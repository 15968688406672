import React, { memo } from "react";
const WithToggle = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {props.children}
    </div>
  );
};

export default memo(WithToggle);
