import React from "react";
import WrapComponent from "../Common/WrapComponent";
import BlogRead from "./blogRead";


const BlogDetails = () => {
  return <WrapComponent>
    {<BlogRead />}
  </WrapComponent>;
};

export default BlogDetails;
