import React, { memo } from "react";
// import { useNavigate } from "react-router-dom";

import WrapComponent from "../Common/WrapComponent";
import WithToggle from "../Common/WithToggle";

import cloudManagmentImage from "../../assets/img/cloud-management-img.png";
import diagram8 from "../../assets/img/digram-8.png";
import diagram9 from "../../assets/img/digram-9.png";
import image10 from "../../assets/img/digram-10.png";
import iamge11 from "../../assets/img/digram-11.png";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const CloudManagment = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <div className="services-banner-section-3 text-center">
        <div className="banner-text media-banner-text">
          <div className="banner-gradient-title mb-15">Cloud Management</div>
          <div className="breadcrumb-links ">
            {/* <a
              onClick={() => {
                navigate("/");
              }}
              href=""
            >
              Home
            </a> */}
            <Link to="/" onClick={() => {}}>
              Home
            </Link>{" "}
            <span className="color-45B0DC"> / Cloud Management</span>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-6 comman-mb">
            <img
              className="images"
              alt="cloudManagmentImage"
              src={cloudManagmentImage}
            />
          </div>
          <div className="col-md-6">
            <p className="mb-15">
              Our Cloud management services encompass planning, provisioning,
              administering, monitoring and governing cloud computing services.
              Cloud management includes the processes, tools and policies that
              are used to assure that cloud computing services are deployed,
              tracked, and optimized for maximum benefit. Cloud management
              encompasses the technology, processes and people that manage the
              cloud, including the users, the provider and the administrators.
              Cloud management is essential for enabling organizations to get
              the most benefit from cloud computing services.
            </p>
            <div className="heading-20 mb-15">
              Our cloud management services includes the following activities:
            </div>
            <ul className="list-content">
              <li>
                <span className="bullet-title">Provisioning: </span>
                Allocating the computing resources and services that a cloud
                provider offers.
              </li>
              <li>
                <span className="bullet-title">Monitoring: </span>
                Tracking the performance and utilization of cloud-based
                services, such as storage, applications, and networks.
              </li>
              <li>
                <span className="bullet-title">Configuration: </span>
                Configuring settings to ensure that the cloud environment is
                optimized for performance and reliability.
              </li>
              <li>
                <span className="bullet-title">Security: </span>
                Ensuring that the cloud environment is secure from unauthorized
                access and malicious attacks.
              </li>
              <li>
                <span className="bullet-title">Optimization: </span>
                Ensuring that the cloud environment is optimized for cost,
                performance, and efficiency.
              </li>
              <li>
                <span className="bullet-title">Compliance: </span>
                Ensuring that the cloud environment is compliant with any
                applicable laws or regulations.
              </li>
              <li>
                <span className="bullet-title">Governance: </span>
                Establishing policies and procedures for effective and efficient
                management of the cloud environment.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="gradient-title custom-text-center mb-20">
                Our Cloud Management Framework
              </div>
              <div className="custom-text-center">
                <p className=" mb-20">
                  We have a highly robust framework that has evolved from
                  managing more than 25 large digital platforms globally. Our
                  framework covers cost, operations, security and compliance,
                  data management, application performance, assets and
                  configurations, and more to ensure large, small and mid-size
                  businesses can realize the full benefits of the cloud in a
                  responsible and secure manner.
                </p>
              </div>
              <div className="text-center mb-20">
                <img alt="diagram" className="images" src={diagram8} />
              </div>
              <div className="heading-20 custom-text-center mb-15">
                The key tenants encompass the following:
              </div>
              <ul className="list-content-7">
                <li> Perimeter Security</li>
                <li>Access Security</li>
                <li>Data Security</li>
                <li> Scalability</li>
                <li>Dynamic Load Balancing</li>
                <li> Infra Capacity on demand</li>
                <li> High Availability</li>
                <li> BCP/DR</li>
                <li>Monitoring</li>
                <li> Automation</li>
                <li className="initial">
                  Cost reporting, monitoring and optimization
                </li>
              </ul>
              <div className="text-center mt-20 mb-0">
                <img className="images" alt="diagram9" src={diagram9} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="gradient-title custom-text-center mb-20">
          Our Technology Stack
        </div>
        <div className="heading-20 custom-text-center mb-20">
          Here is a table listing some common technologies used in our DevOps
          practice:
        </div>
        <table className="table table-bordered mb-20 bg-white">
          <thead>
            <tr className="font-white bg-4db0dc">
              <th scope="col">Technology</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Version Control Systems</th>
              <td>
                Version control systems like Git, SVN, GitHub, and Bitbucket
                help teams manage and track changes to code.
              </td>
            </tr>
            <tr>
              <th scope="row">Continuous Integration</th>
              <td>
                Continuous integration tools like Jenkins, Travis CI, and
                CircleCI help teams automatically build and test code changes.
              </td>
            </tr>
            <tr>
              <th scope="row">Containerization</th>
              <td>
                Containerization tools like Docker, Kubernetes, and AWS ECS help
                teams package and deploy applications in lightweight containers.
              </td>
            </tr>
            <tr>
              <th scope="row">Infrastructure as Code</th>
              <td>
                Infrastructure as code tools like Terraform, CloudFormation, and
                Ansible help teams automate the provisioning and management of
                infrastructure.
              </td>
            </tr>
            <tr>
              <th scope="row">Configuration Management</th>
              <td>
                Configuration management tools like Puppet, Chef, and Ansible
                help teams automate the configuration of servers and
                infrastructure.
              </td>
            </tr>
            <tr>
              <th scope="row">Monitoring and Logging</th>
              <td>
                Monitoring and logging tools like Splunk, Elastic Stack, and AWS
                Cloudwatch help teams track the performance and health of
                applications and infrastructure.
              </td>
            </tr>
            <tr>
              <th scope="row">Cloud Platforms</th>
              <td>
                Cloud platforms like AWS, Azure, and GCP provide a range of
                tools and services for building, deploying, and managing
                applications and infrastructure.
              </td>
            </tr>
            <tr>
              <th scope="row">Static Code Analysis Tools</th>
              <td>
                Static code analysis tools like SonarQube, Veracode, and
                Checkmarx help teams identify vulnerabilities and security
                issues in code before it is deployed.
              </td>
            </tr>
            <tr>
              <th scope="row">Container Scanning Tools</th>
              <td>
                Container scanning tools like Twistlock, Aqua Security, and
                Anchore help teams identify vulnerabilities and security issues
                in container images.
              </td>
            </tr>
            <tr>
              <th scope="row">Vulnerability </th>
              <td>
                Vulnerability management tools like Qualys, Tenable, and Rapid7
                help
              </td>
            </tr>
            <tr>
              <th scope="row">Management Tools</th>
              <td>
                teams identify and prioritize vulnerabilities in applications
                and infrastructure
              </td>
            </tr>
            <tr>
              <th scope="row">Web Application Firewalls</th>
              <td>
                Web application firewalls like Cloudflare, AWS WAF, and Imperva
                help protect against cyber threats by blocking malicious traffic
                to web applications.
              </td>
            </tr>
            <tr>
              <th scope="row">Network Security Tools</th>
              <td>
                Network security tools like firewalls, intrusion detection and
                prevention systems, and virtual private networks (VPNs) help
                protect against cyber threats by monitoring and blocking
                malicious traffic to and from networks.
              </td>
            </tr>
            <tr>
              <th scope="row">Identity and Access Management (IAM) Tools</th>
              <td>
                IAM tools like AWS IAM, Azure AD, and Google Cloud IAM help
                organizations manage and control access to resources and data.
              </td>
            </tr>
            <tr>
              <th scope="row">Compliance</th>
              <td>
                Use Encryption and data masking techniques to adhere to PCI-DSS,
                HIPAA and other global security standards. Leverage tools like
                AWS KMS, Cloud HSM for generating and managing keys.
              </td>
            </tr>
          </tbody>
        </table>
        <div className="custom-text-center">
          <p className="mb-0">
            These technologies can be used in combination to create a robust
            DevOps workflow that enables teams to deliver code and updates to
            production faster and more efficiently. It's important to choose the
            right technologies for your organization's specific needs and goals.
          </p>
        </div>
      </div>
      <div className="main-bg-section-4">
        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title">Use Cases</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card-box-2 comman-mb">
                <div className="padding-20">
                  <div className="small-text">Automated CI/CD Pipeline</div>
                  <div className="card-content-4">
                    Problem Statement: Design an automated deployment pipeline
                    for deploying ECS microservices for a multi-tenant
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-2 comman-mb">
                <div className="padding-20">
                  <div className="small-text">
                    Cloud Infra Cost Optimization
                  </div>
                  <div className="card-content-4">
                    Problem Statement: Customer based in India wanted to conduct
                    a cost optimization exercise for their Cloud deployments
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-2 ">
                <div className="padding-20">
                  <div className="small-text">
                    Cloud Infra and Application Monitoring
                  </div>
                  <div className="card-content-4">
                    Problem Statement: Implement infrastructure and application
                    monitoring for India based customer on running systems on
                    AWS
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-3"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-2 mb-0">
                <div className="padding-20">
                  <div className="small-text">
                    Cloud Operations and Security
                  </div>
                  <div className="card-content-4">
                    Problem Statement: With the growth in technology, there is
                    always a threat to security breaches. These include
                    components such as:
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-4"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-1">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Automated CI/CD Pipeline</div>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  Design an automated deployment pipeline for deploying ECS
                  microservices for a multi-tenant platform. The services are
                  built using Java, Python and NodeJS.
                </p>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <p>
                  The following pipeline represents a simple web application
                  deployment process.
                </p>
                <img
                  className="images desk-images mb-15"
                  alt="iamge10"
                  src={image10}
                />
                <div className="gradient-title heading-22">
                  The automated Jenkins deployment process works as follows:
                </div>
                <div className="bullet-title">Trigger: </div>
                <ul className="list-content-8 mb-15">
                  <li>User pushes the code or commits a change to GitHub.</li>
                </ul>
                <div className="bullet-title">Code Pull: </div>
                <ul className="list-content-8 mb-15">
                  <li>Jenkins checks for any new changes on GitHub.</li>
                  <li>
                    If Jenkins finds changes, it will fetch the code from Github
                    and will map with the job enabled for a specific task.
                    DevOps can also trigger the respective Job manually in
                    Jenkins.
                  </li>
                  <li>
                    Jenkins clones all the files from the GitHub repository to
                    the Jenkins server workspace directory.
                  </li>
                </ul>
                <div className="bullet-title">Build or Package: </div>
                <ul className="list-content-8 mb-15">
                  <li> Build using Maven and create a Docker Image.</li>
                </ul>
                <div className="bullet-title">Push or Store:</div>
                <ul className="list-content-8 mb-15">
                  <li> Publish to a DockerHub repository.</li>
                </ul>
                <div className="bullet-title">Deploy In ECS:</div>
                <ul className="list-content-8 mb-15">
                  <li>
                    Once, image is successfully pushed to DockerHub, Jenkins
                    will then stop the equivalent ECS task in AWS.
                  </li>
                  <li>
                    As a result, the existing task will be stopped, resulting in
                    spinning up of a new task.
                  </li>
                  <li>
                    The New ECS task will pull a fresh image from DockerHub and
                    deploy it in ECS Fargate.
                  </li>
                </ul>
                <div className="bullet-title">Test:</div>
                <ul className="list-content-8 ">
                  <li>
                    We can check the Target Group health checks to verify if the
                    service deployment is successful or not.
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-2">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Cloud Infra Cost Optimization</div>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  Customer based in India wanted to conduct a cost optimization
                  exercise for their Cloud deployments
                </p>
                <ul className="list-content mb-15">
                  <li>Complicated and multifaceted pricing structures</li>
                  <li>High costs</li>
                  <li>Continuous modifications to cloud offerings</li>
                  <li>Excessive alternative architectures</li>
                  <li>Lack of coherence across cloud platforms</li>
                </ul>
                <div className="gradient-title heading-22 mb-15">
                  Solution Implementation
                </div>
                <img
                  className="images desk-images mb-20"
                  alt="iamge11"
                  src={iamge11}
                />
                <div className="heading-20 ">Analyze Cloud Costs:</div>
                <ul className="list-content-8 mb-15">
                  <li>
                    <span className="bullet-title">Evaluation:</span> Go with a
                    perfect cloud strategy for efficient cost evaluation
                  </li>
                  <li>
                    <span className="bullet-title">Billing model:</span>
                    Compare, assess and determine vendors by region and
                    service-specific costs
                  </li>
                  <li>
                    <span className="bullet-title">Monitoring:</span> Continuous
                    monitoring is key to understanding infrastructure usage
                  </li>
                </ul>
                <div className="heading-20 ">Track Wastage:</div>
                <p>
                  Identify resources that are idle, orphaned and
                  overprovisioned, along with reserved instances. These can be
                  unused machine images, load balancers, unassociated IPs,
                  unused data volumes, among others
                </p>
                <div className="heading-20 ">Prepare a 7-Point Checklist:</div>
                <ul className="list-content-8">
                  <li>Identify resources </li>
                  <li>Make resources visible</li>
                  <li>Whitelist resources</li>
                  <li>Schedule repeating instances</li>
                  <li>Storage management</li>
                  <li>Plan budgets</li>
                  <li>Plan region-wise pricing</li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-3">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  Cloud Infra and Application Monitoring
                </div>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  Implement infrastructure and application monitoring for India
                  based customer on running systems on AWS
                </p>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <p>
                  We made a checklist of all the Infra components and
                  applications that need to be monitored. For each, we
                  recommended the most appropriate and cost effective tool for
                  monitoring the specific services. We finally used multiple
                  tools.
                </p>
                <div className="heading-20 ">Cloud Watch:</div>
                <p>
                  We are using Cloudwatch for all the basic logging and
                  monitoring for the AWS cloud. CloudWatch is good for gaining a
                  quick overview of the status of AWS workloads. But it’s rarely
                  sufficient for a complete log analytics and cloud monitoring
                  strategy. To achieve the latter, we use Cloudwatch as the
                  datasource for other tools (listed below) that offer features
                  like multi cloud support, sophisticated alert configuration,
                  storage flexibility and more.
                </p>
                <div className="heading-20 ">Lumigo:</div>
                <p>
                  We used it to monitor AWS lambda &amp; debug Lambda timeouts.
                </p>
                <div className="heading-20 ">Grafana Cloud:</div>
                <p>
                  Grafana Cloud is a cloud-based app for Grafana. We use this to
                  monitor a wide range of AWS services such as EC2, ECS, Lambda,
                  S3 etc.
                </p>
                <div className="heading-20 ">Influx Data:</div>
                <p>
                  It's a time-series database. InfluxData helps to get a wide
                  range of data (metrics, events, and time series). InfluxDB can
                  then be used as a datasource for tools like grafana for visual
                  representation of all the metrics. We created Grafana
                  Dashboards with InfluxDB.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-4">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Cloud Operations and Security</div>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  With the growth in technology, there is always a threat to
                  security breaches. These include components such as:
                </p>
                <ul className="list-content-8 mb-15">
                  <li>Network, infra &amp; security structure</li>
                  <li>Storage &amp; servers</li>
                  <li>User &amp; role management</li>
                  <li>Business applications</li>
                  <li>Operating systems</li>
                  <li>Databases</li>
                  <li>Compliance with the Information Security Policy</li>
                  <li>
                    Configuration of various other components, SSL, backups,
                    patching etc.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <p>
                  We are using Google Workspace for email/chat communication,
                  business applications, storages and SSO integration with AWS.
                  It makes working together a lot easier.
                </p>
                <div className="heading-20 ">Admin: </div>
                <ul className="list-content-8 mb-15">
                  <li>
                    Add users, manage devices, and configure security and
                    settings.
                  </li>
                </ul>
                <div className="heading-20 ">Security and Control: </div>
                <ul className="list-content-8 mb-15">
                  <li>
                    The centralized administration makes setup and management
                    fast and easy. We have used 2-step verification for
                    security.
                  </li>
                </ul>
                <div className="heading-20 ">Endpoint Management: </div>
                <ul className="list-content-8 mb-15">
                  <li>
                    We have used endpoint management to distribute apps on
                    mobile devices, check usage, manage security settings and
                    limit access on any endpoint.
                  </li>
                </ul>
                <p>
                  Windows Active Directory is also used for local infra/servers
                  user management, set up of group user and device policies.
                </p>
                <p className="heading-20 mb-10">
                  We are following below options to manage users within Active
                  Directory:
                </p>
                <ul className="list-content-8 mb-15">
                  <li>
                    <span className="bullet-title">Account Disabling:</span> To
                    disable a user account we set the 'AccountDisabled' property
                    to 'True' in the User Management interface.
                  </li>
                  <li>
                    <span className="bullet-title">Account Expiration:</span> We
                    set the account 'Expiration Date' property to the desired
                    date value to set an expiration date to an account.
                  </li>
                  <li>
                    <span className="bullet-title">Account Lockout:</span> An
                    account lockout is set if the number of failed logon
                    attempts exceeds.
                  </li>
                  <li>
                    <span className="bullet-title">
                      User Cannot Change Password:
                    </span>
                    Administrator decides if they will allow the user to have
                    the ability to change their own password.
                  </li>
                </ul>
                <div className="heading-20 ">Storage &amp; Backups:</div>
                <p>
                  We are using Google Storage and S3 for backups. We keep
                  scripts in place for daily and weekly backups. Lifecycle
                  policies are also in place.
                </p>
                <div className="heading-20 ">
                  Compliance with the Information Security Policy
                </div>
                <p>
                  When there is a violation of the security policy. Example:
                </p>
                <ul className="list-content-8 mb-15">
                  <li>Violation of a documented procedure by an associate</li>
                  <li>Virus attack</li>
                  <li>Loss of service</li>
                </ul>
                <p>
                  All such security incidents are logged into the Services
                  Tracker. These will be escalated to the Security Team who will
                  assign appropriate resources to do a Root Cause Analysis and
                  take remedial action as needed. A security incident can lead
                  to disciplinary action against an employee in which case the
                  HR team will be informed.
                </p>
                <div className="heading-20 ">Security Audits</div>
                <p className="mb-0">
                  Regular internal audits are carried out by the security team
                  at a bi-annual frequency. Purpose of the audit is to Monitor
                  all security measures to ensure conformance with organization
                  policies.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
    </WrapComponent>
  );
};

export default memo(CloudManagment);
