import React, { memo } from "react";
import aboutUs from "../../assets/img/about-us-img.png";
import aboutUs1 from "../../assets/img/about-us-img-1.png";

const WelcomeSection = () => {
  return (
    <>
      <div className="main-section ">
        <div className="row animate__fadeInUp">
          <div className="col-md-6 comman-mb">
            <img className="images" src={aboutUs} alt="" />
          </div>
          <div className="col-md-6 mb-30 justifyText">
            <div className="gradient-title mb-15">
              Welcome to Extrapreneurs India!
            </div>
            <p>
              We are a team of passionate individuals who are dedicated to
              helping enterprises, entrepreneurs and small and medium business
              owners succeed.{" "}
            </p>
            <p>
              Our goal is to provide the technical resources, support, and
              guidance that you need to turn your business goals into a reality.
            </p>
            <p>
              Our team is comprised of experienced business professionals who
              have a wealth of technical, domain and operational knowledge and
              expertise.
            </p>
            <p>
              We offer a range of services, including platform engineering, web
              and mobile apps, cloud management, QA &amp; Testing, BI &amp;
              Analytics, Platform Accelerators and a lot more to help you grow
              and scale your business.
            </p>
            <p>
              We believe that entrepreneurship is the key to driving innovation
              and positive change in the world. That's why we are committed to
              supporting the next generation of business leaders and helping
              them achieve their goals.
            </p>
            <p>
              We look forward to working with you as an equal stake partner and
              helping you reach new heights of success.
            </p>
          </div>
          <div className="col-md-6 comman-mb justifyText">
            <div className="gradient-title heading-24 mb-15">Key Values</div>
            <ul className="list-content">
              <li>
                {" "}
                We are a full stack platform engineering services and solutions
                company.
              </li>
              <li>
                {" "}
                We bring strong domain and process knowledge to value-add to the
                solutions we build.
              </li>
              <li>
                {" "}
                We have a strong pedigree of Solution Architecture and Design
                skills.
              </li>
              <li>
                {" "}
                We have rich experience in Platform and Product management.
              </li>
              <li>
                {" "}
                We operate Agile Delivery management and emphasis on team
                collaboration and communication.
              </li>
              <li>
                We work with customers across the globe and across enterprise,
                small and medium markets.
              </li>
              <li>
                We run a Technology Innovation Lab to keep the engineering teams
                and CoE updated on the latest technology and align it to
                customer requirements.
              </li>
              <li>
                {" "}
                The founders and the core delivery team have strong delivery
                track record with over 300 person-years of experience between
                them across Fortune 500 companies.
              </li>
              <li>The source code IP always belongs to the customer.</li>
            </ul>
          </div>
          <div className="col-md-6 comman-mb text-right">
            <img className="images" src={aboutUs1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WelcomeSection);
