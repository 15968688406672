import React, { memo } from "react";
import WrapComponent from "../Common/WrapComponent";
import img6 from "../../assets/img/img-6.png";
import growthZone from "../../assets/img/growth-zone-img.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const LifeAtExtrapreneurs = () => {
  // const navigate = useNavigate();
  return (
    <>
      <WrapComponent>
        <ScrollToTopButton/>
        <div>
          <div className="banner-section-4 text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Life at Extrapreneurs
              </div>
              <div className="breadcrumb-links ">
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
                <span className="color-45B0DC"> / Life at Extrapreneurs</span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb">
                <img className="images" src={img6} alt="" />
              </div>
              <div className="col-md-6">
                <div className="gradient-title mb-15">Why Choose Us?</div>
                <p>
                  If you need a job, don’t choose Extrapreneurs India. When you
                  need a career, choose Extrapreneurs. We don’t give jobs! we
                  build career!!!
                </p>
                <p>
                  At Extrapreneurs India, we have the best of the industry
                  talent that has the engineering discipline on one hand and the
                  open &amp; learning environment on the other hand that fosters
                  growth and innovation.
                </p>
                <p className="mb-0">
                  {" "}
                  We don’t have managers, We have leaders, our leader’s first
                  KPI is to build people and build organization. Our management
                  culture is designed to help, mentor, support and groom young
                  talent on the latest of tools and best practices, execute
                  innovative proof of concept labs and help evolve solution
                  concepts to working code in production.
                </p>
              </div>
            </div>
          </div>
          <div className="main-bg-section-6">
            <div className="main-section">
              <div className="row">
                <div className="col-md-5">
                  <div className="gradient-title mb-15">
                    Here is how we see our
                    <br /> Family Growing
                  </div>
                  <div className="paragraph-content">
                    <p>
                      {" "}
                      We, at EPI, firmly believe that all employees need to
                      engage in the Learning and Growth Zones to realize their
                      full potential.
                    </p>
                    <p>
                      A strong mentorship program ensures constant guidance and
                      clear sense of direction to upskill, address behavioral,
                      soft skills and leadership gaps and evolve to being a
                      better person.
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <img className="images" src={growthZone} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">
                EPI Global Culture &amp; Core Values
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card-box-4">
                  <div className="padding-20">
                    <div className="heading-22">Ownership</div>
                    <ul className="list-content-2">
                      <li>Everyone is an enterpreneur</li>
                      <li>
                        Endless opportunity for the individual who wants to take
                        responsibility
                      </li>
                      <li>Learn and Earn</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-4">
                  <div className="padding-20">
                    <div className="heading-22">Transparency</div>
                    <ul className="list-content-2">
                      <li className="initial">
                        Utmost transparency in building people
                      </li>
                      <li>360 degree feedback syatem</li>
                      <li className="initial">
                        Direct access to the owner of the company
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-4">
                  <div className="padding-20">
                    <div className="heading-22">
                      Aligning to Customer Business
                    </div>
                    <ul className="list-content-2">
                      <li>Understand what is key to customer success</li>
                      <li>Short term &amp; long term outcomes</li>
                      <li>Capital allocation for best value for money</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-4 mb-0 comman-mb">
                  <div className="padding-20">
                    <div className="heading-22">Learning &amp; Growing</div>
                    <ul className="list-content-2">
                      <li>Mentorship programs</li>
                      <li>
                        Various training programs (technical &amp; soft skills)
                      </li>
                      <li className="initial">
                        Career &amp; interpersonal skill development
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-4 mb-0">
                  <div className="padding-20">
                    <div className="heading-22">Holistic Wellbeing</div>
                    <ul className="list-content-2">
                      <li className="initial">
                        Physical &amp; mental health is as important
                      </li>
                      <li>Short term &amp; long term outcomes</li>
                      <li>
                        Living a good like: The journey &amp; the goal both are
                        important
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="custom-text-center">
                <div className="gradient-title mb-15">EPian Speaks!!</div>
              
                  <section className="testimonials">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="customers-testimonials" className="owl-carousel">
                            <div className="item">
                              <div className="shadow-effect">
                                <div className="testi-scroll scroll_text_show mb-15">
                                  <p>
                                    <i>
                                      “I am part of EPI family since last 8 years.
                                      Joined as Senior developer and today I am
                                      working as Senior Project Manager in EPI. It
                                      is a long journey which is challenging in
                                      terms of work, enjoyed in terms of
                                      togetherness.
                                    </i>
                                  </p>
                                  <p>
                                    <i>
                                      {" "}
                                      Apart from the work and beyond the work,
                                      Extrapreneurs taught me a lot about wealth
                                      management that I am using to better my life."
                                    </i>
                                  </p>
                                </div>
                                <div className="client-name">Milind Nikam</div>
                                <div className="client-designation">
                                  Senior Project Manager
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="shadow-effect">
                                <div className="testi-scroll scroll_text_show mb-15">
                                  <p>
                                    <i>
                                      “Its a pleasure to be part of EPI team, as a
                                      program manager I have been working with
                                      multiple teams and multiple projects, every
                                      team member is very supportive and
                                      encouraging; management team is always ready
                                      guide and mentor whenever required. As a
                                      organisation EPI has an engineering culture
                                      with professional and disciplined approach. "
                                    </i>
                                  </p>
                                </div>
                                <div className="client-name">Urmila Date</div>
                                <div className="client-designation">
                                  {" "}
                                  Program Manager
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="shadow-effect">
                                <div className="testi-scroll scroll_text_show mb-15">
                                  <p>
                                    <i>
                                      “I have been working with EPI for the last 8
                                      years. The environment &amp; work culture here
                                      is very supportive and motivational. In these
                                      8 years, I got the opportunity to work in
                                      multiple domains &amp; on multiple
                                      technologies. I enjoy working with EPI every
                                      single day as my first day of Job. same
                                      enthusiasm &amp; love.”
                                    </i>
                                  </p>
                                </div>
                                <div className="client-name">Shivraj Jain</div>
                                <div className="client-designation">
                                  Software Engineer
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="shadow-effect">
                                <div className="testi-scroll scroll_text_show mb-15">
                                  <p>
                                    <i>
                                      “I am honored and humbled to be a member of
                                      the EPI team. Everyone, including the
                                      management and team members are highly
                                      enthusiastic and supportive about the work. I
                                      got the chance to work on very innovative
                                      ideas which also helped me to upgrade my
                                      skill.”
                                    </i>
                                  </p>
                                </div>
                                <div className="client-name">Amrut Gaikwad</div>
                                <div className="client-designation">
                                  Program Manager
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>  
            </div>
          </div>
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(LifeAtExtrapreneurs);
