import WrapComponent from "../Common/WrapComponent";

import healthcarePharmaImage from "../../assets/img/healthcare-pharma-img.png";
import diagram15 from "../../assets/img/digram-15.png";
import diagram16 from "../../assets/img/digram-16.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { memo } from "react";
import ScrollToTopButton from "../Common/ScrollToTop";

const HealthcareAndFarma = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-2 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Healthcare &amp; Pharma
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="color-45B0DC"> / Healthcare &amp; Pharma</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="healthcare"
                src={healthcarePharmaImage}
              />
            </div>
            <div className="col-md-6">
              <p>
                As a leader in the healthcare and pharma industry, your
                organization is constantly looking for ways to improve
                efficiency, reduce costs, and deliver better patient outcomes.
                At EPI, we understand the unique challenges and opportunities
                facing your industry, and we have a range of solutions to help
                you meet them head-on.
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="gradient-title heading-24 mb-25">
                  Some key processes and challenges in the healthcare and pharma
                  industry include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">Sales Team Automation:</span> Managing 
                    the complex and time-consuming process of managing
                    sales teams, including lead management, lead conversion,
                    doctor appointments, sales reports, visit planning, samples,
                    promo kits and post sales support.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Supply Chain Management:
                    </span> Ensuring 
                    the efficient and secure distribution of
                    pharmaceutical products, while also managing costs and
                    complying with regulations.
                  </li>
                  <li>
                    <span className="bullet-title">Patient Engagement:</span> Engaging 
                    and communicating with patients to improve
                    adherence to treatment plans and overall health outcomes.
                  </li>
                  <li>
                    <span className="bullet-title">Data Management:</span> Managing 
                    and analyzing large volumes of data to inform
                    decision-making and improve patient care.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Managing Healthcare Professionals:
                    </span> Engaging 
                    and connecting the accurate healthcare
                    professionals to the medical trusts and organizations
                  </li>
                </ul>
              </div>
              <div className="col-md-6 mb-15">
                <div className="gradient-title heading-24 mb-20">
                  To address these challenges and more, our solutions include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">
                      Sales Team Automation Software:
                    </span> Our 
                    software streamlines the sales team management process,
                    including lead management, lead conversion, doctor
                    appointments, sales reports, visit planning, samples, promo
                    kits and post sales support to help you increase efficiency
                    and drive sales.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Supply Chain Optimization Tools:
                    </span> Our 
                    tools use advanced analytics to optimize the
                    distribution of pharmaceutical products, reducing costs and
                    ensuring compliance.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Patient Engagement Platforms:
                    </span> Our 
                    patient engagement platforms offer a range of tools to
                    help you communicate with patients, including appointment
                    scheduling, medication reminders, and educational resources.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Data Management and Analytics Solutions:
                    </span> Our 
                    data management and analytics solutions help you extract
                    insights from large volumes of data, informing
                    decision-making and improving patient care.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Managing Healthcare Professionals:
                    </span> Automate 
                    the recruitment process to find a suitable
                    candidate; automation to map the right candidates to
                    appropriate requirements through various job networks.
                  </li>
                </ul>
              </div>
              <div className="custom-text-center">
                <p className="mb-0">
                  Contact us today to learn more about how we can help your
                  organization navigate the challenges and opportunities of the
                  healthcare and pharma industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-3">
          <div className="main-section">
            <div className="custom-text-center mb-30">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 1</div>
                  <div className="card-content-4">
                    About the Company: The UK's leading multidisciplinary
                    healthcare recruiter. Having collaboration with over 90% of
                    NHS trusts, in addition to private medical organizations.
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 2</div>
                  <div className="card-content-4">
                    About the Company: US based organization providing
                    resiliency training to First Responders who are typically
                    subject to PTSD disorders as they encounter stressful
                    situations.
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    The UK's leading multidisciplinary healthcare recruiter.
                    Having collaboration with over 90% of NHS trusts, in
                    addition to private medical organizations. Their offering
                    spans doctors, nurses, and allied health professionals.
                  </p>
                  <p>
                    They help healthcare organizations across the country find
                    the staff they need to provide the best possible care to
                    their patients.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    Design and build of a scraper to 'scrap’ incoming structured
                    and unstructured assignment data from variety of sources
                    (portals and emails).
                  </p>
                  <p>
                    The scraper is to be configurable to accommodate the varying
                    data sources and formats and integrates with an established
                    ‘parser’ to load the scraped data directly into DB in order
                    to automatically create an unassigned temporary contract for
                    Nurses and Doctors.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We have built a complete solution using SMTP mail client to
                    authenticate emails and attachments via bot and created rule
                    engine for reading/scraping the emails header/body/footer.
                    We also built a configurable bot for Web Portals using auth
                    algorithm which was multithreaded to scrape the data
                    simultaneously from various portals. Here the input actions
                    were passed automatically by bot to the portals in order to
                    apply various filters to get to the final data and scrape it
                    and push it further for processing. The following are the
                    solution highlights:
                  </p>
                  <ul className="list-content-8 mb-15">
                    <li>
                      Automated the processes involved in capturing the
                      requirements from various VMS and Emails with no manual
                      intervention.
                    </li>
                    <li>
                      Implemented the RPA with the help of Open Source Tech
                      stack with no cost on Tools and Technology used for
                      Scraper.
                    </li>
                    <li>
                      Optimized and automated error handling and recovery
                      mechanism at the time of requirement gathering which
                      reduced human error ratio to almost 100%.
                    </li>
                    <li>
                      Rapid and reliable output which provides an upper hand
                      over the competitors in the market to fulfil the
                      requirements as soon as possible.
                    </li>
                    <li>
                      Unified centralized Web Portal hosted in premise data
                      center which can be accessed from anywhere 24/7.
                    </li>
                    <li>
                      Various Dashboard/MIS reports for analysing the
                      requirement trends from each vendor management portal.
                    </li>
                  </ul>
                  <div className="text-center ">
                    <img className="images" alt="diagram15" src={diagram15} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 2</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    US based organization providing resiliency training to First
                    Responders who are typically subject to PTSD disorders as
                    they encounter stressful situations.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    Create a portal which can integrate with the existing
                    website and with Salesforce. The portal should enable the
                    progress of the resiliency training by the First Responders
                    who have been selected to go through the training by their
                    organizations. The future vision includes provision and
                    monitoring of counselling services.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We have built a complete solution in React+Java which
                    integrates with Salesforce inline with HIPAA compliance. The
                    assessment and user management program is built in React
                    Application and client and first responder onboarding is
                    built in Salesforce. The following are the solution
                    highlights:
                  </p>
                  <ul className="list-content-8 mb-15">
                    <li>
                      Bulk First time upload of first responders into database.
                    </li>
                    <li>
                      First time access and login password generation and
                      notification of program initiation
                    </li>
                    <li>
                      Created List of Assessments and administering the
                      Assessments to the first responder
                    </li>
                    <li>
                      Storage of the assessment responses directly into
                      Salesforce to ensure HIPAA compliance
                    </li>
                    <li>
                      Scoring of the various assessments based on several rules
                    </li>
                    <li>Assessment History management</li>
                    <li>
                      Notification for pre-test and post-test of several lessons
                      from resilience training program
                    </li>
                    <li>
                      Completion flag from website for completion of each
                      resilience training
                    </li>
                    <li>
                      Reporting of an incident/ trauma and triggers for
                      re-assessments
                    </li>
                    <li>
                      Dashboards for workflow items running on various status
                    </li>
                    <li>Reports on the program status in their enterprise</li>
                    <li>
                      Notifications and Alerts at various points to various
                      stakeholders (first responders, operations and backend
                      team)
                    </li>
                  </ul>
                  <div className="text-center">
                    <img className="images" alt="diagram16" src={diagram16} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(HealthcareAndFarma);
