import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import videokycImg from "../../assets/img/video-kyc-img.png";
import diagram40 from "../../assets/img/digram-40.png";
import videokycImg1 from "../../assets/img/video-kyc-img-1.png";
import videokycImg2 from "../../assets/img/video-kyc-img-2.png";
import videokycImg3 from "../../assets/img/video-kyc-img-3.png";
import videokycImg4 from "../../assets/img/video-kyc-img-4.png";
import videokycImg5 from "../../assets/img/video-kyc-img-5.png";
import videokycImg6 from "../../assets/img/video-kyc-img-6.png";
import videokycImg7 from "../../assets/img/video-kyc-img-7.png";
import videokycImg8 from "../../assets/img/video-kyc-img-8.png";
import videokycImg9 from "../../assets/img/video-kyc-img-9.png";
import videokycImg10 from "../../assets/img/video-kyc-img-10.png";
import videokycImg11 from "../../assets/img/video-kyc-img-11.png";
import videokycImg12 from "../../assets/img/video-kyc-img-12.png";
import videokycImg13 from "../../assets/img/video-kyc-img-13.png";
import videokycImg14 from "../../assets/img/video-kyc-img-14.png";
import videokycImg15 from "../../assets/img/video-kyc-img-15.png";
import videokycImg16 from "../../assets/img/video-kyc-img-16.png";
import videokycImg17 from "../../assets/img/video-kyc-img-17.png";
import videokycImg18 from "../../assets/img/video-kyc-img-18.png";
import videokycImg19 from "../../assets/img/video-kyc-img-19.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const Videokyc = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  //Slider
  const [index, setIndex] = useState(0);
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const changeSlide = () => {
    if (index > slides.length - 1) setIndex(0);

    if (index < 0) setIndex(slides.length - 1);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  // Call the changeSlide function whenever the index changes
  React.useEffect(() => {
    changeSlide();
    // eslint-disable-next-line
  }, [index]);

  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-section-kyc text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">Video KYC</div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="/solutions">Solutions</a>{" "} */}
                <Link to="/solutions" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="font-45B0DC"> / Video KYC</span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center video-kyc-img-tp">
                <img className="images " src={videokycImg} alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="gradient-title mb-15">Why Video KYC?</div>
                <p>
                  KYC is generally part of financial regulatory process for any
                  Financial Institution (FI) to identify and verify the client's
                  identity when opening an account and periodically over time
                  (reKYC). In other words, FIs must make sure that their clients
                  are genuinely who they claim to be.
                </p>
                <p>
                  Video-based KYC provides the means to FIs to perform KYC
                  remotely from anywhere via a video call. The verifier
                  authenticates the person on the video call, the ID and Address
                  documents presented, does a liveliness check along with
                  establishing the precise location of the person.
                </p>
                <p>
                  VideoKYC is needed for several reasons, including compliance
                  with regulations, increased security and fraud prevention,
                  execution at scale for a very large customer base and enhanced
                  customer experience.
                </p>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="gradient-title">THOR-VideoKYC Overview</div>
                  THOR-VideoKYC is a solution built on the THOR platform which
                  provides facility for customer to join a video conference room
                  with an Agent and complete the KYC. This is a full stack
                  hybrid cloud native solution where the frontend is implemented
                  in ReactJS, backend in serverless Python APIs, database is
                  PostgreSQL and for Video conferencing we have used OpenVidu CE
                  (Central media server framework) that is hosted on AWS. The
                  KYC meeting scheduling and tracking workflows are implemented
                  via Salesforce Service Cloud.
                </div>
                <div className="col-md-6">
                  <div className="heading-20 custom-text-center mb-15">
                    The implementation architecture:
                  </div>
                  <img
                    id="myImg"
                    src={diagram40}
                    onClick={() => {
                      openModal(diagram40);
                    }}
                    className="img-shadow width-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="gradient-title mb-15 text-center">
              THOR-VideoKYC Primary Features Include:
            </div>
            <div className="row">
              <div className="col-md-6 ">
                <div className="heading-20 mb-15">Customer side features:</div>
                <ul className="list-content mb-20">
                  <li>Register and Schedule the VideoKYC or do it on Demand</li>
                  <li>Re-schedule or Cancel the VideoKYC request</li>
                  <li>
                    On receiving the meeting link, Join the VideoKYC meeting for
                    KYC check
                  </li>
                </ul>
                <div className="heading-20 mb-15">
                  Supervisor / Reviewer side features:
                </div>
                <ul className="list-content">
                  <li>Review Agent Appointment Calendar</li>
                  <li>
                    Transfer appointments from one agent to another or back to
                    Queue
                  </li>
                  <li>
                    Review the conducted VideoKYC details and mark as Closed
                  </li>
                  <li>Track Agent Productivity</li>
                  <li>Review MIS</li>
                  <li>Rich Tracking Dashboards</li>
                </ul>
              </div>
              <div className="col-md-6 ">
                <div className="heading-20 mb-15">Agent side features:</div>
                <ul className="list-content">
                  <li>Daily Activity Calendar</li>
                  <li>
                    On assignment of VideoKYC meeting, accept/reject the meeting
                    request
                  </li>
                  <li>Join the VideoKYC meeting and finish the KYC check</li>
                  <li>
                    During meeting, capture the screenshots of user and user’s
                    documents
                  </li>
                  <li>During meeting, complete the checklist</li>
                  <li>
                    Post meeting, review and update KYC to either accept or
                    reject it based on clarity of documents and images captured
                    and matching it with available Customer details
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section-img-slider custom-slider">
            <div className="main-section">
              {/* <div class="col-md-12">
          <div class="gradient-title text-center">How CRM Works?</div>
          <div id="mainBanner" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators carouselIndicators">
                  <li data-target="#mainBanner" data-slide-to="0" class="active"></li>
                  <li data-target="#mainBanner" data-slide-to="1"></li>
                  <li data-target="#mainBanner" data-slide-to="2"></li>
                  <li data-target="#mainBanner" data-slide-to="3"></li>
                  <li data-target="#mainBanner" data-slide-to="4"></li>
                  <li data-target="#mainBanner" data-slide-to="5"></li>
                  <li data-target="#mainBanner" data-slide-to="6"></li>


              </ol>
              <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img class="d-block w-100 w100" src="img/crm-img-1.png" alt="First slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-2.png" alt="Second slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-3.png" alt="Third slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-4.png" alt="Third slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-5.png" alt="Third slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-6.png" alt="Third slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-7.png" alt="Third slide">

                  </div>
              </div>
              <a class="carousel-control-prev" href="#mainBanner" role="button" data-slide="prev">
                  <i class="fa-solid fa-angle-left banner-btn"></i>
                  <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#mainBanner" role="button" data-slide="next">
                  <i class="fa-solid fa-angle-right banner-btn"></i>
                  <span class="sr-only">Next</span>
              </a>
          </div>
      </div> */}
              <div className="gradient-title text-center">
                How VideoKYC Works?
              </div>
              <div id="slider" className="slider">
                <div className="slide" style={{ display: "block" }}>
                  <img src={videokycImg1} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg2} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg3} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg4} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg5} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg11} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg12} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg13} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg14} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg15} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg16} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg17} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg18} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg6} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg7} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg8} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg9} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg10} alt="" />
                </div>
                <div className="slide">
                  <img src={videokycImg19} alt="" />
                </div>
                {/*Controlling arrows*/}
                <span
                  className="controls"
                  id="left-arrow"
                  onClick={() => prevSlide(-1)}
                >
                  {" "}
                  <i className="fa-solid fa-angle-left" />
                </span>
                <span
                  className="controls"
                  id="right-arrow"
                  onClick={() => nextSlide(1)}
                >
                  <i className="fa-solid fa-angle-right" />
                </span>
              </div>
              <div id="dots-con">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="gradient-title mb-15 text-center">
                  Benefits of THOR-VideoKYC
                </div>
                <p className="heading-20 text-center mb-15">
                  {" "}
                  THOR-VideoKYC is beneficial due to following main
                  characteristics:
                </p>
                <ul className="list-content-6">
                  <li>
                    <span className="bullet-title">Remote Verification:</span>{" "}
                    VideoKYC enables businesses to remotely verify the identity
                    of their customers, eliminating the need for in-person
                    verification. This provides convenience to customers and
                    reduces operational costs for businesses.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Compliance with Regulations:
                    </span>{" "}
                    VideoKYC enables businesses to comply with these regulations
                    by providing a remote and secure way to verify customer
                    identities without the need for physical documentation.
                  </li>
                  <li>
                    <span className="bullet-title">Enhanced Security:</span>{" "}
                    VideoKYC provides a secure way to verify customer
                    identities, reducing the risk of fraud and identity theft.
                    It enables businesses to verify the authenticity of the
                    customer's identity documents and confirm that the person on
                    the other end of the video call is the same person as shown
                    in the documents.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Improved Customer Experience:
                    </span>{" "}
                    VideoKYC eliminates the need for customers to physically
                    visit a branch or office to complete the KYC process. This
                    provides a more convenient and efficient experience for
                    customers, enabling them to complete the KYC process from
                    the comfort of their own homes.
                  </li>
                  <li>
                    <span className="bullet-title">Time and Cost Savings:</span>{" "}
                    VideoKYC is a cost-effective solution compared to
                    traditional in-person KYC processes. It eliminates the need
                    for businesses to invest in physical infrastructure and
                    staff to handle the KYC process. Additionally, it saves time
                    for both businesses and customers by enabling remote
                    verification.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="gradient-title mb-15 text-center">
                    VideoKYC Use Cases{" "}
                  </div>
                  <p className="heading-20 text-center mb-15">
                    {" "}
                    VideoKYC (Know Your Customer) has a wide range of use cases
                    across various industries, including:
                  </p>
                  <ul className="list-content-6">
                    <li>
                      <span className="bullet-title">
                        Banking and Finance:{" "}
                      </span>
                      Banks and financial institutions can use VideoKYC to
                      onboard new customers remotely, verify their identities,
                      and comply with regulatory requirements. VideoKYC can also
                      be used for loan disbursements, account openings, and
                      other financial transactions.
                    </li>
                    <li>
                      <span className="bullet-title">Insurance: </span>
                      Insurance companies can use VideoKYC to verify the
                      identities of new policyholders, process claims, and
                      prevent fraud. VideoKYC can also be used for digital
                      insurance policies, enabling customers to complete the
                      entire process online.
                    </li>
                    <li>
                      <span className="bullet-title">Healthcare: </span>
                      Healthcare providers can use VideoKYC to remotely verify
                      the identities of patients, confirm insurance coverage,
                      and process claims. VideoKYC can also be used for
                      telemedicine appointments, providing a secure and
                      convenient way for patients to consult with healthcare
                      professionals.
                    </li>
                    <li>
                      <span className="bullet-title">Real Estate: </span>
                      Real estate companies can use VideoKYC to remotely verify
                      the identities of property buyers and sellers, process
                      transactions, and comply with regulatory requirements.
                      VideoKYC can also be used for virtual property tours,
                      enabling customers to view properties remotely.
                    </li>
                    <li>
                      <span className="bullet-title">Telecom: </span>
                      Telecom companies can use VideoKYC to onboard new
                      customers, verify their identities, and comply with
                      regulatory requirements. VideoKYC can also be used for SIM
                      card replacements and other telecom transactions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}
          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt=""
              />
              <div id="caption" />
            </div>
          </div>
          {/* ..................................................modal end...................................................... */}
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(Videokyc);
