import WrapComponent from "../Common/WrapComponent";

import diagram24 from "../../assets/img/digram-24.png";
import bankingMutualfundImage from "../../assets/img/banking-mutual-fund-img.png";
import diagram30 from "../../assets/img/digram-30.png";
import diagram31 from "../../assets/img/digram-31.png";
import diagram32 from "../../assets/img/digram-32.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { memo } from "react";
import ScrollToTopButton from "../Common/ScrollToTop";

const MutualFundDistributor = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-5 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Mutual Funds Distributor
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="font-45B0DC"> / Mutual Funds Distributor</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="Banking and mutual fund"
                src={bankingMutualfundImage}
              />
            </div>
            <div className="col-md-6">
              <p>
                As a mutual fund distribution business in India, you are
                responsible for bringing mutual fund products to market and
                helping investors make informed decisions about their
                investments.
              </p>
              <p>
                At EPI, we understand the unique challenges and opportunities of
                the mutual fund distribution industry in India, and we have a
                range of solutions to help you succeed.
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className=" gradient-title custom-text-center mb-20">
                  Some key processes and challenges in the mutual fund
                  distribution industry in India include:
                </div>
                <ul className="list-content mb-15">
                  <li>
                    <span className="bullet-title">Order Execution:</span> The
                    software should allow users to execute orders on the BSE and
                    NSE with ease.
                  </li>
                  <li>
                    <span className="bullet-title">Portfolio Management:</span> The 
                    software should provide tools for managing and tracking
                    mutual fund portfolios, including asset allocation and
                    performance tracking.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Customer Relationship Management (CRM):
                    </span> The 
                    software should provide tools for managing customer
                    relationships, including contact management, lead tracking,
                    and sales forecasting.
                  </li>
                  <li>
                    <span className="bullet-title">Compliance:</span> The
                    software should include tools for ensuring compliance with
                    regulations related to mutual fund distribution, including
                    those related to sales practices and disclosure.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Reporting and Analytics:
                    </span> The 
                    software should provide a range of reports and analytics
                    tools to help users track performance and make informed
                    decisions about their mutual fund investments.
                  </li>
                  <li>
                    <span className="bullet-title">Mobile Accessibility:</span> The 
                    software should be accessible from mobile devices,
                    allowing users to manage their mutual fund investments on
                    the go.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Integration with other Systems:
                    </span> The 
                    software should be able to integrate with other systems,
                    such as accounting software, to provide a comprehensive view
                    of the mutual fund distribution business.
                  </li>
                </ul>
                <p className="mb-0">
                  Contact us today to learn more about how we can help your
                  mutual fund distribution business succeed in the competitive
                  Indian market.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-4">
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card-box-2 comman-mb mb-0">
                  <div className="padding-20">
                    <div className="small-text">Use Case 1</div>
                    <div className="card-content-4">
                      About the Company: One of leading Investment Education and
                      learning solutions company focused on competency building
                      for the investments and financial services sector in India
                      for over 12 years.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-1"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-box-2 comman-mb mb-0">
                  <div className="padding-20">
                    <div className="small-text">Use Case 2</div>
                    <div className="card-content-4">
                      About the Company: One of leading Financial Training
                      Experts in India. Created 300+ independent financial
                      advisors through the DAP/ExpertMFD platform
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-2"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    One of leading Investment Education and learning solutions
                    company focused on competency building for the investments
                    and financial services sector in India for over 12 years.
                  </p>
                  <div className="heading-20">
                    Currently running four different vertical businesses with 4
                    different platforms:
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Certification Program for Working Professionals (RMs).
                    </li>
                    <li>
                      Certification Program for Students (Potential RMs and
                      IFAs).
                    </li>
                    <li>Certification Program for Entrepreneurs.</li>
                    <li>Online video channel for general investor.</li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    The financial and equity market is growing over the world.
                    However, there are not as many Advisory organizations who
                    can provide the necessary education and information. There
                    is a need to provided more and more information so that
                    licensed Mutual Fund Distributors to support the growing
                    investor base.
                  </p>
                  <p>
                    10+ year old architecture which needs technical, Functional
                    and architectural upgrades. No Scalability and difficult to
                    manage the upgrades. Multiple applications built on
                    different tech stacks.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <div className="heading-20">
                    Web Application: Super Admin | Exam center | Content
                    Learning Portal | Video surfing platform | Hosting on AWS
                  </div>
                  <ul className="list-content mb-15">
                    <li> Enrollment and registration of potential users.</li>
                    <li> Built-in examination and quiz engine.</li>
                    <li>
                      Inbuilt implementation support for preparatory training
                      for NISM Certification.
                    </li>
                    <li> Payment gateway integration.</li>
                    <li> Workflow orchestration for multiple processes.</li>
                    <li>
                      Built-in features to cater operational requirements for
                      assisting the potential distributors in scheduling the
                      NISM Certification, KYD process, applying for ARN, EUIN,
                      Dashboards, Reports, Learning progress review, Distributor
                      Journey Views, Audit Trail, Product configuration,
                      Assessment engine configuration, user management.
                    </li>
                  </ul>
                  <div className="text-center mb-15">
                    <img className="images" alt="diagram30" src={diagram30} />
                  </div>
                  <div className="text-center mb-0 ">
                    <img className="images" alt="diagram" src={diagram31} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 2</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    One of leading Financial Training Experts in India. Created
                    300+ independent financial advisors through the
                    DAP/ExpertMFD platform.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    The Mutual Fund Investor base is growing in India. However,
                    there are not as many Mutual Fund Advisors who can capture
                    the growing investors. There is a need to generate more and
                    more licensed Mutual Fund Distributors to support the
                    growing investor base. Multiple courses and levels in
                    learning path, configurable learning path with optional,
                    mandatory levels, cost/no cost for some products. Custom
                    workflows and journey.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <div className="heading-20">
                    Web Application: Distributor / Ops / AMC / Super Admin |
                    Hosting on AWS | Micro services | CICD Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Enrollment and registration of potential distributors.
                    </li>
                    <li> Capturing registered users’ profile.</li>
                    <li>
                      Learning Path configuration based on chosen journey –
                      ARN/EUIN/CPE.
                    </li>
                    <li>
                      Built-in Pre-Assessment engine to gauge the potential
                      distributors factual and behavioral attributes, scoring
                      mechanism.
                    </li>
                    <li>
                      Integration with third party LMS for preparatory training
                      for NISM Certification.
                    </li>
                    <li> Payment gateway integration.</li>
                    <li>Workflow orchestration for multiple processes.</li>
                  </ul>
                  <p>
                    Built-in features to cater operational requirements for
                    assisting the potential distributors in scheduling the NISM
                    Certification, KYD process, applying for ARN, EUIN,
                    Dashboards, Reports, Learning progress review, Distributor
                    Journey Views, Audit Trail, Product configuration,
                    Assessment engine configuration, user management.
                  </p>
                  <div className="text-center mb-15">
                    <img
                      className="images desk-images"
                      alt="diagram"
                      src={diagram32}
                    />
                  </div>
                  <div className="text-center mb-0 ">
                    <img className="images" alt="diagram" src={diagram24} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>

        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(MutualFundDistributor);
