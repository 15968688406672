import React, { memo } from "react";
import roadmap from "../../assets/img/roadmap.png";

const RoadmapSection = () => {
  return (
    <>
      <div className="main-bg-section-roadmap">
        <img className="images desk-images" src={roadmap} alt="" />
      </div>
    </>
  );
};

export default memo(RoadmapSection);
