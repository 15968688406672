import React, { memo } from "react";
import logo from "../../assets/img/logo.png";
// import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  console.log('Current route:', location.pathname);
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark epi-navbar">
        {/* <a
          className="navbar-brand mr-auto"
          href=""
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} alt="" />
        </a> */}
        <Link 
        className="navbar-brand mr-auto"
        href=""
        to="/" 
        onClick={() => {window.scrollTo(0, 0);}}>
        <img src={logo} alt="" />
        </Link>{" "}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-brand mr-auto" href="#" />
          <ul className="navbar-nav ">
            <li className="nav-item dropdown active">
              {/* <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICES
              </a> */}
              <Link 
                // className="nav-link dropdown-toggle "
                className = {location.pathname.includes("services")? "nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICES
              </Link>{" "}

              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-platformDevelopment");
                  }}
                  href=""
                >
                  Platform Development &amp;
                  <br />
                  Digital Transformation
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/services/platform-development"
                  onClick={() => {}}
                  href=""
                >
                  Platform Development &amp;
                  <br />
                  Digital Transformation
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-biAndAndlytics");
                  }}
                  href=""
                >
                  BI &amp; Analytics
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/services/bi-and-analytics"
                  onClick={() => {}}
                  href=""
                  >
                  BI &amp; Analytics
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-cloudManagment");
                  }}
                  href=""
                >
                  Cloud Management
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/services/cloud-management"
                onClick={() => {}}
                href=""
                >
                Cloud Management
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-rpaSolutions");
                  }}
                  href=""
                >
                  RPA Solutions
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/services/rpa-solutions" 
                  onClick={() => {}}
                  href=""
                >
                RPA Solutions
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-platformAcceleration");
                  }}
                  href=""
                >
                  Platform Accelerators
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/services/platform-acceleration" 
                  onClick={() => {}}
                  href=""
                >
                Platform Accelerators
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/services-testingAndQA");
                  }}
                  href=""
                >
                  Testing &amp; QA Services
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/services/testing-and-qa"
                  onClick={() => {}}
                  href=""
                >
                  Testing &amp; QA Services
                </Link>{" "}
              </div>
            </li>
            <li className="nav-item dropdown">
              {/* <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                INDUSTRIES
              </a> */}
              <Link 
                // className="nav-link dropdown-toggle "
                className = {location.pathname.includes("industries")? "nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                INDUSTRIES
              </Link>{" "}
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-bankingAndFiance");
                  }}
                  href=""
                >
                  Banking &amp; Finance
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/industries/banking-and-finance" 
                onClick={() => {}}
                href=""
                >
                Banking &amp; Finance
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-healthcareAndFarma");
                  }}
                  href=""
                >
                  Healthcare &amp; Pharma
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/industries/healthcare-and-pharma"
                onClick={() => {}}
                href=""
                >
                Healthcare &amp; Pharma
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-travelAndTourism");
                  }}
                  href=""
                >
                  Travel &amp; Tourism
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/industries/travel-and-tourism" 
                  onClick={() => {}}
                  href=""
                >
                Travel &amp; Tourism
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-printAndPublish");
                  }}
                  href=""
                >
                  Print &amp; Publishing
                </a> */}
                <Link 
                  className="dropdown-item boxes"
                  to="/industries/print-and-publish" 
                  onClick={() => {}}
                  href=""
                >
                 Print &amp; Publishing
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-mutualfundDistributor");
                  }}
                  href=""
                >
                  Mutual Funds Distributor
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/industries/mutual-fund-distributor"
                onClick={() => {}}>
                Mutual Funds Distributor
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/industries-labourAndOffice");
                  }}
                  href=""
                >
                  Labor, Office &amp; Factory <br />
                  Law Compliance
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/industries/labour-and-office"
                onClick={() => {}}>
                  Labor, Office &amp; Factory <br />
                  Law Compliance
                </Link>{" "}
              </div>
            </li>
            <li className="nav-item menulink">
              {/* <a
                className="nav-link disabled"
                onClick={() => {
                  navigate("/solutions");
                }}
                href=""
              >
                SOLUTIONS
              </a> */}
              <Link 
                // className="nav-link disabled"
                className = {location.pathname.includes("solutions")? "nav-link disabled active":"nav-link disabled "}
                to="/solutions" 
                onClick={() => {}}>
                SOLUTIONS
              </Link>{" "}
            </li>
            <li className="nav-item dropdown">
              {/* <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ABOUT Us
              </a> */}
              <Link 
                // className="nav-link dropdown-toggle"
                className = {location.pathname.includes("aboutus")? "nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                ABOUT Us
              </Link>{" "}
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <a
                  className="dropdown-item boxes"
                  href=""
                  onClick={() => {
                    navigate("/aboutus-lifeAtExtrapreneurs");
                  }}
                >
                  Life at Extrapreneurs
                </a> */}
                <Link 
                className="dropdown-item boxes"
                href=""
                to="/aboutus/life-at-extrapreneurs" 
                onClick={() => {}}>
                Life at Extrapreneurs
                </Link>{" "}
                {/* <a
                  className="dropdown-item boxes"
                  onClick={() => {
                    navigate("/aboutus-ourCelebrations");
                  }}
                  href=""
                >
                  Our Celebrations
                </a> */}
                <Link 
                className="dropdown-item boxes"
                to="/aboutus/our-celebrations" 
                onClick={() => {}}>
                  Our Celebrations
                </Link>
              </div>
            </li>
            <li className="nav-item menulink">
              {/* <a className="nav-link disabled" href="/blog">
                BLOG
              </a> */}
              <Link 
              // className="nav-link disabled" 
              className = {location.pathname.includes("blog")? "nav-link disabled active":"nav-link disabled "}
              to="/blog">
              BLOG
              </Link>{" "}
            </li>
            <li className="nav-item menulink">
              {/* <a
                className="nav-link disabled"
                href=""
                onClick={() => {
                  navigate("/careers");
                }}
              >
                CAREERS
              </a> */}
              <Link 
                // className="nav-link disabled"
                className = {location.pathname.includes("careers")? "nav-link disabled active":"nav-link disabled "}
                href=""
                to="/careers" 
                onClick={() => {}}>
                  CAREERS
              </Link>{" "}
            </li>
            <li className="nav-item menulink">
              {/* <a
                className="nav-link disabled"
                href=""
                onClick={() => {
                  navigate("/contactus");
                }}
              >
                CONTACT Us
              </a> */}
              <Link 
              // className="nav-link disabled"
              className = {location.pathname.includes("contactus")? "nav-link disabled active":"nav-link disabled "}
              href=""
              to="/contactus"
              onClick={() => {}}>
                CONTACT Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default memo(Header);
