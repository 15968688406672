import Global_var from "../Global/global_var";
import RestDataSource from "./restdatasource";

export const loginService = {

  TokenUser,
  submitQuery,
  getJobList,
  applyJob,
};


function TokenUser(fn) {
  let url = Global_var.URL_GET_TOKEN; //Global_var.BASEURL +
  return new RestDataSource(url).Store(
    {
      "buId": "1",
      "userId": "5e33559d-34f0-45a3-a709-73cb2f746fc3",
      "userName": "saniya",
      "password": "Epi@12345",
      "environment": "dev"
  },
    (res) => {
      // debugger;
      console.log(res)
      // console.log(res.data.responseObject.access_token);
      fn(res.data);
    }
  );
}



function submitQuery(userData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "Success") {
      localStorage.setItem("jwt-token", res.responseObject.access_token);
      var url = Global_var.BASEURL+Global_var.URL_SUBMIT_QUERY;

      return new RestDataSource(url, 0, fn).Store(
        userData,
        (res) => {
          if (res != null) {
            if (res.headers["Authoriazation"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res);
            // }
            // });
          }
        },
        fnError
      );
    }
  });
}

function getJobList(userData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "Success") {
      localStorage.setItem("jwt-token", res.responseObject.access_token);
      var url = Global_var.BASEURL+Global_var.URL_GET_JOB_LIST;

      return new RestDataSource(url, 0, fn).GetOne(
        userData,
        (res) => {
          if (res != null) {
            if (res.headers["Authoriazation"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res);
            // }
            // });
          }
        },
        fnError
      );
    }
  });
}

function applyJob(userData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "Success") {
      localStorage.setItem("jwt-token", res.responseObject.access_token);
      var url = Global_var.BASEURL+Global_var.URL_APPLY_JOB;

      return new RestDataSource(url, 0, fn).Store(
        userData,
        (res) => {
          if (res != null) {
            if (res.headers["Authoriazation"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res);
            // }
            // });
          }
        },
        fnError
      );
    }
  });
}
