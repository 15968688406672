import React, { memo } from "react";
import philosophy from "../../assets/img/our-philosophy.png";

const PhilosophySection = () => {
  return (
    <>
      <div className="main-section">
        <div className="row">
          <div className="col-md-6 comman-mb">
            <img className="images" src={philosophy} alt="" />
          </div>
          <div className="col-md-6">
            {/* <div class="small-title">Our Philosophy</div> */}
            <div className="gradient-title mb-20">Our Philosophy</div>
            <ul className="list-content justifyText">
              <li>
                <div className="heading-20">Our Vision</div>
                Our vision is to establish Extrapreneurs India as a global
                technology services and solutions partner for established
                enterprise business, entrepreneurs, start-ups, and innovators to
                collaborate and create sustainable, long-term value for each
                other. We strive to create a vibrant and inclusive ecosystem
                that will foster innovation, productivity, and collective
                success.
              </li>
              <li>
                <div className="heading-20">Our Mission</div>
                At Extrapreneurs India, our mission is to empower businesses of
                all sizes to achieve their business goals through innovative
                market leading cloud based digital solutions. We strive to
                provide a rich ecosystem of engineering skills that helps teams
                to deliver accelerated system features with high quality and
                reliablity.
              </li>
              <li>
                <div className="heading-20">Our Values</div>
                At Extrapreneurs India, we believe in fostering an environment
                of ownership, innovation, design thinking and global best
                practices. We strive to exceed customer expectations by
                delivering superior quality technology services and solutions
                that are driven by passion, integrity and excellence.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PhilosophySection);
