import React from "react";
import { Routes, Route } from "react-router-dom";

import LandingPage from "../Components/LandingPage";
import PlatformDevelopment from "../Components/ServicesSections/PlatformDevelopment";
import BIAnalytics from "../Components/ServicesSections/BIAnalytics";
import CloudManagment from "../Components/ServicesSections/CloudManagment";
import RPASolutions from "../Components/ServicesSections/RPASolutions";
import PlatformAcceleration from "../Components/ServicesSections/PlatformAcceleration";
import TestingAndQA from "../Components/ServicesSections/TestingAndQA";
import BankingAndFiance from "../Components/IndustriesSection/BankingAndFiance";
import HealthcareAndFarma from "../Components/IndustriesSection/HealthcareAndFarma";
import TravelAndTourism from "../Components/IndustriesSection/TravelAndTourism";
import PrintAndPublish from "../Components/IndustriesSection/PrintAndPublish";
import MutualFundDistributor from "../Components/IndustriesSection/MutualFundDistributor";
import LabourAndOffice from "../Components/IndustriesSection/LabourAndOffice";
import Solutions from "../Components/Solutions";
import CustomerOnboarding from "../Components/Solution/CustomerOnboarding";
import CustomerRelationshipManagement from "../Components/Solution/CustomerRelationshipManagement";
import EmailAnalytics from "../Components/Solution/EmailAnalytics";
import FinancialAnalyst from "../Components/Solution/FinancialAnalyst";
import FinancialPlanning from "../Components/Solution/FinancialPlanning";
import MultifactorAuthentication from "../Components/Solution/MultifactorAuthentication";
import ThorPlatform from "../Components/Solution/ThorPlatform";
import Videokyc from "../Components/Solution/Videokyc";
import LifeAtExtrapreneurs from "../Components/AboutUs/LifeAtExtrapreneurs";
import OurCelebrations from "../Components/AboutUs/OurCelebrations";
import Blogs from "../Components/Blogs/Blogs";
import BlogDetails from "../Components/Blogs/BlogDetails";
import ContactUs from "../Components/ContactUs";
import Careers from "../Components/Careers";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/services/platform-development"
        element={<PlatformDevelopment />}
      />
      <Route path="/services/bi-and-analytics" element={<BIAnalytics />} />
      <Route path="/services/cloud-management" element={<CloudManagment />} />
      <Route path="/services/rpa-solutions" element={<RPASolutions />} />
      <Route
        path="/services/platform-acceleration"
        element={<PlatformAcceleration />}
      />
      <Route path="/services/testing-and-qa" element={<TestingAndQA />} />
      <Route
        path="/industries/banking-and-finance"
        element={<BankingAndFiance />}
      />
      <Route
        path="/industries/healthcare-and-pharma"
        element={<HealthcareAndFarma />}
      />
      <Route
        path="/industries/travel-and-tourism"
        element={<TravelAndTourism />}
      />
      <Route path="/industries/print-and-publish" element={<PrintAndPublish />} />
      <Route
        path="/industries/mutual-fund-distributor"
        element={<MutualFundDistributor />}
      />
      <Route path="/industries/labour-and-office" element={<LabourAndOffice />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route
        path="/solutions/customer-onboarding"
        element={<CustomerOnboarding />}
      />
      <Route
        path="/solutions/customer-relationship-management"
        element={<CustomerRelationshipManagement />}
      />
      <Route path="/solutions/email-analytics" element={<EmailAnalytics />} />
      <Route
        path="/solutions/financial-analyst"
        element={<FinancialAnalyst />}
      />
      <Route
        path="/solutions/financial-planning"
        element={<FinancialPlanning />}
      />
      <Route
        path="/solutions/multifactor-authentication"
        element={<MultifactorAuthentication />}
      />
      <Route path="/solutions/thor-platform" element={<ThorPlatform />} />
      <Route path="/solutions/videokyc" element={<Videokyc />} />
      <Route
        path="/aboutus/life-at-extrapreneurs"
        element={<LifeAtExtrapreneurs />}
      />
      <Route path="/aboutus/our-celebrations" element={<OurCelebrations />} />
      <Route path="/blog" element={<Blogs />} />
      <Route path="/blog/:id" element={<BlogDetails />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/careers" element={<Careers />} />
    </Routes>
  );
};
export default Routers;
