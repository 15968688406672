import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import emailBg from "../../assets/img/email-analytics-bg.png";
import diagram43 from "../../assets/img/digram-43.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const EmailAnalytics = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-email-analytics text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Email Analytics using ChatGPT
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="solutions.html">Solutions</a>{" "} */}
                <Link to="/solution" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="font-45B0DC">
                  {" "}
                  / Email Analytics using ChatGPT
                </span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center solution-img-tp">
                <img className="images " src={emailBg} alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="gradient-title mb-15">
                  Why Email Analytics using ChatGPT is needed?{" "}
                </div>
                <p>
                  An Email marketer generally runs campaigns, collects lead data
                  and compiles reports. But all this would not be rewarding if
                  you cannot draw insights and take action to add value to your
                  email campaigns. That is Why Email Analytics using ChatGPT is
                  needed.
                </p>
                <p>
                  In today’s word, we need to track how your subscribers
                  interact with your emails by tracking different metrics that
                  deal with email delivery, engagement, and conversions. Such
                  analytics helps email marketers draw valuable insights and
                  optimize and strategize email campaigns for better
                  performance.
                </p>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="gradient-title mb-15">
                    Email Analytics using ChatGPT Solution
                  </div>
                  <p>
                    THOR- Email Analytics using ChatGPT is a solution built on
                    the THOR platform and ChatGPT which provides analysis of
                    email content through easy REST based API integration. This
                    is a backend solution (Backend in Python) that can be hosted
                    on AWS.
                  </p>
                  <p>
                    For analytics purpose, we have used ChatGPT (Chat Generative
                    Pre-Trained Transformer). It is a Chabot developed by OpenAI
                    which is built on top of OpenAI’s GPT-3 family of large
                    language models.
                  </p>
                  <div className="heading-20 mb-20">
                    THOR- Email Analytics using ChatGPT primary features
                    include:
                  </div>
                  <ul className="list-content">
                    <li>
                      Auto respond to email by generating response from ChatGPT
                      AI tool
                    </li>
                    <li>Classify email into different categories</li>
                    <li>Gather Contact data from emails</li>
                    <li>
                      Download attachments/documents from email using
                      THOR-Document Management service
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <img
                    id="myImg"
                    src={diagram43}
                    onClick={() => {
                      openModal(diagram43);
                    }}
                    className="img-shadow width-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="gradient-title  text-center">
                    Benefits of THOR- Email Analytics using ChatGPT
                  </div>
                </div>
                <div className="col-md-12 mb-20">
                  <div className="heading-20 mb-20 text-center">
                    THOR- Email Analytics using ChatGPT is beneficial due to
                    following main characteristics:
                  </div>
                  <ul className="list-content-6">
                    <li>
                      The architecture follows the same principals as the THOR
                      platform and is scalable and secure.
                    </li>
                    <li>It is built using open source tools and software.</li>
                    <li>It is very easy to integrate with any solution</li>
                    <li>
                      It provides the core features and is well suited for SaaS,
                      B2B and B2C platforms based business models.
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="heading-20 mb-20 text-center">
                    Other key functional benefits are:
                  </div>
                  <ul className="list-content-6">
                    <li>
                      Service can provide instant responses to your queries, as
                      it is designed to quickly process and analyze natural
                      language input.
                    </li>
                    <li>
                      Wide range of knowledge: ChatGPT has been trained on a
                      vast corpus of text data, which means it can provide
                      information on a wide range of topics and fields.
                    </li>
                    <li>
                      24/7 availability: ChatGPT is available 24/7, which means
                      you can ask it questions or seek its assistance anytime,
                      anywhere.
                    </li>
                    <li>
                      Overall, ChatGPT can be a valuable resource for
                      individuals seeking information, answers to their
                      questions, or just looking for a chatbot to chat with.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="gradient-title  text-center">
                    Use cases implemented using THOR- Email Analytics using
                    ChatGPT
                  </div>
                </div>
                <div className="col-md-12 mb-20">
                  <div className="heading-20 mb-20 text-center">
                    With the help of THOR- Email Analytics using ChatGPT
                    services we have implemented below 4 use cases:
                  </div>
                  <ul className="list-content-6">
                    <li>
                      <span className="bullet-title">Usecase # 1:</span> Scan
                      the mailbox, Read the email and Auto-respond to email
                      using ChatGPT
                    </li>
                    <li>
                      <span className="bullet-title">Use case # 2:</span> Scan
                      the mailbox, Read the email and Classify the email into
                      one of the given categories using ChatGPT{" "}
                    </li>
                    <li>
                      <span className="bullet-title">Use case # 3:</span> Scan
                      the mailbox, Read the email and extract the contact
                      information including name, address, email, phone number,
                      etc. from the email using ChatGPT
                    </li>
                    <li>
                      <span className="bullet-title">Use case # 4:</span> Scan
                      the mailbox, Read the email and download the attachments
                      from email using ChatGPT and provide document summary
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}
          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt=""
              />
              <div id="caption" />
            </div>
          </div>
          {/* ..................................................modal end...................................................... */}
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(EmailAnalytics);
