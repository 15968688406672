import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import cobImg from "../../assets/img/cob-img.png";
import diagram33 from "../../assets/img/digram-33.png";
import pdfIcon from "../../assets/img/pdf-icon.png";
import cobImg2 from "../../assets/img/cob-img-2.png";
import cobImg3 from "../../assets/img/cob-img-3.png";
import cobImg4 from "../../assets/img/cob-img-4.png";
import cobImg5 from "../../assets/img/cob-img-5.png";
import cobImg6 from "../../assets/img/cob-img-6.png";
import cobImg7 from "../../assets/img/cob-img-7.png";
import cobImg8 from "../../assets/img/cob-img-8.png";
import cobImg9 from "../../assets/img/cob-img-9.png";
import cobImg10 from "../../assets/img/cob-img-10.png";
import cobImg11 from "../../assets/img/cob-img-11.png";
import cobImg12 from "../../assets/img/cob-img-12.png";
import cobImg13 from "../../assets/img/cob-img-13.png";
import cobImg14 from "../../assets/img/cob-img-14.png";
import cobPdf from "./cob-sample-pdf.pdf";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const CustomerOnboarding = () => {
  // const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const changeSlide = () => {
    if (index > slides.length - 1) setIndex(0);

    if (index < 0) setIndex(slides.length - 1);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  // Call the changeSlide function whenever the index changes
  React.useEffect(() => {
    changeSlide();
    // eslint-disable-next-line
  }, [index]);

  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div className="banner-section-cob text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Customer Onboarding
            </div>
            <div className="breadcrumb-links">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a>{" "} */}
              <Link to="/" onClick={() => {}}>
                  Home
              </Link>{" "}
              <span className="font-white"> / </span>
              {/* <a href="solutions.html">Solutions</a>{" "} */}
              <Link to="/solutions" onClick={() => {}}>
              Solutions
              </Link>{" "}
              <span className="color-45B0DC"> / Customer Onboarding</span>
            </div>
          </div>
        </div>

        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb text-center solution-img-tp">
              <img className="images" src={cobImg} alt="" />
            </div>
            <div className="col-md-6">
              <div className="gradient-title mb-15">
                Why is Customer Onboarding needed?
              </div>
              <p>
                Customer Onboarding or client onboarding is a crucial step of
                the customer journey encompassing the entire customer lifecycle,
                including user onboarding. It is dedicated to guiding customers
                through every stage of the customer onboarding journey using
                marketing efforts, customer engagement strategies, customer
                onboarding software, onboarding content, and more. Essentially,
                the main goal of this customer onboarding process is to help
                users find value in the product and bring value to the product
                to make sure customer churn is avoided, and customer retention
                is achieved regularly.
              </p>
              <p>
                The customer onboarding process is probably the most important
                stage of the customer lifecycle. While it comes right at the
                start of their journey, it sets the tone for their whole
                relationship with your product and company.
              </p>
              <p>
                {" "}
                Customer onboarding is the process that new users go through to
                get set up and start using your product. It covers the whole
                journey: from initial sign-up to product activation and first
                use. Customer onboarding aims to deliver value to your customer
                as early as possible — in their first use
              </p>
              <p>
                Customer onboarding is so important because it sets the tone for
                the ongoing relationship your customer has with your product.
              </p>
              <p className="mb-0">
                {" "}
                A good onboarding process will keep your customers engaged.
                Helping them clearly understand and experience the value they’ll
                get from your product will mean they start using it
                successfully, but more importantly, it’ll give them a reason to
                log back in and use your product again and again.
              </p>
            </div>
          </div>
        </div>

        <div className="gray-bg-section-img-slider ">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12 comman-mb mb-50">
                <div className="gradient-title text-center">
                  Customer Onboarding Process Flow
                </div>
                <div className="text-center">
                  <img className="images" src={diagram33} alt="" />
                </div>
              </div>
              <div className="custom-slider">
                <div className="gradient-title text-center">
                  How Customer Onboarding works?
                </div>
                <div id="slider">
                  <div className="slide" style={{ display: "block" }}>
                    <img src={cobImg2} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg3} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg4} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg5} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg6} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg7} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg8} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg9} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg10} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg11} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg12} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg13} alt="" />
                  </div>
                  <div className="slide">
                    <img src={cobImg14} alt="" />
                  </div>
                  {/*Controlling arrows*/}
                  <span
                    className="controls"
                    id="left-arrow"
                    onClick={() => prevSlide(-1)}
                  >
                    {" "}
                    <i className="fa-solid fa-angle-left" />
                  </span>
                  <span
                    className="controls"
                    id="right-arrow"
                    onClick={() => nextSlide(1)}
                  >
                    <i className="fa-solid fa-angle-right" />
                  </span>
                </div>
                <div id="dots-con">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </div>
                <div className="heading-20 mt-25">
                  Sample Customer Onboarding PDF
                </div>
                <div className="pdf-button">
                  {/* <a
                    href={cobPdf}
                    target="_blank"
                    className="btn p10-0"
                    rel="noreferrer"
                  >
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="ml-10">Customer Onboarding</span>
                  </a> */}
                    <Link  
                      // href={cobPdf}
                      to={cobPdf}
                      target="_blank"
                      className="btn p10-0"
                      rel="noreferrer">
                      <img src={pdfIcon} alt="" />{" "}
                      <span className="ml-10">Customer Onboarding </span>
                    </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title mb-20">COB Benefits</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ul className="list-content comman-mb">
                <li>
                  {" "}
                  Onboard customers digitally online over web and mobile within
                  minutes.{" "}
                </li>
                <li>
                  Ability to configure the process as per the product and
                  regulatory compliances.{" "}
                </li>
                <li>
                  Keep the process simple for an average users to complete it
                  without any support.{" "}
                </li>
                <li>
                  Provide self service tools to reduce operational overheads of
                  a back-office/call center team.
                </li>
                <li>
                  Provide strong security to safeguard user identity from
                  internal and external teams.{" "}
                </li>
                <li>
                  Address drop-outs by nudging users to complete the journey.{" "}
                </li>
                <li>Fully Digital. </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-content comman-mb">
                <li>Frictionless end to end (no manual step). </li>
                <li>Reduce abandonment rates. </li>
                <li>
                  Process Flow should be very simple to understand and execute
                  in Self Service mode.{" "}
                </li>
                <li>Access to FAQ for account opening (for each product). </li>
                <li>
                  Show video of the process flow to showcase the entire journey.{" "}
                </li>
                <li>
                  Ability to use from mobile, desktop, WhatsApp chatbot, other
                  channels.{" "}
                </li>
                <li>API first design. </li>
                <li>
                  Initiate, Pause and continue Account Opening journey
                  seamlessly.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ..................................................modal start...................................................... */}
        <div className="custom-modal">
          <div id="myModal" className="modal">
            <span className="close">×</span>
            <img className="modal-content" id="img01" alt="" />
            <div id="caption" />
          </div>
        </div>
        {/* ..................................................modal end...................................................... */}
      </WrapComponent>
    </>
  );
};

export default memo(CustomerOnboarding);
