import './App.css';
import Routers from './Routes/Routes.js';
import { ToastContainer } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <Routers />
    <ToastContainer />
    </>
  );
}

export default App;
