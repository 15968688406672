import React, { useState, useEffect } from "react";
import WrapComponent from "./Common/WrapComponent";
//import { Formik, Form, Field, ErrorMessage } from "formik";
//import * as Yup from "yup";
import { loginService } from "../Services/loginService";
//import { ToastContainer } from "react-toastify";
import CleanText from "./CleanText";
import { Link } from "react-router-dom";
import ScrollToTopButton from "./Common/ScrollToTop";
//import axios from "axios";
//import Global_var from "../Global/global_var";
import { InfinitySpin } from "react-loader-spinner";
import WithToggle from "./Common/WithToggle";
import ApplyToPosition from "./Common/ApplyToPosition";
import { string } from "yup";
//import data from "./Common/job.json";
// import shapeImg from "../assets/img/shape-img.png";
import shapeImgPos from "../assets/img/shape-img.png";
import shapeBigImgPos from "../assets/img/shape-big-img.png";

const Careers = () => {
  // const validationSchema = Yup.object({
  //   name: Yup.string().required("Full Name is required"),
  //   email_id: Yup.string()
  //     .email("Invalid email address")
  //     .required("Email is required"),
  //   phone: Yup.string().required("Phone is required"),
  //   message: Yup.string().required("Message is required"),
  // });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal() {
    //alert("inside");
    setModalVisible(true);
    //setModalImgSrc(src);
  }
  function closeModal() {
    //alert("inside close");
    setModalVisible(false);
  }

  const [loading, setLoading] = useState(true);
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    loginService.getJobList(
      {},
      (res) => {
        console.log("API Response:", res.data);
        setJobList(res.data.responseListObject);
        const modifiedList = res.data.responseListObject.map((jobdata) => {
          setLoading(false);
          return {
            ...jobdata,
            showForm: false,
          };
        });
        console.log(modifiedList);
        // debugger;
      },
      (error) => {
        console.log("Error while fetching job List:", error);
      }
    );
  }, []);

  const handleApplyClick = (job) => {
    console.log("Apply button clicked");
    console.log("job", job);
    setSelectedJob(job);
    console.log(setSelectedJob);
  };

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5", {
          action: "homepage",
        })
        .then((token) => {
          // ...
        });
    });
  };

  const handleApplyClose = () => {
    window.location.reload();
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5";
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  return (
    <WrapComponent>
      <ScrollToTopButton />

      <div>
        <div className="banner-section-4 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">Careers</div>
            <div className="breadcrumb-links ">
              {/* <a
                  href=""
                  onClick={() => {
                    navigate("/");
                  }}
                > */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span style={{ color: "#45B0DC" }}> / Careers</span>
            </div>
          </div>
        </div>
        <div className="main-section-3">
          <div className="custom-text-center-2 mb-50">
            <div className="gradient-title-2">Current Job Openings</div>
            Explore all the vacancies in your area according to your expertise
            for unlimited opportunities to develop your career
          </div>
          {loading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "200px",
              }}
            >
              <InfinitySpin width="1000" color="#aec10b" />
            </div>
          ) : (
            <div id="accordion" className="">
              <div className="row">
                {jobList.map((job, index) => (
                  <div className="col-md-4 mb-30">
                    <div className="" key={index}>
                      {/* Career card Changes Start here */}
                      <div className="" id={`heading${index}`}>
                        <div className="positionBox">
                          <div
                            className="positionImgBox"
                            aria-labelledby={`heading${index}`}
                          >
                            <div className="positiontitle">
                              {job.job_title}{" "}
                            </div>
                            <div className="positionReq">
                              <div className="width-50">
                                <div className="experience">
                                  <b>Experience:</b>{" "}
                                  {
                                    JSON.parse(job.description).description
                                      .experience
                                  }
                                </div>
                                {JSON.parse(job.description).description
                                  .salary && (
                                  <div className="location">
                                    <b>Salary:</b>{" "}
                                    {
                                      JSON.parse(job.description).description
                                        .salary
                                    }
                                  </div>
                                )}
                              </div>

                              <div>
                                <div className="experience">
                                  <b>Openings: </b>{" "}
                                  {
                                    JSON.parse(job.description).description
                                      .openings
                                  }
                                </div>
                                <div className="location">
                                  <b>Location:</b>{" "}
                                  {
                                    JSON.parse(job.description).description
                                      .location
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="location">
                              <b>Availability:</b>{" "}
                              {
                                JSON.parse(job.description).description
                                  .availability
                              }
                            </div>
                          </div>

                          <img
                            className="width-100 widthPos-100"
                            src={shapeImgPos}
                          />
                          <div className="mainContent">
                            <div className="positionSubtitle">
                              Requirements:
                            </div>

                            {JSON.parse(job.description)
                              .description.descr.requirements.slice(0, 3)
                              .map((req) => (
                                <ul className="positionContent">
                                  <li>{req.req}</li>
                                </ul>
                              ))}
                          </div>
                          <div className="applyBtn">
                            <div className="width-100">
                              <button
                                class="btn btn-primary custom-button "
                                type="submit"
                                data-toggle="modal"
                                data-target={`#applyToPos_${index}`}
                                onClick={closeModal}
                              >
                                Apply Now
                              </button>
                            </div>
                            <div
                              class="btn keep-reading-btn  mt-0"
                              type="submit"
                              data-toggle="modal"
                              data-target={`#coreJavaReqDetails_${index}`}
                              onClick={openModal}
                            >
                              View & Apply
                              <i className="fa-solid fa-arrow-right ml-5"></i>
                            </div>
                          </div>
                        </div>
                        <WithToggle id={`coreJavaReqDetails_${index}`}>
                          <>
                            <div
                              className="modal-dialog modalDialog"
                              role="document"
                              style={{
                                display: modalVisible ? "block" : "none",
                              }}
                            >
                              <div className="modal-content" key={index}>
                                <div className="">
                                  <div className="">
                                    <div className="positionBigBox">
                                      <div
                                        className="positionBigImgBox"
                                        id={`heading${index}`}
                                      >
                                        <div className="modal-header modalHeader closeBtn">
                                          <button
                                            type="button"
                                            className="close closeWhiteBtn"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span
                                              aria-hidden="true"
                                              className=""
                                            >
                                              ×
                                            </span>
                                          </button>
                                        </div>
                                        <div class="flexCareer width-100 positionBigBox">
                                          <div class="careerspace width-100">
                                            <div className="positiontitle">
                                              {" "}
                                              {job.post_title}{" "}
                                            </div>
                                            <div className="positionReq">
                                              <div className="width-50">
                                                <div className="experience">
                                                  <b>Experience:</b>{" "}
                                                  {
                                                    JSON.parse(job.description)
                                                      .description.experience
                                                  }
                                                </div>
                                                {JSON.parse(job.description)
                                                  .description.salary && (
                                                  <div className="location">
                                                    <b>Salary:</b>{" "}
                                                    {
                                                      JSON.parse(
                                                        job.description
                                                      ).description.salary
                                                    }
                                                  </div>
                                                )}
                                              </div>

                                              <div>
                                                <div className="experience">
                                                  <b>Openings:</b>{" "}
                                                  {
                                                    JSON.parse(job.description)
                                                      .description.openings
                                                  }
                                                </div>
                                                <div className="location">
                                                  <b>Location:</b>{" "}
                                                  {
                                                    JSON.parse(job.description)
                                                      .description.location
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                            <div className="location">
                                              <b>Availability:</b>{" "}
                                              {
                                                JSON.parse(job.description)
                                                  .description.availability
                                              }
                                            </div>
                                          </div>
                                          <div
                                            class="text-left mt-15 topOrgBtn"
                                            type="submit"
                                            data-toggle="modal"
                                            data-target={`#applyToPos_${index}`}
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-primary custom-button"
                                              onClick={closeModal}
                                            >
                                              Apply Now
                                            </button>

                                            <i class="fa-solid ml-5"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <img
                                        className="width-100 widthPos-100"
                                        src={shapeBigImgPos}
                                      />
                                      <div class="mainContent2">
                                        <div className="positionSubtitle">
                                          Requirements:
                                        </div>
                                        {JSON.parse(
                                          job.description
                                        ).description.descr.requirements.map(
                                          (req) => (
                                            <ul className="positionContent">
                                              <li>{req.req}</li>
                                            </ul>
                                          )
                                        )}
                                      </div>
                                      <div
                                        class="text-right mt-15 applyBtn1"
                                        type="submit"
                                        data-toggle="modal"
                                        data-target={`#applyToPos_${index}`}
                                      >
                                        <button
                                          type="submit"
                                          class="btn btn-primary custom-button"
                                          onClick={closeModal}
                                        >
                                          Apply Now
                                        </button>
                                        <i class="fa-solid ml-5"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </WithToggle>
                        <WithToggle id={`applyToPos_${index}`}>
                          <>
                            <div className="modal-backdrop modalBackdrop">
                              <div
                                className="modal-dialog smallModalDialog"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="">
                                    <div className="">
                                      <div className="">
                                        <div className="modal-header modalHeader ">
                                          <button
                                            type="button"
                                            className="close "
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleApplyClose}
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div className="modal-body modalBody">
                                          <ApplyToPosition
                                            job_title={job.job_title}
                                            job_id={job.ID}
                                            post_title={job.post_title}
                                            description={
                                              JSON.parse(job.description)
                                                .description
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </WithToggle>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </WrapComponent>
  );
};

export default Careers;
