import React, { memo } from "react";
import banner1 from "../../assets/img/banner-1.png";
import banner2 from "../../assets/img/banner-2.png";
import banner3 from "../../assets/img/banner-3.png";
import banner4 from "../../assets/img/banner-4.png";

const SliderSection = () => {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide bg-black"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to={0}
            className="active"
          />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
          <li data-target="#carouselExampleIndicators" data-slide-to={3} />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src={banner1} alt="First slide" />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="banner-gradient-title-49">
                Conceptualize, Architect &amp; Implement Cloud Native Digital
                Platforms
              </h5>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={banner2} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="banner-gradient-title-49 ">
                Partner of Choice For Leading Businesses Across The Globe
              </h5>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={banner3} alt="Third slide" />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="banner-gradient-title-49 ">
                {" "}
                Leverage Our Full Stack Engineering Services For Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={banner4} alt="Third slide" />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="banner-gradient-title-49 ">
                {" "}
                Invest in Early Stage Startups &amp; Provide Technology, Design
                &amp; Product Development Services For MVP &amp; GTM
              </h5>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <i className="fa-solid fa-angle-left banner-btn" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <i className="fa-solid fa-angle-right banner-btn" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
};

export default memo(SliderSection);
