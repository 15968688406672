import { useState, useEffect } from "react";
function ScrollToTopButton() {
    const [showButton, setShowButton] = useState(false);
  
    useEffect(() => {
      // Add a scroll event listener to the window
      const handleScroll = () => {
        const scrollY = window.scrollY;
        const viewportHeight = window.innerHeight;
        const scrollPercentage = (scrollY / viewportHeight) * 100;
  
        // Show the button when the user scrolls down 100vh (100%)
        if (scrollPercentage >= 100) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Scroll smoothly to the top
      });
    };
  
    return (
      <div>
        {/* Conditionally render the button */}
        {showButton && (
          <button className="btn scrollToTopBtn showBtn" onClick={scrollToTop}>
          <i className="fa-solid fa-arrow-up" />
       </button>
        )}
        {/* Your component's content */}
      </div>
    );
  }
  
  export default ScrollToTopButton;