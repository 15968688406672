import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import thorImg from "../../assets/img/thor-img.png";
import keyHeighLight from "../../assets/img/keyhighlight-img.png";
import icon1 from "../../assets/img/icon-1.png";
import icon2 from "../../assets/img/icon-2.png";
import icon3 from "../../assets/img/icon-3.png";
import icon4 from "../../assets/img/icon-4.png";
import icon5 from "../../assets/img/icon-5.png";
import icon6 from "../../assets/img/icon-6.png";
import icon7 from "../../assets/img/icon-7.png";
import icon8 from "../../assets/img/icon-8.png";
import icon9 from "../../assets/img/icon-9.png";
import icon10 from "../../assets/img/icon-10.png";
import icon11 from "../../assets/img/icon-11.png";
import domainModule from "../../assets/img/domain-specific-modules-img.png";
import cobImg1 from "../../assets/img/cob-img-1.png";
import diagram28 from "../../assets/img/digram-29.png";
import diagram24 from "../../assets/img/digram-24.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const ThorPlatform = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }

  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/> 
        <div>
          <div className="banner-section-thor text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Thor Platform Solution
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="solutions.html">Solutions</a>{" "} */}
                <Link to="/solutions" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="font-45B0DC"> / Thor Platform Solution</span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center solution-img-tp">
                <img className="images" src={thorImg} alt="" />
              </div>
              <div className="col-md-6">
                <div className="gradient-title mb-15">
                  Why THOR API first platform?
                </div>
                <p>
                  As more organizations move to the cloud, they implement
                  processes to deal with new microservices architectures,
                  containerization, and continuous delivery. Whether they’re
                  adopting cloud services or transitioning to a cloud
                  infrastructure, an API-first approach can help you manage the
                  complexity of working in the cloud.
                </p>
                <p>
                  The traditional code-first approach to app development
                  sometimes results in delays, rework, or a disjointed
                  experience for the developer, especially in this cloud-driven
                  landscape.
                </p>
                <p>
                  {" "}
                  An API-first approach assumes the design and development of an
                  application programming interface (API) comes before the
                  implementation. Your team begins by creating an interface for
                  their application. After the API has been developed, the team
                  will rely on this interface to build the rest of the
                  application.
                </p>
                <p>
                  By introducing new features as an independent service accessed
                  by API, the rest of the app can be stitched together, along
                  with any other future apps.
                </p>
                <p className="mb-0">
                  With an API-first approach, instead of starting with code, you
                  could start with design, planning, mocks, and tests. As you
                  might already suspect, this process is aligned with the
                  popular agile software development principle of iterating
                  rapidly. This allows the team and any other stakeholders to
                  weigh in on the proposed direction and functionality, early
                  and often.
                </p>
              </div>
            </div>
          </div>
          <div className="main-bg-section-5">
            <div className="main-section">
              <div className="custom-text-center">
                <div className="gradient-title">THOR Benefits</div>
              </div>
              <div className="row mb-20">
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-1">
                      <div className="heading-22">Plug &amp; Play</div>
                      <p>
                        Treat REST APIs (Micro services) as Lego Blocks to help
                        build Business Applications in Agile model
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-2">
                      <div className="heading-22">Speedy Delivery </div>
                      <p>
                        Avoid lead time, cost and effort to build boiler plate
                        services that offers no strong differentiation to any
                        application and is a necessary hygiene
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-3">
                      <div className="heading-22">Easy to Implement</div>
                      <p>
                        Abstraction layer helps upgrade the services seamlessly
                        without impacting existing application features and
                        functions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-4">
                      <div className="heading-22">Best Practices</div>
                      <p>
                        Inherit strong and best practices for building,
                        deploying and monitoring applications in the cloud
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-5">
                      <div className="heading-22">Latest Tech</div>
                      <p>
                        Value added solutions leveraging AI, ML, RPA to provide
                        out of box product differentiators to end consumers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-5">
                    <div className="card-box-number-6">
                      <div className="heading-22">Robust &amp; Proven </div>
                      <p>Proven in various platforms successfully running</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-text-center ">
                <div className="gradient-title mb-20">Thor Use Cases</div>
                <div className="paragraph-content mb-20">
                  <p>
                    {" "}
                    THOR can help reduce overall effort required to deliver
                    cloud based enterprise and retail applications by 60-70%.
                    Major effort goes in building the User Interfaces (Portals
                    and Mobile Apps) and Domain specific Business services
                    (APIs) that the solution needs to deliver. Some of the
                    solutions where THOR is a strong fit
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card-box mb-0 comman-mb">
                    <div className="small-text">Use Case 1</div>
                    <div className="card-content">
                      {" "}
                      About the Company: Customer onboarding is the process of
                      introducing a customer to a new product or service. It is
                      designed to ensure that the customer has a positive
                      experience and is able to quickly and easily
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-1"
                    >
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box mb-0 comman-mb">
                    <div className="small-text">Use Case 2</div>
                    <div className="card-content">
                      {" "}
                      About the Company: Customer is a USA based organization
                      that provides billing assistance to the healthcare
                      industry. The Company's services include collections,
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-2"
                    >
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-30">
                  <div className="card-box mb-0">
                    <div className="small-text">Use Case 3</div>
                    <div className="card-content">
                      About the Company: Customer is one of the USA’s leading
                      providers of data processing, printing, and mailing of
                      information-sensitive documents.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-3"
                    >
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box mb-0 ">
                    <div className="small-text">Use Case 4</div>
                    <div className="card-content">
                      About the Company: India’s leading Professional education
                      platform for MF industry. Founded by a group of
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-4"
                    >
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="gradient-title mb-15">THOR Key Highlights</div>
                <ul className="list-content comman-mb">
                  <li>
                    THOR provides pre-built platform backend components packaged
                    as services (REST APIs)
                  </li>
                  <li>
                    Back-end services are needed to build, implement and manage
                    Enterprise Applications, Portals or Platforms
                  </li>
                  <li>
                    It is built using open source tools and software (top
                    preference)
                  </li>
                  <li>
                    The Cloud Native Micro services Architecture ensures that it
                    is extensible, inter operable and can be integrated into
                    existing and new Applications with super ease
                  </li>
                  <li>
                    It provides a productivity leverage of more than 1000+
                    person months of effort
                  </li>
                  <li>
                    It’s built for enterprise scale and security to run SaaS,
                    B2B and B2C platform based business models
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-center">
                <img
                  id="myImg"
                  src={keyHeighLight}
                  onClick={() => {
                    openModal(keyHeighLight);
                    console.log("Clicked on img");
                  }}
                  className="img-shadow width-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="custom-text-center">
                <div className="gradient-title">THOR Foundation Modules</div>
              </div>
              <div className="row mb-15">
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <img src={icon1} className="mb-15" alt="" />
                      <div className="heading-22">Foundation</div>
                      <p>
                        {" "}
                        Basic services covering security, audit, configuration,
                        notification, document, currency, location, master data,
                        lookup data and more
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <div className="mb-15">
                        <img src={icon2} alt="" />
                      </div>
                      <div className="heading-22">SaaS</div>
                      <p>
                        SaaS services for multi-tenant configuration, products,
                        subscriptions, billing and white labelling
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <img src={icon3} className="mb-15" alt="" />
                      <div className="heading-22">Content</div>
                      <p>
                        {" "}
                        Services to extract data from content, convert content
                        to different formats, generate documents using
                        templates, tinyURL, QR code and many more
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <img src={icon4} className="mb-15" alt="" />
                      <div className="heading-22">RPA</div>
                      <p>
                        {" "}
                        Robotic automation services to process unstructured
                        documents and extract data for processing, analytics and
                        other use cases – covers websites, portals, pdf
                        documents and Images
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <img src={icon5} className="mb-15" alt="" />
                      <div className="heading-22">Security</div>
                      <p>
                        {" "}
                        Basic services covering security, audit, configuration,
                        notification, document, currency, location, master data,
                        lookup data and more
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3">
                    <div className="text-center">
                      <img src={icon6} className="mb-15" alt="" />
                      <div className="heading-22">Secure Vault</div>
                      <p>
                        {" "}
                        Services to encrypt and decrypt data at rest and in
                        motion with efficient keys management
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3 mb-0">
                    <div className="text-center">
                      <div className="mb-15">
                        <img src={icon7} alt="" />
                      </div>
                      <div className="heading-22">Utility</div>
                      <p>
                        {" "}
                        Log file shipping for monitoring, chat, tinyURL, QR
                        code, and other miscellaneous services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-text-center ">
                <div className="gradient-title">THOR Business Modules</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="card-box-3 mb-0 comman-mb">
                    <div className="text-center">
                      <img src={icon8} className="mb-15" alt="" />
                      <div className="heading-22">Master Data Management</div>
                      <p>
                        {" "}
                        Services for managing the metadata and CRUD operations
                        for typical business masters like Bank, Branch, Country,
                        Currency, Lookup Lists etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3 mb-0 comman-mb">
                    <div className="text-center">
                      <div className="mb-15">
                        <img src={icon9} alt="" />
                      </div>
                      <div className="heading-22">LMS</div>
                      <p>
                        Learning Management services for assessment or testing,
                        content, courses, program management, study plans,
                        scheduling, tracking, scoring and certificate generation
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3 mb-0 comman-mb">
                    <div className="text-center">
                      <img src={icon10} className="mb-15" alt="" />
                      <div className="heading-22">eCommerce</div>
                      <p>
                        {" "}
                        eCommerce services for order management, invoice
                        generation, product, pricing, discounts, delivery
                        tracking, returns management
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-box-3 mb-0">
                    <div className="text-center">
                      <img src={icon11} className="mb-15" alt="" />
                      <div className="heading-22">Payments</div>
                      <p>
                        Payment services for online and offline payments.
                        Seamless integration with payment gateways, recurring
                        payments, payment plans, promotions, discounts
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb">
                <div className="gradient-title mb-15">
                  THOR Domain Specific Modules
                </div>
                <p>
                  Domain specific targeted solutions build on top of THOR
                  Foundation and Business modules
                </p>
                <ul className="list-content-1">
                  <li>eKYC</li>
                  <li>Wealth Management</li>
                  <li>Financial Planning</li>
                  <li>Lending</li>
                  <li>Insurance</li>
                  <li>Inventory Management</li>
                  <li>Invoice Payables Automation</li>
                  <li>Supply Chain Automation</li>
                </ul>
                <p>
                  THOR comes with out of the box ready to operate Admin Portals
                  for the Platform management team and Tenant teams. These are
                  responsive webs apps.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img
                  id="myImg1"
                  src={domainModule}
                  onClick={() => {
                    openModal(domainModule);
                  }}
                  className="img-shadow width-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}

          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt="Loading.."
              />
              <div id="caption" />
            </div>
          </div>

          {/* ..................................................modal end...................................................... */}
          {/* ..........................................modal start...................................... */}
          <div
            className="modal fade"
            id="useCase-1"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Customer onboarding is the process of introducing a customer
                    to a new product or service. It is designed to ensure that
                    the customer has a positive experience and is able to
                    quickly and easily understand the product or service and how
                    to use it. Onboarding typically involves customer education,
                    training, and support. It can include activities such as
                    customer onboarding calls, webinars, video tutorials,
                    customer service, and product demonstrations.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Financial products have their own Account Opening nuances
                      based on regulatory requirements.
                    </li>
                    <li>
                      The onboarding process includes multiple cross-functional
                      teams involved.
                    </li>
                    <li>
                      Volatile regulatory environment: changes to the process,
                      data and underlying systems to comply.
                    </li>
                    <li>Ownership and in-efficient collaboration.</li>
                    <li>
                      Dealing with unstructured/semi-structured documents and
                      data.
                    </li>
                    <li>Integrating with Legacy Back-office systems.</li>
                    <li>
                      Integration with the India Stack services for identity,
                      e-Sign and Storage.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Speed &amp; automated onboarding: Timely engagement and
                      costs, No manual processes.
                    </li>
                    <li>
                      Easy Access: Mobile friendly is the present and the
                      future.
                    </li>
                    <li>
                      Ability to configure the process as per the product and
                      regulatory compliances and organizations.
                    </li>
                    <li>
                      Keep the process simple: An average users to complete it
                      without any support.
                    </li>
                    <li>
                      Provide self service tools to reduce operational overheads
                      of a back-office/call center team.
                    </li>
                    <li>
                      Provide strong security to safeguard user identity from
                      internal and external teams.
                    </li>
                    <li>
                      Address drop-outs by nudging users to complete the
                      journey.
                    </li>
                    <li>
                      Provide building blocks to quickly stitch together an
                      Onboarding journey.
                    </li>
                    <li>
                      Salesforce® tech bring all the cross functional teams
                      together.
                    </li>
                    <li>
                      Leverage the India Stack for Digital Identity and
                      Signatures.
                    </li>
                    <li>
                      Leverage AI/ML for scanning documents and photos to
                      eliminate manual overheads.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img className="images desk-images" src={cobImg1} alt="" />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ..........................................modal end...................................... */}
          {/* ..........................................modal start...................................... */}
          <div
            className="modal fade"
            id="useCase-2"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 2</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Customer is a USA based organization that provides billing
                    assistance to the healthcare industry.
                  </p>
                  <p>
                    The Company's services include collections, pre-collections,
                    legal and judgment recovery, and billing assistance.
                    Customer offers legal services in addition to its billing
                    and collections services.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Patients were paying their hospital bills from different
                      platforms, such as from Payment Portal, from customer site
                      and directly from Hospital.
                    </li>
                    <li>
                      But the problem is the Payment synching, the other
                      platform was showing older bill amounts.
                    </li>
                    <li>
                      The payment plans and schedule for payment were not
                      intuitive and clear enough.
                    </li>
                    <li>Issue in Partial Payment functionality.</li>
                    <li>PCI DSS certification needed.</li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Provided a solution to sync the paid bill amount across
                      the platforms and which helps users to pay the actual bill
                      amount.{" "}
                    </li>
                    <li>Provided single platform to pay the bills. </li>
                    <li>
                      Solution provided for PCI DSS compliance transactions to
                      make secure payments.
                    </li>
                    <li>
                      Provided functionality for Partial payment, Full Payment.{" "}
                    </li>
                    <li>
                      Provided interactive Payment plans &amp; schedules to
                      patients to pay their bills in different schedules.{" "}
                    </li>
                    <li>Provided strong security for payment.</li>
                    <li>
                      Provided Mobile friendly website to do payment from mobile
                      also.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img
                      className="images desk-images"
                      src={diagram28}
                      alt=""
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ..........................................modal end...................................... */}
          {/* ..........................................modal start...................................... */}
          <div
            className="modal fade"
            id="useCase-3"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 3</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Customer is one of the USA’s leading providers of data
                    processing, printing, and mailing of information-sensitive
                    documents. From billing and tax statements to
                    HIPAA-regulated letters and forms, it rigorously maintains
                    the security of personal information.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Existing application was developed in a legacy tech stack.
                    </li>
                    <li>
                      Application was hosted in-house and document management
                      also handled internally.
                    </li>
                    <li>
                      Huge amount of documents (More than 5 TB) were stored
                      which was costing more of the infrastructure amount.
                    </li>
                    <li>Ownership and in-efficient collaboration</li>
                    <li>Fetching a specific document was time consuming.</li>
                    <li>
                      Overall document management was one of the biggest
                      problems for Customer.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content">
                    <li>
                      By consuming THOR APIs Customer develops their whole
                      application in minimal time since all APIs are in place.
                    </li>
                    <li>
                      Since THOR APIs are developed in the latest technologies
                      which are open source tech, hence the new application
                      development cost was marginally lower than older systems.
                    </li>
                    <li>
                      They revamped the application by using numerous THOR
                      Services.
                    </li>
                    <li>
                      THOR Services were successfully consumed from the .Net
                      based application.
                    </li>
                    <li>Document service was used extensively.</li>
                    <li>
                      5TB documents was migrated as part of this effort within 2
                      to 3 weeks timespan with multiple threads ran parallelly.
                    </li>
                    <li>
                      Newly developed applications and services are hosted on
                      AWS which reduced cost.
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ..........................................modal end...................................... */}
          {/* ..........................................modal start...................................... */}
          <div
            className="modal fade"
            id="useCase-4"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 4</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    India’s leading Professional education platform for MF
                    industry. Founded by a group of professionals who have more
                    than 100-man years of industry experience and a knack for
                    understanding industry’s skilling requirements. Driven by
                    the purpose of creating over 100,000 skilled Banking,
                    Financial Services, Insurance (BFSI) &amp; Real Estate
                    professionals and new to industry financial entrepreneurs
                    over the next 5 years. They bring to the table, an
                    inside-out practioners’ perspective, proven execution
                    capability &amp; relentless zeal to support all COGS of
                    better employability &amp; employment ecosystem – Job
                    seekers, Corporates and Educational institutions. Customer
                    Team is working very closely with experts of BFSI industry
                    and educational institutions to bridge the huge gap between
                    Academia and Corporates.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      The Mutual Fund Investor base is growing in India.
                      However, there are not as many Mutual Fund Advisors who
                      can capture the growing investors.
                    </li>
                    <li>
                      There is a need to generate more and more licensed Mutual
                      Fund Distributors to support the growing investor base.
                    </li>
                    <li>
                      Multiple courses and levels in learning path, configurable
                      learning path with optional, mandatory levels, cost/no
                      cost for some products.
                    </li>
                    <li>
                      Custom workflows and journey stages as per process
                      (ARN/EUIN/CPE).
                    </li>
                    <li>
                      Since it’s an e-learning platform, performance is expected
                      as on the higher side.
                    </li>
                  </ul>
                  <div className="gradient-title heading-24 mb-10">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Web Application: Distributor / Ops / AMC / Super Admin |
                      Hosting on AWS | Micro services | CICD Implementation.
                    </li>
                    <li>
                      Enrollment and registration of potential distributors.
                    </li>
                    <li>Capturing registered users’ profile.</li>
                    <li>
                      Learning Path configuration based on chosen journey –
                      ARN/EUIN/CPE.
                    </li>
                    <li>
                      Built-in Pre Assessment engine to gauge the potential
                      distributors factual and behavioral attributes, scoring
                      mechanism.
                    </li>
                    <li>
                      Integration with third party LMS for preparatory training
                      for NISM Certification.
                    </li>
                    <li>Payment gateway integration.</li>
                    <li>Workflow orchestration for multiple processes.</li>
                    <li>
                      Built-in features to cater operational requirements for
                      assisting the potential distributors in scheduling the
                      NISM Certification, KYD process, applying for ARN, EUIN,
                      Dashboards, Reports, Learning progress review, Distributor
                      Journey Views, Audit Trail, Product configuration,
                      Assessment engine configuration, user management.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img
                      className="images desk-images"
                      src={diagram24}
                      alt=""
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ..........................................modal end...................................... */}
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(ThorPlatform);
