import React from "react";
import ReactMarkdown from "react-markdown";
import { stripHtml } from "string-strip-html";

const CleanText = ({ html }) => {
  // Remove HTML tags using string-strip-html
  const cleanedText = stripHtml(html).result;

  // Render the cleaned text using ReactMarkdown
  return <ReactMarkdown>{cleanedText}</ReactMarkdown>;
};

export default CleanText;
