import Axios from "axios";

export default class RestDataSource {
  
  constructor(base_url, errorCallback) {
    // Token Configuration
    //
    // let BUID = localStorage.getItem("BUbuId");
    Axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("jwt-token") === null
        ? localStorage.getItem("jwt-token")
        : localStorage.getItem("jwt-token");

    // Userid and userLogin
    Axios.defaults.headers.common["x-api-key"] =
    "v4JShxB2iC8g5PR9G9Kp11HQx9R4r2XE89wvheGI";
    Axios.defaults.headers.common["Content-Type"] = "application/json";
    
    // ===============================================================================================
    localStorage.setItem(
      "headers",
      JSON.stringify(Axios.defaults.headers.common)
    );
    // ===============================================================================================

    this.BASE_URL = base_url;
    this.handleError = errorCallback;
  }

  async GetData(callback) {
    this.SendRequest("get", this.BASE_URL, callback);
  }
  async GetOneByParam(id, callback) {
    this.SendRequest("get", `${this.BASE_URL}?${id}`, callback);
  }
  async GetOne(data, callback) {
    this.SendRequest("get", this.BASE_URL, callback, data);
  }
  //  async GetOne(id, callback) {
  //   this.SendRequest("get", `${this.BASE_URL}/${id}`, callback);
  // }
  async Store(data, callback) {
    this.SendRequest("post", this.BASE_URL, callback, data);
  }
  async Update(data, callback) {
    this.SendRequest("put", this.BASE_URL, callback, data);
  }
  async Delete(data, callback) {
    this.SendRequest("delete", this.BASE_URL, callback, data);
  }

  async SendRequest(method, url, callback, data) {
    var k = "";
    try {
      let response = await Axios.request({
        //headers: axiosheaders.Headers(),
        method: method,
        url: url,
        data: data,
      });
      callback(response);
    } catch (err) {
      if (err && err.response && err.response.data) {
        if (err.response.data) k = err.response.data.reasonText;
        if (err.response.data.reasonCode === 400)
           k = err.response.data.reasonText;
        else  k = "Error encountered, Please try again";

        var errbx = document.getElementById("processing-status");
        if (errbx != null)
          document.getElementById("processing-status").innerHTML =
            k + ", Please try again.";
        callback(err.response);
      } // localStorage.setItem("api400",k);
      // this.handleError(err);
    }
  }
}