import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import crmImg from "../../assets/img/crm-img.png";
import diagram41 from "../../assets/img/digram-41.png";
import diagram42 from "../../assets/img/digram-42.png";
import crmImg1 from "../../assets/img/crm-img-1.png";
import crmImg2 from "../../assets/img/crm-img-2.png";
import crmImg3 from "../../assets/img/crm-img-3.png";
import crmImg4 from "../../assets/img/crm-img-4.png";
import crmImg5 from "../../assets/img/crm-img-5.png";
import crmImg6 from "../../assets/img/crm-img-6.png";
import crmImg7 from "../../assets/img/crm-img-7.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const CustomerRelationshipManagement = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  //Slider
  const [index, setIndex] = useState(0);
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const changeSlide = () => {
    if (index > slides.length - 1) setIndex(0);

    if (index < 0) setIndex(slides.length - 1);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  // Call the changeSlide function whenever the index changes
  React.useEffect(() => {
    changeSlide();
    // eslint-disable-next-line
  }, [index]);
  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-section-crm text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Customer Relationship Management (CRM)
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="solutions.html">Solutions</a>{" "} */}
                <Link to="/solutions" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="color-45B0DC">
                  {" "}
                  / Customer Relationship Management (CRM)
                </span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center solution-img-tp">
                <img className="images " src={crmImg} alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="gradient-title mb-15">Why CRM is Needed?</div>
                <p>
                  Customer Relationship Management or CRM is a process of
                  administering and managing Customer engagements and
                  Interactions within any organization. Typically, this helps in
                  pre-sales, sales, marketing, support and management. It helps
                  in maximizing the Lead potential and track the Lead funnel to
                  conversion. It involves
                </p>
                <ul className="list-content">
                  <li>Lead, Opportunity, Contact, Account management</li>
                  <li>Aggregation of information to provide KPIs</li>
                  <li>
                    Tracking and measuring marketing campaigns through analytics
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="gradient-title mb-15">CRM Solution</div>
                  <p>
                    THOR-CRM is a solution built on the THOR platform which
                    provides CRM features to any platform through easy
                    integration. This is a full stack solution (Frontend in
                    React JS and Backend in Python) that can be hosted on AWS.
                  </p>
                  <div className="heading-20 mb-20">
                    Its Primary Features include:
                  </div>
                  <ul className="list-content-6">
                    <li>Lead Management</li>
                    <li>Contact Management</li>
                    <li>Account Management</li>
                    <li>Opportunity Management</li>
                    <li>Product Management</li>
                    <li>Price Book</li>
                    <li>Assignment Rules</li>
                  </ul>
                </div>
                <div className="col-md-6 text-center">
                  <img
                    id="myImg"
                    src={diagram41}
                    onClick={() => {
                      openModal(diagram41);
                    }}
                    className="img-shadow width-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 text-center">
                <img
                  id="myImg"
                  src={diagram42}
                  onClick={() => {
                    openModal(diagram42);
                  }}
                  className="img-shadow width-100"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="gradient-title mb-15">CRM Implementation</div>
                <p>
                  Generally, a given CRM User can manage Leads. Leads can be
                  sourced from various channels and uploaded into the system.
                  This process can be automated via API, Email and FTP
                  integration. Lead assignment rules can be configured to bring
                  in automation in the process of lead management. There could
                  be multiple lead assignment rules associated with a given lead
                  type. Leads are contacted, enriched and nurtured and qualified
                  for action by the sales team. At this point, the lead can
                  become an opportunity. An opportunity can essentially be
                  linked to one or more products; it can also have one or more
                  contacts. Quotes can be generated on an Opportunity. Once an
                  opportunity is converted into an actual sales, it becomes an
                  Account. New opportunities can be created on existing
                  Accounts. Contact is an independent entity that can be linked
                  to Lead, Opportunity and Account and is carried over in the
                  Lead and Opportunity journeys.
                </p>
              </div>
            </div>
          </div>
          <div className="gray-bg-section-img-slider custom-slider">
            <div className="main-section">
              {/* <div class="col-md-12">
          <div class="gradient-title text-center">How CRM Works?</div>
          <div id="mainBanner" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators carouselIndicators">
                  <li data-target="#mainBanner" data-slide-to="0" class="active"></li>
                  <li data-target="#mainBanner" data-slide-to="1"></li>
                  <li data-target="#mainBanner" data-slide-to="2"></li>
                  <li data-target="#mainBanner" data-slide-to="3"></li>
                  <li data-target="#mainBanner" data-slide-to="4"></li>
                  <li data-target="#mainBanner" data-slide-to="5"></li>
                  <li data-target="#mainBanner" data-slide-to="6"></li>


              </ol>
              <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img class="d-block w-100 w100" src="img/crm-img-1.png" alt="First slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-2.png" alt="Second slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-3.png" alt="Third slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-4.png" alt="Third slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-5.png" alt="Third slide">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-6.png" alt="Third slide">

                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/crm-img-7.png" alt="Third slide">

                  </div>
              </div>
              <a class="carousel-control-prev" href="#mainBanner" role="button" data-slide="prev">
                  <i class="fa-solid fa-angle-left banner-btn"></i>
                  <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#mainBanner" role="button" data-slide="next">
                  <i class="fa-solid fa-angle-right banner-btn"></i>
                  <span class="sr-only">Next</span>
              </a>
          </div>
      </div> */}
              <div className="gradient-title text-center">How CRM Works?</div>
              <div id="slider">
                <div className="slide" style={{ display: "block" }}>
                  <img src={crmImg1} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg2} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg3} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg4} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg5} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg6} alt="" />
                </div>
                <div className="slide">
                  <img src={crmImg7} alt="" />
                </div>
                {/*Controlling arrows*/}
                <span
                  className="controls"
                  id="left-arrow"
                  onClick={() => prevSlide(-1)}
                >
                  {" "}
                  <i className="fa-solid fa-angle-left" />
                </span>
                <span
                  className="controls"
                  id="right-arrow"
                  onClick={() => nextSlide(1)}
                >
                  <i className="fa-solid fa-angle-right" />
                </span>
              </div>
              <div id="dots-con">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="gradient-title mb-15 text-center">
                  Benefits of THOR-CRM
                </div>
                <p className="text-center heading-20 mb-15">
                  THOR-CRM is beneficial due to following main characteristics:
                </p>
                <ul className="list-content-6">
                  <li>
                    The architecture follows the same principals as the THOR
                    platform and is scalable and secure
                  </li>
                  <li>It is built using open source tools and software</li>
                  <li>
                    It is a full stack cloud based solution built on top of the
                    THOR platform
                  </li>
                  <li>It is very easy to integrate with any platform</li>
                  <li>
                    It provides the core features of a CRM and is well suited
                    for SaaS, B2B and B2C platforms based business models
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}
          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt=""
              />
              <div id="caption" />
            </div>
          </div>
          {/* ..................................................modal end...................................................... */}
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(CustomerRelationshipManagement);
