import React, { memo } from "react";
import WrapComponent from "../Common/WrapComponent";

import requirmentLabourLaw from "../../assets/img/requirement-labour-law-img.png";
import diagram22 from "../../assets/img/digram-22.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const LabourAndOffice = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-6 text-center">
          <div className="banner-text">
            <div className="banner-gradient-title mb-15">
              Labor, Office Factory <br />
              Law Compliance
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="color-45B0DC">
                / Labor, Office &amp; Factory Law Compliance
              </span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="requirment"
                src={requirmentLabourLaw}
              />
            </div>
            <div className="col-md-6">
              <p>
                As a corporate business operating in India, it is important to
                ensure compliance with the country's labour laws and statutory
                requirements at the local, state, and central levels. At EPI, we
                understand the unique challenges and opportunities of labour
                laws and statutory compliance in India, and we have a range of
                solutions to help you meet your obligations.
              </p>
              <ul className="list-content">
                <li>Automate Labour Law Compliance.</li>
                <li>Compliance document management.</li>
                <li>Minimum Wages calculation.</li>
                <li>Manage Working hours.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-bg-section-3">
          <div className="main-section">
            <div className="custom-text-center mb-30">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 1</div>
                  <div className="card-content-4">
                    About the Company: Customer offers unique value propositions
                    including customized solutions to its clients using a
                    combination of subject matter expertise, lean processes, and
                    disruptive proprietary technology for cost-effective
                    engagements in the areas of Labour Law compliance and HR
                    solutions.
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Customer offers unique value propositions including
                    customized solutions to its clients using a combination of
                    subject matter expertise, lean processes, and disruptive
                    proprietary technology for cost-effective engagements in the
                    areas of Labour Law compliance and HR solutions.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <div className="heading-20">
                    Some key processes and regulations to consider in India
                    include:
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      <span className="bullet-title">Minimum Wages:</span>
                      Ensuring that all employees are paid at least the minimum
                      wage as set by the government.
                    </li>
                    <li>
                      <span className="bullet-title">Working Hours:</span>
                      Complying with regulations on working hours, including
                      limits on the number of hours that can be worked per day
                      and per week.
                    </li>
                    <li>
                      <span className="bullet-title">Leave and Holidays:</span>
                      Providing employees with paid leave and holidays as
                      required by law.
                    </li>
                    <li>
                      <span className="bullet-title">Health and Safety:</span>
                      Ensuring that the workplace is safe and healthy for
                      employees, including providing necessary protective
                      equipment and training.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Statutory Compliance:
                      </span>
                      Meeting all relevant statutory requirements, including
                      those related to taxes, permits, and licenses.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <div className="heading-20">
                    To address these challenges and more, our solutions include:
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      <span className="bullet-title">
                        Minimum Wage Compliance Software:
                      </span>
                      Our software helps you track and ensure compliance with
                      minimum wage requirements.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Time and Attendance Tracking:
                      </span>
                      Our time and attendance tracking software help you monitor
                      and record working hours, ensuring compliance with
                      regulations.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Leave and Holiday Management Software:
                      </span>
                      Our leave and holiday management software helps you track
                      and manage employee leave and holidays, ensuring
                      compliance with regulations.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Health and Safety Training:
                      </span>
                      Our health and safety training programs help you educate
                      employees about the importance of workplace safety and
                      provide them with the knowledge and skills they need to
                      stay safe on the job.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Statutory Compliance Software:
                      </span>
                      Our statutory compliance software helps you track and meet
                      all relevant statutory requirements, including those
                      related to taxes, permits, and licenses.
                    </li>
                  </ul>
                  <p>
                    Contact us today to learn more about how we can help your
                    organization navigate the challenges and opportunities of
                    labour laws and statutory compliance in India.
                  </p>
                  <div className="text-center ">
                    <img
                      className="images desk-images"
                      alt="diagram22"
                      src={diagram22}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(LabourAndOffice);
