import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import axios from "axios";
import * as Yup from "yup";

import Global_var from "../../Global/global_var";
import { loginService } from "../../Services/loginService";
import WrapComponent from "./WrapComponent";

const ApplyToPosition = (props) => {
  const nameRegex = /^[A-Za-z.'\s]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneRegex = /^(?:\+91-?)?[0-9]{10}$/;
  // const fileRegex =  /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|pps|ppsx|jpeg|jpg|png)$/i;
  const fileRegex = /\.(pdf|doc|docx)$/i;

  // const phoneRegex = /^\+91-\d{10}$/
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Full Name is required")
      .matches(
        nameRegex,
        "Fullname contains only letters, spaces, periods (.), and apostrophes."
      ),
    email_id: Yup.string()
      .email("Invalid email address")
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(phoneRegex, "Invalid phone"),
    message: Yup.string().required("Message is required"),
    file: Yup.string().matches(fileRegex, "Invalid file type"),
  });

  const initialValues = {
    name: "",
    email_id: "",
    phone: "",
    message: "",
    file: "",
    position: "",
    job_id: "",
    ip: "",
  };

  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [ipAddress, setIPAddress] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const [base64File, setBase64File] = useState("");
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [fileSubmit, setFileSubmit] = useState(true);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (!fileRegex.test(fileName)) {
        // Invalid file type
        console.log("Invalid file type");
        setBase64File(""); // Clear the base64 file data
        setErrorMessage(
          "Invalid file type. Please select a valid file type (pdf, doc, docx, xls, xlsx, ppt, pptx, pps, ppsx)."
        );
        setFileSubmit(false);
      } else {
        convertFileToBase64(selectedFile);
        setErrorMessage(""); // Clear any previous error message
        setFileSubmit(true);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setBase64File(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5", {
          action: "homepage",
        })
        .then((token) => {
          // ...
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5";
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  // const [captchaValue, setCaptchaValue] = useState(null);

  const gre = (e) => {
    // console.log(e)
    // e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5", {
          action: "submit",
        })
        .then(function (token) {
          console.log(token);

          const secretKey = Global_var.G_CAPTCHA_SCE_KEY;
          const userIp = ipAddress;
          // const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}&remoteip=${userIp}`;

          // console.log("URL", url)
          // axios.post("http://localhost:3311/verify",).then(res=>{
          //   console.log(res)
          // })

          axios
            .post(
              Global_var.EPI_API_BASE.PROD + Global_var.CAPTCHA_VERIFY_URL,
              {
                secret: secretKey,
                response: token,
                remoteip: userIp,
                headers: {
                  "Cache-Control": "no-cache",
                  "Content-Type": "application/x-www-form-urlencoded",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((res) => {
              console.log(res);
              console.log(res.data);
              if (res.data.success) {
                // alert("Success")
                // setCaptchaValue("Done");
                // console.log(selectedJob);
                // e.job_id = selectedJob.ID;
                // e.job_title = selectedJob.job_title;
                // e.post_title = selectedJob.post_title;
                // e.description = selectedJob.description;

                console.log(props);
                e.job_id = props.job_id;
                e.job_title = props.job_title;
                e.post_title = props.post_title;
                e.description = props.description;

                const { name, email_id, phone, message } = e;
                //debugger;
                console.log(props.job_id);
                loginService.applyJob(
                  {
                    name,
                    email_id,
                    phone,
                    message,
                    file: base64File,
                    ip: ipAddress,
                    job_id: props.job_id,
                    position: props.job_title,
                  },
                  (res) => {
                    if (res.data.status === "Success") {
                      setApiLoading(false);
                      toast.success(res.data.reasonText, {
                        toastId: "success1",
                      });
                      setTimeout(() => {
                        window.location.reload();
                      }, 5000); // Adjust the time
                    } else {
                      toast.error(res.data.reasonText);
                    }
                  }
                );
              } else {
                toast.error("Failed to verify captcha please reload the page", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                alert("Failed to verify captcha please reload the page");
                window.location.reload();
                return;
              }
            });
          // Add your logic to submit to your backend server here.
          // if (!captchaValue) {
          //   toast.error("Failed to varify captcha please reload the page", { position: toast.POSITION.BOTTOM_RIGHT });
          //   // <ToastContainer />
          //   return;
          // }
        });
    });
  };

  const onSubmit = (values) => {
    //debugger;
    //console.log("values ==" + values);
    //alert(values);
    // alert("clicked")
    setApiLoading(true);
    let token = gre(values);
    console.log("Your token is", token);
    return;
  };

  return (
    // <div
    //   className="modal fade"
    //   id={props.id}
    //   jobId={props.jobId}
    //   tabIndex={-1}
    //   role="dialog"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <div className="form-section">
        <div className="heading-20 mb-20">Apply for this position</div>
        {/* <div>Props jod id: {props.job_id}</div> */}
        <Form>
          <div className="form-bg">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="required">
                Full Name
              </label>
              <Field
                type="text"
                className="form-control"
                id="name"
                name="name"
                aria-describedby="emailHelp"
                placeholder="Full Name"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" className="required">
                Email
              </label>
              <Field
                type="text"
                className="form-control"
                id="email_id"
                name="email_id"
                placeholder="Email"
              />
              <ErrorMessage
                name="email_id"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" className="required">
                Mobile
              </label>
              <Field
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                placeholder="Mobile"
              />
              <div className="file-extension">
                Allowed Format: +91-0101010101, +910101010101 ,0101010101
              </div>
              <ErrorMessage
                name="phone"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" className="required">
                Message
              </label>
              <Field
                as="textarea"
                className="form-control"
                id="message"
                name="message"
                rows={3}
                defaultValue={""}
              />
              <ErrorMessage
                name="message"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Upload CV/Resume</label>
              <div className="borderbox">
                <input
                  type="file"
                  id="real-file"
                  name="file"
                  onChange={handleFileChange}
                />
              </div>
              <div className="file-extension">Allowed Type: pdf, doc, docx</div>
              {/* <div
                                    id="file-error"
                                    className="text-danger"
                                  /> */}
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </div>
          </div>
          <div className="text-right mt-25">
            {apiLoading ? (
              <div
                style={{
                  justifyContent: "right",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "auto",
                }}
              >
                <InfinitySpin width="250" color="#aec10b" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary custom-button"
                disabled={fileSubmit === false}
              >
                Submit
              </button>
            )}
          </div>
        </Form>
      </div>
    </Formik>

    //</div>
  );
};

export default ApplyToPosition;
