import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import gudiPadwaImage1 from "../../assets/img/gudi-padwa-1.jpg";
import gudiPadwaImage2 from "../../assets/img/gudi-padwa-2.jpg";
import gudiPadwaImage3 from "../../assets/img/gudi-padwa-3.jpg";
import gudiPadwaImage4 from "../../assets/img/gudi-padwa-4.jpg";
import gudiPadwaImage5 from "../../assets/img/gudi-padwa-5.jpg";

import pieEyeImage3 from "../../assets/img/pie-eye-img-3.png";
import pieEyeImage4 from "../../assets/img/pie-eye-img-4.png";
import pieEyeImage5 from "../../assets/img/pie-eye-img-5.png";
import pieEyeImage2 from "../../assets/img/pie-eye-img-2.png";

import annualDayImage1 from "../../assets/img/annual-day-img-1.png";
import annualDayImage2 from "../../assets/img/annual-day-img-2.png";
import annualDayImage3 from "../../assets/img/annual-day-img-3.png";
import annualDayImage4 from "../../assets/img/annual-day-img-4.png";
import annualDayImage5 from "../../assets/img/annual-day-img-5.png";
import annualDayImage6 from "../../assets/img/annual-day-img-6.png";
import annualDayImage7 from "../../assets/img/annual-day-img-7.png";
import annualDayImage8 from "../../assets/img/annual-day-img-8.png";
import annualDayImage9 from "../../assets/img/annual-day-img-9.png";
import annualDayImage10 from "../../assets/img/annual-day-img-10.png";
import annualDayImage11 from "../../assets/img/annual-day-img-11.png";
import annualDayImage12 from "../../assets/img/annual-day-img-12.png";

import themDayImage1 from "../../assets/img/theme-day-1.png";
import themDayImage2 from "../../assets/img/theme-day-2.png";
import themDayImage3 from "../../assets/img/theme-day-3.png";
import themDayImage4 from "../../assets/img/theme-day-4.png";
import themDayImage5 from "../../assets/img/theme-day-5.png";
import themDayImage6 from "../../assets/img/theme-day-6.png";
import themDayImage7 from "../../assets/img/theme-day-7.png";
import themDayImage8 from "../../assets/img/theme-day-8.png";
import themDayImage9 from "../../assets/img/theme-day-9.png";
import themDayImage10 from "../../assets/img/theme-day-10.png";
import themDayImage11 from "../../assets/img/theme-day-11.png";
import themDayImage12 from "../../assets/img/theme-day-12.png";
import themDayImage13 from "../../assets/img/theme-day-13.png";
import themDayImage14 from "../../assets/img/theme-day-14.png";
import themDayImage15 from "../../assets/img/theme-day-15.png";
import themDayImage16 from "../../assets/img/theme-day-16.png";

import independentDayImage5 from "../../assets/img/independans-day-5.png";
import independentDayImage4 from "../../assets/img/independans-day-4.png";
import independentDayImage3 from "../../assets/img/independans-day-3.png";
import independentDayImage2 from "../../assets/img/independans-day-2.png";
import independentDayImage1 from "../../assets/img/independans-day-1.png";
import independentDayImage6 from "../../assets/img/independans-day-6.png";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const eventObj = [
  {
    name: "Gudi Padwa 2023 Ethnic Wear Day",
    data: [
      { imgValue: gudiPadwaImage1 },
      { imgValue: gudiPadwaImage2 },
      { imgValue: gudiPadwaImage3 },
      { imgValue: gudiPadwaImage4 },
      { imgValue: gudiPadwaImage5 },
    ],
  },
  {
    name: "PieEye Offshore Meeting",
    data: [
      { imgValue: pieEyeImage3 },
      { imgValue: pieEyeImage4 },
      { imgValue: pieEyeImage5 },
      { imgValue: pieEyeImage2 },
    ],
  },
  {
    name: "Annual Day Celebration",
    data: [
      { imgValue: annualDayImage1 },
      { imgValue: annualDayImage2 },
      { imgValue: annualDayImage3 },
      { imgValue: annualDayImage4 },
      { imgValue: annualDayImage5 },
      { imgValue: annualDayImage6 },
      { imgValue: annualDayImage7 },
      { imgValue: annualDayImage8 },
      { imgValue: annualDayImage9 },
      { imgValue: annualDayImage10 },
      { imgValue: annualDayImage11 },
      { imgValue: annualDayImage12 },
    ],
  },
  {
    name: "Theme Day Event",
    data: [
      { imgValue: themDayImage1 },
      { imgValue: themDayImage2 },
      { imgValue: themDayImage3 },
      { imgValue: themDayImage4 },
      { imgValue: themDayImage5 },
      { imgValue: themDayImage6 },
      { imgValue: themDayImage7 },
      { imgValue: themDayImage8 },
      { imgValue: themDayImage9 },
      { imgValue: themDayImage10 },
      { imgValue: themDayImage11 },
      { imgValue: themDayImage12 },
      { imgValue: themDayImage13 },
      { imgValue: themDayImage14 },
      { imgValue: themDayImage15 },
      { imgValue: themDayImage16 },
    ],
  },
  {
    name: "Independence Day Event",
    data: [
      { imgValue: independentDayImage5 },
      { imgValue: independentDayImage4 },
      { imgValue: independentDayImage3 },
      { imgValue: independentDayImage2 },
      { imgValue: independentDayImage1 },
      { imgValue: independentDayImage6 },
    ],
  },
];
const OurCelebrations = () => {
  const [eventIndex, setEventIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);

  const handleNext = () => {
    if (imageIndex === eventObj[eventIndex]?.data?.length - 1) {
      setEventIndex((prevIndex) => (prevIndex + 1) % eventObj?.length);
      setImageIndex(0);
    } else {
      setImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    // if (imageIndex === 0) {
    //   setEventIndex((prevIndex) =>
    //     prevIndex === 0 ? eventObj?.length - 1 : prevIndex - 1
    //   );
    //   setImageIndex(eventObj[eventIndex]?.data?.length - 1);
    // } 
    if (imageIndex === 0) {
      // Navigate to the previous event and set the image index to the last image of that event
      setEventIndex((prevIndex) => (prevIndex === 0 ? eventObj.length - 1 : prevIndex - 1));
      setImageIndex(eventObj[eventIndex === 0 ? eventObj.length - 1 : eventIndex - 1].data.length - 1);
    }else {
      setImageIndex((prevIndex) => prevIndex - 1);
    }
  };
  return (
    <>
      <WrapComponent>
        <ScrollToTopButton />
        <div>
          <div className="banner-section-4 text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Our Celebrations
              </div>
              <div className="breadcrumb-links ">
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-45B0DC"> / Our Celebrations</span>
              </div>
            </div>
          </div>
          <div className="main-section">
            {(eventObj || []).map((event, objIndex) => {
              const { name, data } = event;
              return (
                <React.Fragment key={objIndex}>
                  <div className="event-gradient-title">{name}</div>
                  <div className="row">
                    {(data || []).map((image, imgIndex) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-xs-6 thumb grow"
                          key={imgIndex}
                        >
                          <Link
                            className="thumbnail"
                            href="#"
                            data-image-id
                            data-toggle="modal"
                            data-title={name}
                            data-image={image.imgValue}
                            data-target="#image-gallery"
                            onClick={() => {
                              setEventIndex(objIndex);
                              setImageIndex(imgIndex);
                            }}
                          >
                            <img
                              className="img-thumbnail"
                              src={image.imgValue}
                              alt="Another alt text"
                            />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="container">
            <div className="row">
              <div
                className="modal fade"
                id="image-gallery"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg eventModaLg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4
                        className="modal-title gradient-title-popup"
                      >
                        {eventObj[eventIndex]?.name}
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <img
                        className="img-responsive col-md-12"
                        src={eventObj[eventIndex]?.data[imageIndex]?.imgValue}
                        alt={`Event ${eventIndex + 1} ${imageIndex + 1}`}
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary float-left"
                        //id="show-previous-image"
                        onClick={handlePrevious}
                      >
                        <i className="fa fa-arrow-left" />
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        className="btn btn-secondary float-right"
                      >
                        <i className="fa fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(OurCelebrations);