// import React from "react";
import React, { useEffect, useState } from "react";
import WrapComponent from "./Common/WrapComponent";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import { loginService } from "../Services/loginService";
// import { ToastContainer, toast } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ScrollToTopButton from "./Common/ScrollToTop";
import axios from "axios";
import Global_var from "../Global/global_var";
import { InfinitySpin } from  'react-loader-spinner'
//recaptcha


const ContactUs = () => {
  // const navigate = useNavigate();
  const nameRegex = /^[A-Za-z.'\s]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const phoneRegex = /^(?:\+91-?)?[0-9]{10}$/
  // const fileRegex =  /\.(pdf|doc|docx)$/i;
  const fileRegex =  /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|pps|ppsx|jpeg|jpg|png)$/i;
  // const phoneRegex = /^\+91-\d{10}$/
  const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required").matches(nameRegex, "Fullname contains only letters, spaces, periods (.), and apostrophes."),
    email_id: Yup.string()
      .email("Invalid email address").matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required").matches(phoneRegex, "Invalid phone"),
    message: Yup.string().required("Message is required"),
    // file: Yup.string().required("Message is required"),  
    file: Yup.string().matches(fileRegex,"Invalid file type"),  
  });


  const initialValues = {
    name: "",
    email_id: "",
    phone: "",
    message: "",
    file: "",
    ip: "",

  };


  const [ipAddress, setIPAddress] = useState('')
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);


  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5", { action: "homepage" })
        .then(token => {
          // ...
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5"
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, []);



  const [base64File, setBase64File] = useState("");

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     convertFileToBase64(selectedFile);
  //   }
  // };

  const [errorMessage, setErrorMessage] = useState("");
  const [fileSubmit, setFileSubmit] = useState(true);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (!fileRegex.test(fileName)) {
        // Invalid file type
        console.log("Invalid file type");
        setBase64File(""); // Clear the base64 file data
        setErrorMessage("Invalid file type. Please select a valid file type (pdf, doc, docx, xls, xlsx, ppt, pptx, pps, ppsx, jpg, jpeg, png).");
        setFileSubmit(false);
      } else {
        convertFileToBase64(selectedFile);
        setErrorMessage(""); // Clear any previous error message
        setFileSubmit(true);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setBase64File(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const [captchaValue, setCaptchaValue] = useState(null);

  const gre = (e) => {
    // e.preventDefault();
    // debugger;
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5', { action: 'submit' }).then(function (token) {
        console.log(token);

        const secretKey = Global_var.G_CAPTCHA_SCE_KEY;
        const userIp = ipAddress;
        // const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}&remoteip=${userIp}`;
        // console.log("URL", url)
        axios.post(Global_var.EPI_API_BASE.PROD + Global_var.CAPTCHA_VERIFY_URL, {
          'secret': secretKey,
          'response': token,
          'remoteip': userIp,
          headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*"
          }
        }).then((res)=>{
          console.log(res);
          console.log(res.data);
          if(res.data.success){
            // alert("Success")
            setCaptchaValue("Done")
            const { name, email_id, phone, message } = e;
        console.log(e);
        // debugger;
        console.log(name)
        loginService.submitQuery({
          name, email_id, phone, message, file: base64File, ip: ipAddress,
        },
          (res) => {
            // debugger;
            console.log(captchaValue)
            console.log("response", res)
            if (res.data.status === "Success") {
              // debugger;
              setLoading(false);
              toast.success(res.data.reasonText)
              setTimeout(() => {
                window.location.reload();
              }, 5000); // Adjust the time
            } else {
              // debugger;
              toast.error(res.data.reasonText)
            }
          },

        )

          }
          else{
            toast.error("Failed to varify captcha please reload the page", {position: toast.POSITION.BOTTOM_RIGHT})
            alert("Failed to varify captcha please reload the page")
            window.location.reload();
            return
          }
        })
        // Add your logic to submit to your backend server here.
        // if (!captchaValue) {
        //   toast.error("Failed to varify captcha please reload the page", {position: toast.POSITION.BOTTOM_RIGHT});
        //   // <ToastContainer />
        //   return;
        // }
        // toast.info("Doneee", {position: toast.POSITION.BOTTOM_RIGHT})
          });
        });
  }

  const onSubmitFinal = (values, action) => {
    // debugger;
    // console.log(values);
    setLoading(true);
    let token = gre(values)
    console.log("Your token is", token)
    return
    // debugger;
    // const { name, email_id, phone, message } = values;
    // console.log(values);

    // console.log(name)
    // loginService.submitQuery({
    //   name, email_id, phone, message, file: base64File, ip: ipAddress,
    // },
    //   (res) => {
    //     debugger;
    //     console.log("response", res)
    //     if (res.data.status === "Success") {
    //       debugger;
    //       toast.success(res.data.reasonText)
    //       // window.location.reload();
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 5000); // Adjust the time
    //     } else {
    //       debugger;
    //       toast.error(res.data.reasonText)
    //     }
    //   },

    // )
  };


  // function onChange(value) {
  //   console.log("Captcha value:", value);

  // }

  return (
    <WrapComponent>
      <ScrollToTopButton />
      {/* <ToastContainer style={{ width: "37%" }} /> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitFinal}
      >
        <div>
          <div className="banner-section-5 text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">Contact Us</div>
              <div className="breadcrumb-links">
                {/* <a
                  href=""
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => { }}>
                  Home
                </Link>{" "}
                <span className="contact-breadcrumb-links color-45B0DC">
                  {" "}
                  / CONTACT Us
                </span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="gradient-title mb-15">Hello!</div>
                <div className="paragraph-content-2 mb-30 textJustify">
                  Call, email or fill up the form on this page – whatever works
                  for you. We’ll respond promptly!
                </div>
                <div className="heading-orange-24 mb-25">
                  Extrapreneurs India Private Limited
                </div>
                <div className="address-box">
                  <div className="heading-20">Head Office</div>
                  <ul className="list-content-3">
                    <li className="initial">
                      301, Bhakti Genesis, Shankar Kalat Nagar, Wakad,
                      Pimpri-Chinchwad, Maharashtra 411057, India.
                    </li>
                  </ul>
                  {/* <ul className="list-content-4">
                    <li>(+91) 99754 98229</li>
                  </ul> */}
                  <ul className="list-content-5">
                    <li>corporate@extrapreneursindia.com</li>
                  </ul>
                </div>
                <div className="address-box mt-20">
                  <div className="heading-20">US Sales Office</div>
                  <ul className="list-content-3">
                    <li>
                      Thinqloud Solutions Americas Inc, 17 Aldrich Rd, Kendall
                      Park, NJ 08824
                    </li>
                  </ul>
                  <ul className="list-content-4">
                    <li>(+1) 612 454 5031</li>
                  </ul>
                  <ul className="list-content-5">
                    <li>corporate@extrapreneursindia.com</li>
                  </ul>
                </div>
                <div className="address-box mt-20">
                  <div className="heading-20">Dubai Sales Office</div>
                  <ul className="list-content-3">
                    <li className="initial">
                      Mihir Salla, Prime Link IT Solution, Office# 308, The
                      Binary By Omniyat, AL Abraaj St., Business Bay, Dubai -
                      UAE
                    </li>
                  </ul>
                  <ul className="list-content-4">
                    <li>+971 5 522 01435, +971 4 582 9777</li>
                  </ul>
                  <ul className="list-content-5">
                    <li>corporate@extrapreneursindia.com</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-section">
                  <div className="gradient-title mb-15">How Can we Help?</div>
                  <div className="paragraph-content-2 mb-30 textJustify">
                    Use this form to reach out to us! Whether you have a
                    question about our services, solutions, domain experience,
                    THOR platform features, access to sandbox for experiencing
                    the features, Pricing info &amp; a demo. Our team will
                    respond promptly!
                  </div>
                  {/* //----------------------------------------------------------------------------------------- */}
                  <Form className="form-bg">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1" className="required">
                        Full Name
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        aria-describedby="emailHelp"
                        placeholder="Full Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="required"
                      >
                        Email
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="email_id"
                        name="email_id"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email_id"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="required"
                      >
                        Phone
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                      />
                      <div className="file-extension">
                        Allowed Format: +91-0101010101, +910101010101 ,0101010101
                      </div> 
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="required"
                      >
                        Message
                      </label>
                      <Field
                        as="textarea"
                        className="form-control"
                        id="message"
                        name="message"
                        rows={3}
                        defaultValue={""}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Upload Attachment
                      </label>
                      <div className="borderbox">
                        <input type="file" id="file" name="file" onChange={handleFileChange} />
                        {/* <Field type="file" id="file" name="file" onChange={handleFileChange} /> */}
                      </div>
                      <div className="file-extension">
                      Allowed Type: pdf, doc, docx, xls, xlsx, ppt,pptx, pps, ppsx, jpg ,jpeg ,png
                      </div>
                      {/* <div id="file-error" className="text-danger hidden" /> */}
                      {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    </div>
                    {/* ----------------------------------------------------------------- */}

                    {/* ---------------------------------------------------------------- */}
                    <div className="text-right mt-25">
                      <div className="text-right mt-25" id="preloader" />
                      {loading ? <div style={{justifyContent: "right", alignItems:"center", marginLeft:"auto", marginRight:"auto", width: "auto"}} >
              <InfinitySpin width='250' color="#aec10b"/> 
            </div>:
                      <button
                        type="submit"
                        className="btn btn-primary custom-button"
                        disabled={fileSubmit === false}
                      >
                        Send message
                      </button>
}
                      {/* <input
                          type="hidden"
                          name="page"
                          defaultValue="contact"
                        /> */}
                    </div>
                  </Form>
                  <ToastContainer />

                  {/* //------------------------------------------------------------------------------------- */}
                </div>
              </div>
            </div>
          </div>
          <div className="map-section ">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Google Maps"
                  width="100%"
                  height={400}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=DHAKTI%20GENESTS,%20BHAKTI%20GENESTS,%20Wakad%20Rd,%20Shedge%20Vasti,%20Shankar%20Kalat%20Nagar,%20Wakad,%20Pimpri-Chinchwad,%20Maharashtra%20411057&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
                {/* <a href="https://123movies-to.org" /> */}
                <br />
              </div>
            </div>
          </div>
        </div>
      </Formik>
    </WrapComponent>
  );
};

export default ContactUs;
