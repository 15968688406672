import React from "react";
import WrapComponent from "../Common/WrapComponent";
import Home from "./blogHome";


const Blogs = () => {
  return <WrapComponent>
    
    {<Home />}
  </WrapComponent>;
};

export default Blogs;
