import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import authenticationImg from "../../assets/img/authentication-img.png";
import faceSlide1 from "../../assets/img/face-slide-img-1.png";
import faceSlide2 from "../../assets/img/face-slide-img-2.png";
import faceSlide3 from "../../assets/img/face-slide-img-3.png";
import faceSlide4 from "../../assets/img/face-slide-img-4.png";
import faceSlide5 from "../../assets/img/face-slide-img-5.png";
import faceSlide6 from "../../assets/img/face-slide-img-6.png";
import faceSlide7 from "../../assets/img/face-slide-img-7.png";
import faceSlide8 from "../../assets/img/face-slide-img-8.png";
import faceSlide9 from "../../assets/img/face-slide-img-9.png";
import faceSlide10 from "../../assets/img/face-slide-img-10.png";
import voiceSlide1 from "../../assets/img/voice-slide-img-1.png";
import voiceSlide2 from "../../assets/img/voice-slide-img-2.png";
import voiceSlide3 from "../../assets/img/voice-slide-img-3.png";
import voiceSlide4 from "../../assets/img/voice-slide-img-4.png";
import voiceSlide5 from "../../assets/img/voice-slide-img-5.png";
import voiceSlide6 from "../../assets/img/voice-slide-img-6.png";
import voiceSlide7 from "../../assets/img/voice-slide-img-7.png";
import voiceSlide8 from "../../assets/img/voice-slide-img-8.png";
import voiceSlide9 from "../../assets/img/voice-slide-img-9.png";
import voiceSlide10 from "../../assets/img/voice-slide-img-10.png";
import voiceSlide11 from "../../assets/img/voice-slide-img-11.png";
import voiceSlide12 from "../../assets/img/voice-slide-img-12.png";
import voiceSlide13 from "../../assets/img/voice-slide-img-13.png";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const MultifactorAuthentication = () => {
  // const navigate = useNavigate();
  //Custom slider----------------------------------------------------------------------------------------
  const [index, setIndex] = useState(0);
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const changeSlide = () => {
    if (index > slides.length - 1) setIndex(0);

    if (index < 0) setIndex(slides.length - 1);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  // Call the changeSlide function whenever the index changes
  React.useEffect(() => {
    changeSlide();
    // eslint-disable-next-line
  }, [index]);
  //mySlider----------------------------------------------------------------------------------------------
  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => prevIndex + n);
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  const showSlides = (n) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("dott");
    if (n > slides.length) setSlideIndex(1);
    if (n < 1) setSlideIndex(slides.length);
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = i === slideIndex - 1 ? "block" : "none";
      dots[i].className = dots[i].className.replace(" active", "");
    }
    // for (i = 0; i < dots.length; i++) {
    //     dots[i].className = dots[i].className.replace(" active", "");
    // }
    // dots[slideIndex - 1].className += " active";
  };

  // Call the showSlides function whenever the slideIndex changes
  React.useEffect(() => {
    showSlides(slideIndex);
    // eslint-disable-next-line
  }, [slideIndex]);

  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-section-two-factor text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Advanced Multi Factor Authentication
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  honClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="solutions.html">Solutions</a>{" "} */}
                <Link to="/solutions" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="color-45B0DC">
                  {" "}
                  / Advanced Multi Factor Authentication
                </span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center">
                <img className="images" src={authenticationImg} alt="" />
              </div>
              <div className="col-md-6">
                <p>
                  Multi-factor Authentication (MFA) is an authentication method
                  that requires the user to provide two or more verification
                  factors to gain access to a resource such as an application,
                  online account, etc. MFA is a core component of a strong
                  identity and access management (IAM) policy. Rather than just
                  asking for a username and password, MFA requires one or more
                  additional verification factors, which decreases the
                  likelihood of a successful cyber-attack.
                </p>
                <div className="gradient-title mb-15">
                  Why is MFA Important?
                </div>
                <p className="mb-0">
                  The main benefit of MFA is it will enhance your organization's
                  security by requiring your users to identify themselves by
                  more than a username and password. While important, usernames
                  and passwords are vulnerable to&nbsp;brute force
                  attacks&nbsp;and can be stolen by third parties. Enforcing the
                  use of an MFA factor like a face recognition or voice
                  authentication increased confidence that your organization
                  will stay safe from cyber criminals.
                </p>
              </div>
            </div>
          </div>
          <div className="gray-bg-section-img-slider">
            <div className="main-section">
              <div className="row mb-30">
                <div className="col-md-6 comman-mb">
                  <div className="gradient-title mb-20">Face Recognition </div>
                  <p>
                    Face Recognition is a biometric technology that identifies
                    and verifies an individual from a digital image and video.
                    It captures, examines, and then compares a person’s facial
                    details.
                  </p>
                  <p>
                    Facial recognition has turned into a popular tool to
                    authenticate the identity of an individual. In modern times,
                    this technology has been used in various sectors and
                    industries to prevent ID fraud and identity theft.
                  </p>
                </div>
                <div className="col-md-6 comman-mb">
                  <div className="heading-20 mb-15">
                    Why Face Recognition is Needed?
                  </div>
                  <p>
                    The Face Recognition technology is used in a variety of
                    applications, including device unlocking, banking, tourism,
                    police enforcement, building security, and others.
                  </p>
                  <ul className="list-content mb-15">
                    <li>
                      Face detection boosts productivity by raising accuracy.
                    </li>
                    <li>Face detection provides better security.</li>
                    <li>Face detection builds face recognition technology.</li>
                    <li>
                      Face detection makes facial motion capture possible.
                    </li>
                    <li>Face detection makes life easier.</li>
                  </ul>
                </div>
              </div>
              <div className="custom-slider">
                <div className="gradient-title text-center">
                  How Face Recognition works?
                </div>
                <div id="slider" className="slider">
                  <div className="slide" style={{ display: "block" }}>
                    <img src={faceSlide1} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide2} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide3} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide4} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide5} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide6} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide7} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide8} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide9} alt="" />
                  </div>
                  <div className="slide">
                    <img src={faceSlide10} alt="" />
                  </div>
                  {/*Controlling arrows*/}
                  <span
                    className="controls"
                    onClick={() => prevSlide(-1)}
                    id="left-arrow"
                  >
                    {" "}
                    <i className="fa-solid fa-angle-left" />
                  </span>
                  <span
                    className="controls"
                    id="right-arrow"
                    onClick={() => nextSlide(1)}
                  >
                    <i className="fa-solid fa-angle-right" />
                  </span>
                </div>
                <div id="dots-con">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </div>
              </div>
              {/* <div class="col-md-12">
          <div class="gradient-title ">How Face Recognition works?</div>
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators carouselIndicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
               
              </ol>
              <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img class="d-block w-100 w100" src="img/face-slide-img-1.png" alt="First slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-2.png" alt="Second slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-3.png" alt="Third slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-4.png" alt="Third slide">
                     
                  </div>
               
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-6.png" alt="Third slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-7.png" alt="Third slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-8.png" alt="Third slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-9.png" alt="Third slide">
                     
                  </div>
                  <div class="carousel-item">
                      <img class="d-block w-100 w100" src="img/face-slide-img-10.png" alt="Third slide">
                     
                  </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <i class="fa-solid fa-angle-left banner-btn"></i>
                  <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <i class="fa-solid fa-angle-right banner-btn"></i>
                  <span class="sr-only">Next</span>
              </a>
          </div>
      </div> */}
            </div>
          </div>
          <div className="main-section">
            <div className="col-md-12 comman-mb">
              <div className="gradient-title  mb-20 text-center">
                Benefits of Face Recognition
              </div>
              <div className="heading-20 text-center mb-15">
                There are several benefits of Face Recognition process in
                different businesses:
              </div>
              <ul className="list-content-7">
                <li> Improved Public Security.</li>
                <li className="initial">
                  {" "}
                  Fast and Non-Invasive Identity Verification.
                </li>
                <li> Benefited in Banking.</li>
                <li>Benefited in Retail business.</li>
                <li>Better Worker Attendance Systems.</li>
                <li className="initial">
                  Face Recognition Furthers Enables Computer Vision.
                </li>
              </ul>
            </div>
          </div>
          <div className="gray-bg-section-img-slider">
            <div className="main-section">
              <div className="row mb-30">
                <div className="col-md-6 comman-mb ">
                  <div className="gradient-title mb-20">
                    Voice Authentication
                  </div>
                  <p>
                    Voice authentication or voice recognition is a form of
                    biometrics, and voice authentication is the use of a user’s
                    speech to authenticate users. In other words, voice
                    biometrics is the science of using a person’s voice as a
                    unique identifying biological characteristic.
                  </p>
                  <p>
                    Voice biometrics enables fast, frictionless and highly
                    secure access for a range of use cases from call center,
                    mobile and online applications to chatbots, IoT devices and
                    physical access.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="heading-22 mb-15">
                    Why Voice Authentication is needed?
                  </div>
                  <p>
                    To verify an enrolled person's identity, the biometric voice
                    recognition system captures a new speech sample, creates a
                    template from the sample, and compares it against the
                    enrollment template. A strong match between templates
                    indicates that the same person spoke both samples, thus
                    verifying the person's identity.
                  </p>
                  <ul className="list-content mb-15">
                    <li>Voice authentication widely used in,</li>
                    <ul className="list-content mb-15">
                      <li>Mobile Applications</li>
                      <li>Calls Centers and IVR Systems</li>
                      <li>Web Applications</li>
                      <li>Internet of Things (IoT)</li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="gradient-title text-center">
                How Voice Recognition Works?
              </div>
              <div className="slideshow-container">
                <div className="mySlides fade" style={{ display: "block" }}>
                  <img
                    src={voiceSlide1}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide2}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide3}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide4}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide5}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide6}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide7}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide8}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide9}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide10}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide11}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide12}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="mySlides fade">
                  <img
                    src={voiceSlide13}
                    alt="Third slide"
                    className="width-100"
                  />
                </div>
                <div className="dots-container" style={{ display: "none" }}>
                  <span className="dott" onClick={() => currentSlide(1)}></span>
                  <span className="dott" onClick={() => currentSlide(2)}></span>
                  <span className="dott" onClick={() => currentSlide(3)}></span>
                  <span className="dott" onClick={() => currentSlide(4)}></span>
                  <span className="dott" onClick={() => currentSlide(5)}></span>
                  <span className="dott" onClick={() => currentSlide(6)}></span>
                  <span className="dott" onClick={() => currentSlide(7)}></span>
                  <span className="dott" onClick={() => currentSlide(8)}></span>
                  <span className="dott" onClick={() => currentSlide(9)}></span>
                  <span
                    className="dott"
                    onClick={() => currentSlide(10)}
                  ></span>
                  <span
                    className="dott"
                    onClick={() => currentSlide(11)}
                  ></span>
                  <span
                    className="dott"
                    onClick={() => currentSlide(12)}
                  ></span>
                  <span
                    className="dott"
                    onClick={() => currentSlide(13)}
                  ></span>
                </div>
                {/* <a
                  className="prev controls"
                  id="prevBtn"
                  onClick={() => plusSlides(-1)}
                >
                  <i className="fa-solid fa-angle-left" />
                </a> */}
                <Link
                  className="prev controls"
                  id="prevBtn"
                  onClick={() => plusSlides(-1)}>
                  <i className="fa-solid fa-angle-left" />
                </Link>
                {/* <a
                  className="next controls"
                  id="nxtBtn"
                  onClick={() => plusSlides(1)}
                >
                  <i className="fa-solid fa-angle-right" />
                </a> */}
                <Link 
                  className="next controls"
                  id="nxtBtn"
                  onClick={() => plusSlides(1)}>
                  <i className="fa-solid fa-angle-right" />
                </Link>
              </div>
              <br />
            </div>
          </div>
          <div className="main-section">
            <div className="col-md-12">
              <div className="gradient-title mb-20 text-center">
                Benefits of Voice Authentication
              </div>
              <ul className="list-content-6">
                <li>
                  {" "}
                  Reduced Costs - A voice biometrics solution not only reduces
                  the operational cost but increases the efficiency of your
                  security process.
                </li>
                <li>
                  {" "}
                  Improve security and minimize breaches due to compromised
                  passwords, phishing, etc.
                </li>
                <li>
                  Enhance the customer experience with fast, frictionless
                  authentication.
                </li>
                <li>Reduce threats by identifying known fraudsters.</li>
                <li>
                  Instantly identify users and personalize the interaction.
                </li>
                <li>
                  Free agents from time spent verifying users and resetting
                  passwords.
                </li>
                <li>
                  Enable natural login for digital channels, including chatbots
                  and virtual assistants.
                </li>
                <li>
                  {" "}
                  Use as part of a two-factor authentication process to increase
                  security without adding effort.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(MultifactorAuthentication);
