import WrapComponent from "../Common/WrapComponent";

import diagram18 from "../../assets/img/digram-18.png";
import travelAndTourismImage from "../../assets/img/travel-tourism-img.png";
import diagram10 from "../../assets/img/digram-19.png";
import WithToggle from "../Common/WithToggle";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { memo } from "react";
import ScrollToTopButton from "../Common/ScrollToTop";

const TravelAndTourism = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-3 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Travel &amp; Tourism
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="font-45B0DC"> / Travel &amp; Tourism</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="Travel and tourism"
                src={travelAndTourismImage}
              />
            </div>
            <div className="col-md-6">
              <p>
                As a leader in the travel and tourism industry, your
                organization is constantly looking for ways to improve
                efficiency, reduce costs, and enhance the customer experience.
                At EPI, we understand the unique challenges and opportunities
                facing your industry, and we have a range of solutions to help
                you meet them head-on.
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row ">
              <div className="col-md-6">
                <div className="gradient-title heading-24 mb-20">
                  Some key processes and challenges in the travel and tourism
                  industry include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">
                      Booking and Reservation Management:
                    </span> Managing 
                    the complex and time-consuming process of booking
                    and reserving travel arrangements, including flights,
                    hotels, and rental cars.
                  </li>
                  <li>
                    <span className="bullet-title">Customer Service:</span> Providing 
                    excellent customer service to ensure that
                    travelers have a positive experience and are likely to book
                    with you again in the future.
                  </li>
                  <li>
                    <span className="bullet-title">Destination Marketing:</span> Promoting 
                    your destinations and attracting travelers to your
                    region or country.
                  </li>
                  <li>
                    <span className="bullet-title">Data Management:</span> Managing 
                    and analyzing large volumes of data to inform
                    decision-making and improve the customer experience.
                  </li>
                </ul>
              </div>
              <div className="col-md-6 mb-15">
                <div className="gradient-title heading-24 mb-20">
                  To address these challenges and more, our solutions and
                  services include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">
                      Booking and reservation management solutions:
                    </span> Our 
                    solutions streamlines the booking and reservation
                    process, making it easier for travelers to book and manage
                    their travel arrangements.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Customer service platforms:
                    </span> Our 
                    customer service solutions built on the Salesforce
                    platform offer a range of tools to help you communicate with
                    customers and resolve any issues that may arise during their
                    trip.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Destination marketing tools:
                    </span> Our 
                    destination marketing tools built using Salesforce
                    Marketing Cloud help you promote your destinations and
                    attract travelers to your region or country.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Data management and analytics solutions:
                    </span> Our 
                    data management and analytics solutions help you extract
                    insights from large volumes of data, informing
                    decision-making and improving the customer experience. Kick
                    off key reporting via building data lake in line with long
                    term goals for customer segmentation and targeted marketing
                    campaign automation.
                  </li>
                  <li>
                    Designed the Data lake and configuration of data pipelines.
                    Data ingestion from multiple sources and BI reporting.
                  </li>
                </ul>
              </div>
              <div className="custom-text-center">
                <p className="mb-0">
                  Contact us today to learn more about how we can help your
                  organization navigate the challenges and opportunities of the
                  travel and tourism industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-3">
          <div className="main-section">
            <div className="custom-text-center mb-30">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 1</div>
                  <div className="card-content-4">
                    About the Company: The association was formed primarily to
                    help the association members to coordinate and liaison with
                    the Corporation for all negotiations, terms, deals,
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 2</div>
                  <div className="card-content-4">
                    About the Company: A leading travel booking operator
                    including corporate travel management and automation of
                    wholesale distribution of complex international airfares
                    having
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    The association was formed primarily to help the association
                    members to coordinate and liaison with the Corporation for
                    all negotiations, terms, deals, issues and concerns etc. as
                    a collective member. Primarily it deals in the following
                    services:
                  </p>
                  <ul className="list-content mb-20">
                    <li>
                      Platform and Service Provider for Car Rental Franchise
                      owners.
                    </li>
                    <li>
                      Managing different programs for Franchisees throughout the
                      locations.
                    </li>
                  </ul>
                  <p>
                    The association also helps the members in various different
                    programs such as LDW insurance, XM Radio, Counter Insurance
                    Products, Fleet procurement etc. It serves as one stop
                    service to all its members.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-20">
                    <li>
                      Build a system to reconcile the composites received by the
                      ABG to licensees and generate the exceptions.
                    </li>
                    <li>
                      Overcome the manual challenges by automating the complete
                      arithmetic as well as operational workflow to manage the
                      data as well as amount discrepancies.
                    </li>
                    <li>Automate the complete F68 billing process.</li>
                    <li>
                      Automate and quickly manage the To and Fro payments with
                      ACH integration.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We built a complete end to end product with fully functional
                    automated process for reconciliation of the composite as
                    well as F68 invoice generation between the parties. The
                    following are the solution highlights:
                  </p>
                  <ul className="list-content mb-15 ">
                    <li>
                      High degree of functional / product roadmap ownership.
                    </li>
                    <li>Product Development.</li>
                    <li>Scalable design with latest technology.</li>
                    <li>Integration with data feeds from Corporate.</li>
                    <li>
                      Easier data analyses, computation, invoice generation.
                    </li>
                    <li>Intuitive UI for applications.</li>
                    <li>Delivered Multiple Modules in 4 months.</li>
                    <li>Admin Portal, Licensee Portal.</li>
                    <li>Bi-Weekly Development Updates.</li>
                    <li>Maintenance and support.</li>
                    <li>Complete responsibility including.</li>
                    <li>
                      Infrastructure decisions, hosting and engagement with
                      other vendors.
                    </li>
                    <li>Production support: L2, L3.</li>
                  </ul>
                  <div className="text-center ">
                    <img className="images" alt="diagram10" src={diagram10} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 2</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    A leading travel booking operator including corporate travel
                    management and automation of wholesale distribution of
                    complex international airfares having various e-commerce
                    platforms to manage hotel/tour packages as well as flight
                    bookings through GDS and other OTA affiliates.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-20">
                    <li>
                      Build a scraper engine to parse the data from initially
                      two web sources which will essentially include the various
                      hotel rates for specific city.
                    </li>
                    <li>
                      While capturing the rates; build the dynamic algorithm to
                      capture the dynamic bifurcation of rates with respect to
                      the room types. Further, build the dynamic metadata model
                      to drive the comparison between the rates of various web
                      sources vs hotels.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We built a complete end to end solution with automated rate
                    engine to cater and parse dynamic data from various web
                    sources and compare the results between them using unified
                    masters. The following are the solution highlights:
                  </p>
                  <ul className="list-content mb-15">
                    <li>
                      <span className="bullet-title">Highly Configurable:</span>
                      Metadata driven for supporting new websites for scrapping
                      and data extraction; scrapping schedule configured.
                    </li>
                    <li>
                      <span className="bullet-title">Highly Scalable:</span>
                      Uses event driven model leveraging micro services to run
                      concurrent executions for data query, extract and curation
                      functions.
                    </li>
                    <li>
                      <span className="bullet-title">Cost Efficient:</span> Uses
                      container based execution for cost optimization which
                      gives ability to ramp up/down the infra based on volume
                      and concurrent throughput required.
                    </li>
                    <li>
                      <span className="bullet-title">Performance:</span> 3
                      different data storages aligned to optimize the IO for the
                      function being executed - Blob, Key value and Structured;
                      Cache used for reading metadata at run-time.
                    </li>
                    <li>
                      <span className="bullet-title">Traceability:</span>
                      Extracted data can be traced back to the html pages that
                      were queried.
                    </li>
                    <li>
                      <span className="bullet-title">Automation:</span> Query
                      profiles analyzed and mapped into meta data repo by
                      sampling the target web pages.
                    </li>
                    <li>
                      <span className="bullet-title">Flexible Scheduler:</span>
                      Scheduling tool manages the scrapping schedule for each
                      website and can be changed as per business needs.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Resiliency to Website Navigation &amp; HTML Changes at
                        Run-Time:
                      </span>
                      The approach will be able to manage the specific
                      event-based changes within the html based on search
                      results set.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Minimal Coding Required for Each Website via Hooks:
                      </span>
                      The web hook will be configurable to handle the class
                      changes within the html so it can be handled via property
                      of that module.
                    </li>
                  </ul>
                  <div className="text-center ">
                    <img className="images" alt="diagram18" src={diagram18} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(TravelAndTourism);
