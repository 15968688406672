import React, { memo } from "react";
import SliderSection from "./LandingPageSections/SliderSection";
import WelcomeSection from "./LandingPageSections/WelcomeSection";
import ClientSection from "./LandingPageSections/ClientSection";
import PhilosophySection from "./LandingPageSections/PhilosophySection";
import TeamSection from "./LandingPageSections/TeamSection";
import RoadmapSection from "./LandingPageSections/RoadmapSection";
import ThinqloudSection from "./LandingPageSections/ThinqloudSection";
import WrapComponent from "./Common/WrapComponent";
import ScrollToTopButton from "./Common/ScrollToTop";

const LandingPage = () => {
  return (
    <>
    <ScrollToTopButton/>
      <div>
            {/* <button className="btn scrollToTopBtn">
               <i className="fa-solid fa-arrow-up" />
            </button> */}
              <WrapComponent>
                <SliderSection />
                <WelcomeSection />
                <ClientSection />
                <PhilosophySection />
                <TeamSection />
                <RoadmapSection />
                <ThinqloudSection />
              </WrapComponent>
      </div>
    </>
  );
};

export default memo(LandingPage);