import React, { memo } from "react";
import abImg from "../../assets/img/ab-img.png";
import hsImg from "../../assets/img/hs-img.png";
import plImg from "../../assets/img/pl-img.png";

const TeamSection = () => {
  return (
    <>
      <div className="main-bg-section-2">
        <div className="custom-padding-2">
          <div className="custom-text-center">
            <div className="gradient-title mb-25">Meet Our Team</div>
          </div>
          <div className="row">
            <div className="col-md-4 media-mb-30">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img className="images" src={abImg} alt="Paris" />
                  </div>
                  <div className="flip-box-back justifyText">
                    <div className="social-icon">
                      <a
                        href="mailto:avinash.bhokare@extrapreneursindia.com"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="mail-icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/avinashbhokare"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="linkedin-icon" />
                      </a>
                      <a
                        href="https://twitter.com/@AvinashBhokare6"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="twitter-icon" />
                      </a>
                    </div>
                    <p>
                      Delivery &amp; People Management. 25+ years in IT. Has led
                      teams of 700+ engineers. Offshore setup and execution for
                      large ATM manufacturer in BOT model. Specialized in
                      Banking &amp; Government domain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="name">Avinash Bhokare</div>
              <div className="designation">Founder &amp; Director</div>
            </div>
            <div className="col-md-4 media-mb-30">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img className="images" src={plImg} alt="Paris" />
                  </div>
                  <div className="flip-box-back justifyText">
                    <div className="social-icon">
                      <a
                        href="mailto:prasanna.lahoti@extrapreneursindia.com"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="mail-icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/prasannalahoti/"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="linkedin-icon" />
                      </a>
                      <a
                        href="https://twitter.com/@praslahoti"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="twitter-icon" />
                      </a>
                    </div>
                    <p>
                      {" "}
                      Business Generation, Account Management &amp; Finance. 25
                      + years in IT. Led teams of 5000+ people. P&amp;L
                      responsibility for last ten years. Offshoring setup and
                      execution of 500 FTEs with single client. Manufacturing,
                      Retail &amp; Distribution and Banking domain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="name">Prasanna Lahoti</div>
              <div className="designation">Founder &amp; Director</div>
            </div>
            <div className="col-md-4">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img className="images" src={hsImg} alt="Paris" />
                  </div>
                  <div className="flip-box-back justifyText">
                    <div className="social-icon">
                      <a
                        href="mailto:harbinder.saini@extrapreneursindia.com"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="mail-icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/harbinder-saini-2815331/"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="linkedin-icon" />
                      </a>
                      <a
                        href="https://twitter.com/@harsaini"
                        target="_blank"
                        className="btn p-0"
                        rel="noreferrer"
                      >
                        <div className="twitter-icon" />
                      </a>
                    </div>
                    <p>
                      {" "}
                      Technology and New Offerings incubation. 25+ Years in IT
                      Consulting to major IT and finance companies working with
                      the CXOs. Worked on India’s national payment gateway.
                      Architected and implemented ATM, POS, Payment Gateway
                      solutions across major Indian private banks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="name">Harbinder Saini</div>
              <div className="designation">Founder &amp; Director</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TeamSection);
