import React, { memo } from "react";
import WrapComponent from "../Common/WrapComponent";

import testingQAServiceImage from "../../assets/img/testing-qa-services-img.png";
import diagram5 from "../../assets/img/digram-5.png";
import diagram6 from "../../assets/img/digram-6.png";
import QAImage1 from "../../assets/img/qa-img-1.png";
import QAImage2 from "../../assets/img/qa-img-2.png";
import QAImage3 from "../../assets/img/qa-img-3.png";
import QAImage4 from "../../assets/img/qa-img-4.png";
import diagram7 from "../../assets/img/digram-7.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const TestingAndQA = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="services-banner-section-6 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Testing &amp; QA Services
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
               Home
              </Link>{" "}
              <span className="font-45B0DC"> / Testing &amp; QA Services</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="testing"
                src={testingQAServiceImage}
              />
            </div>
            <div className="col-md-6">
              <p className="mb-15">
                We help organizations of all sizes ensure the quality and
                reliability of their products and systems through comprehensive
                testing and QA processes. Our team of experienced testers and QA
                professionals has a proven track record of delivering
                top-quality testing and QA services to a wide range of
                industries.
              </p>
              <div className="heading-20">
                We offer a full range of services, including:
              </div>
              <ul className="list-content">
                <li>
                  <span className="bullet-title">Manual Testing:</span> Our 
                  manual testing services include functional testing,
                  usability testing, compatibility testing, and more to ensure
                  that your products and systems meet your quality standards.
                </li>
                <li>
                  <span className="bullet-title">Automated Testing:</span> Our  
                  automated testing services can help you save time and
                  improve efficiency by automating repetitive or time-consuming
                  testing tasks. We can help you set up and maintain automation
                  testing frameworks using tools like Selenium, Appium, and
                  TestComplete, UFT, Katalon, etc.
                </li>
                <li>
                  <span className="bullet-title">Performance Testing:</span> Our 
                  performance testing services can help you ensure that your
                  products and systems can handle the expected load and
                  performance demands. We can help you design and execute
                  performance tests using tools like JMeter, LoadRunner, and
                  NeoLoad, etc.
                </li>
                <li>
                  <span className="bullet-title">Security Testing:</span> Our 
                  security testing services can help you identify and
                  mitigate vulnerabilities in your products and systems. We can
                  help you design and execute security tests using tools like
                  Burp Suite, ZAP, and WebInspect, etc.
                </li>
                <li>
                  <span className="bullet-title">Regression Testing:</span> Our 
                  regression testing services can help you ensure that
                  changes to your products and systems don't introduce new bugs
                  or issues. We can help you design and execute regression tests
                  to ensure ongoing quality and reliability.
                </li>
              </ul>
              <p className=" mt-15 mb-0">
                We pride ourselves on delivering top-quality testing and QA
                services that meet the unique needs of each of our clients. If
                you're interested in learning more about how our testing and QA
                services can benefit your organization, don't hesitate to
                contact us for a consultation. We look forward to working with
                you!
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="gradient-title custom-text-center mb-20">
              Our Testing Framework
            </div>
            <div className="heading-20 custom-text-center">
              We have a proven testing delivery framework that ensures success
              from Day 1. It covers:
            </div>
            <div className="row">
              <div className="col-md-4">
                <ul className="list-content">
                  <li>
                    Create comprehensive test plans based on client requirements
                  </li>
                  <li>
                    Develop and execute functional, regression and user
                    acceptance tests
                  </li>
                  <li>Perform compatibility testing on multiple platforms</li>
                  <li>Analyze test results and provide detailed reports</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-content">
                  <li>
                    Identify, isolate and troubleshoot application defects
                  </li>
                  <li>Perform Beta and alpha testing with end users</li>
                  <li>Enhance test automation processes</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-content">
                  <li>Develop and maintain automated test scripts</li>
                  <li>Ensure the quality of applications before launch</li>
                  <li>Develop test metrics and track them over time</li>
                </ul>
              </div>
            </div>
            <div className="text-center mt-20 mb-30">
              <img className=" images" alt="diagram" src={diagram5} />
            </div>
            <div className="gradient-title custom-text-center mb-15">
              Systems We have Typically Tested
            </div>
            <div className="heading-20 text-center">
              We have extensive testing experience on the following application
              stacks:
            </div>
            <div className="row mb-20">
              <div className="col-md-4">
                <ul className="list-content">
                  <li>
                    On premise hosted / Cloud hosted / Hybrid Cloud hosted
                  </li>
                  <li>Cloud native applications: Microservices</li>
                  <li>API based backends: Microservices, SOA</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-content">
                  <li>
                    Data platforms: Data pipelines, data lakes, data warehouses,
                    BI
                  </li>
                  <li>Web Apps</li>
                  <li>Mobile Apps: Native and hybrid</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-content">
                  <li>Chatbots: Free format and menu based</li>
                  <li>
                    Security Testing of application dealing with data privacy
                    laws and compliance globally
                  </li>
                  <li>
                    GDPR / CCPA and similar data privacy laws in various
                    countries globally
                  </li>
                </ul>
              </div>
            </div>
            <div className="custom-text-center">
              <p className=" mb-15 heading-20">
                We also have experience testing for localization and
                internationalization. As part of the testing and Quality
                management CoE, we have the following solution coverage
              </p>
            </div>
            <div className="text-center">
              <img className=" images" alt="diagram" src={diagram6} />
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="heading-20 custom-text-center mb-20">
            We have a proven testing delivery framework that ensures success
            from Day 1. It covers:
          </div>
          <div className="text-center mb-20">
            <img className=" images" alt="diagram7" src={diagram7} />
          </div>
          <div className="custom-text-center">
            <p className=" mb-0">
              It's important to choose the right testing tools for your specific
              needs and goals. Some testing tools may be better suited for
              certain types of testing strategies, while others may be more
              suitable for other types of testing. It's also worth noting that
              many testing tools are designed to support multiple types of
              testing strategies, so it's worth considering tools that can be
              used for multiple purposes.
            </p>
          </div>
        </div>
        <div className="main-bg-section-4">
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card-box-2 comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      Automation Test Suite for Enterprise Access Management
                      Product
                    </div>
                    <div className="card-content-4">
                      About the Company: This is a leading information
                      risk-management solutions provider specializing in
                      Privileged Access Management (PAM) and continuous Risk
                      Predictive solutions
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-1"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 comman-mb ">
                  <div className="padding-20">
                    <div className="small-text">
                      Comprehensive Test Suite with Automation for ERP SaaS
                      Cloud platform
                    </div>
                    <div className="card-content-4">
                      About the Company: A mature open source ERP Platform
                      currently focussed on Cannabis Industries and providing
                      SAAS solutions
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-2"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      Data Platform, Ingestion, Pipeline, Storage and BI Reports
                      Testing
                    </div>
                    <div className="card-content-4">
                      About the Company: One of the largest Travel, Hotel and
                      Flight Booking Platform in Middle East
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-3"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 comman-mb mb-0">
                  <div className="padding-20">
                    <div className="small-text">
                      API, Performance, Mobile Testing for a Video Streaming
                      Entertainment Platform
                    </div>
                    <div className="card-content-4">
                      About the Company: One of the largest Live streaming
                      platform in India...
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#useCase-4"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}

        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Automation Test Suite for Enterprise Access Management
                    Product
                  </div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      This is a leading information risk-management solutions
                      provider specializing in Privileged Access Management
                      (PAM) and continuous Risk Predictive solutions.
                    </li>
                    <li>500+ Global Enterprise Customers.</li>
                    <li> 150+ Global Partners.</li>
                  </ul>
                  <div className="gradient-title heading-22">Objective</div>
                  <ul className="list-content mb-15">
                    <li>
                      To reengineer existing applications to latest .Net Core
                      &amp; Angular based web application and test the platform
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Implement application to keep track of all applications
                      settings.
                    </li>
                    <li>
                      Implement application to maintain access &amp; security of
                      the servers.
                    </li>
                    <li> Implement Dashboard to present analytical data.</li>
                    <li>Testing of the features using Automation Testing.</li>
                  </ul>
                  <div className="gradient-title heading-22">Challenges</div>
                  <ul className="list-content mb-15">
                    <li>
                      Due to outdated technologies, older applications have
                      functional restrictions.
                    </li>
                    <li> All applications are running independently.</li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>Responsive web-based applications.</li>
                    <li>Common API layer across all applications.</li>
                    <li>API’s consumed with the help of API gateway.</li>
                    <li> Common database layer.</li>
                    <li>
                      Different types of databases supported/communicated from
                      API layer.
                    </li>
                    <li>High level of security implemented.</li>
                    <li>
                      Single sign on(SSO) supported to communicate between
                      different applications.
                    </li>
                    <li>
                      Test Automation using Cucumber, Selenium, TestNG, Maven
                      for few modules, Selenium, Jenkins, SVN for few others.
                    </li>
                  </ul>
                  <div className="text-center ">
                    <img className="images" alt="QA" src={QAImage1} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Comprehensive Test Suite with Automation for ERP SaaS Cloud
                    platform
                  </div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    A mature open source ERP Platform currently focussed on
                    Cannabis Industries and providing SAAS solutions
                  </p>
                  <div className="gradient-title heading-22">Objective</div>
                  <p>
                    To enhance core engineering practices and develop the
                    platform further to support existing and new client base and
                    further building organization capabilities
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    Open source platform supporting customizations for its
                    users, platform stability being the largest concern with
                    multiple releases and base ERP Platform incremental
                    versions.
                  </p>
                  <div className="gradient-title heading-22">Challenges</div>
                  <p>
                    Delivery streamlining in the organization along with Product
                    Implementation Strategies and Product Stability. Testing the
                    vast platform was a huge effort and cost and automation was
                    a must.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <div className="heading-20">
                    Platform enhancement and new feature development:
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Product Improvements within core ERP modules such as
                      Manufacturing, Sales, Accounting, Distribution.
                    </li>
                  </ul>
                  <div className="heading-20">
                    Process Setup and Implementation:
                  </div>
                  <ul className="list-content ">
                    <li>
                      Product Implementation, Architecture, Delivery Process
                      Implementation Testing.
                    </li>
                    <li> Test Strategy Documentation. </li>
                    <li> Test Plan Creation. </li>
                    <li>
                      Automation tool evaluation and finalization (TestProject
                      &amp; Katalan Studio).
                    </li>
                    <li>Automation Rollout Strategy and Devops integration</li>
                    <li> Functional Testing (Manual &amp; Automation).</li>
                    <li>
                      Browser Compatibility &amp; UI Responsiveness Testing
                      (Browserstack).
                    </li>
                    <li>Security Testing (OWASP).</li>
                  </ul>
                  <div className="text-center mb-0">
                    <img className="images" alt="QA" src={QAImage2} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-3">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Data Platform, Ingestion, Pipeline, Storage and BI Reports
                    Testing
                  </div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    One of the largest Travel, Hotel and Flight Booking Platform
                    in Middle East.
                  </p>
                  <div className="gradient-title heading-22">Objective</div>
                  <p>
                    Kick off key reporting via building data lake inline with
                    long term goal for customer segmentation and targeted
                    marketing campaigns automation.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    Lack of one view of Customer Info and No consolidated data
                    view for products and services including reports.
                  </p>
                  <div className="gradient-title heading-22">Challenges</div>
                  <p>
                    Multiple sources of unstructured data with no one data owner
                    and fixed requirement. Extensive Testing and Data Validation
                    for Quality Assurance.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Designing the Data Lake and configuration of data
                      pipelines.
                    </li>
                    <li> Data ingestion from multiple sources. </li>
                    <li> BI Reporting Testing. </li>
                    <li>Proposing and setting up QA Process. </li>
                    <li>Test Strategy definition.</li>
                    <li>
                      Testing of various ETL processes and data ingestion
                      pipelines.
                    </li>
                    <li>
                      Query Surge for data ingestion testing , Selenium
                      Automation for data validation, Manual testing of BI
                      Reports.
                    </li>
                  </ul>
                  <div className="text-center mb-0 ">
                    <img className="images" alt="QAImage3" src={QAImage3} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-4">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    API, Performance, Mobile Testing for a Video Streaming
                    Entertainment Platform
                  </div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>One of the largest Live streaming platform in India.</p>
                  <div className="gradient-title heading-22">Objective</div>
                  <p>
                    Live Streaming platform with Events discovery and social
                    following without a Content storage. The platform offers
                    anyone with talent a chance to go live in two clicks.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    The platform had to be extensively tested for performance,
                    scalability, overall functional testing.
                  </p>
                  <div className="gradient-title heading-22">Challenges</div>
                  <p>
                    Multiple aspects to be considered for overall platform
                    testing to achieve stability for multiple releases.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li> Manual API Testing using postman. </li>
                    <li> Cucumber used for API Testing Automation.</li>
                    <li> Appium for Mobile App Testing Automation.</li>
                    <li> JMeter for performance testing.</li>
                    <li>Code Magic for CICD integration.</li>
                    <li>
                      AWS Device Farm was used for Android and iOS device
                      testing.
                    </li>
                  </ul>
                  <div className="text-center mb-0 ">
                    <img className="images" alt="QA" src={QAImage4} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(TestingAndQA);
