import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import diagram45 from "../../assets/img/digram-45.png";
import diagram44 from "../../assets/img/digram-44.png";
import financialBg from "../../assets/img/financial-analyst-bg.png";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const FinancialAnalyst = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-financial-analyst text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Thor Financial Analyst Using ChatGPT
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                <a href="solutions.html">Solutions</a>{" "}
                <span className="font-45B0DC">
                  {" "}
                  / Thor Financial Analyst Using Chatgpt
                </span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center solution-img-tp">
                <img className="images " src={financialBg} alt="" />
              </div>
              <div className="col-md-6 ">
                <div className="gradient-title mb-15">
                  Why use a AI Financial Analyst?{" "}
                </div>
                <p>
                  Financial Analyst is crucial for individuals, businesses, and
                  organizations as it helps them make informed decisions and
                  evaluate their financial health. Here are some key reasons why
                  financial Analyst is important:
                </p>
                <ul className="list-content mb-20">
                  <li>
                    <span className="bullet-title">
                      Performance Evaluation:
                    </span>{" "}
                    Financial Analyst allows businesses and individuals to
                    assess their financial performance over time. It helps
                    identify areas of strength and weakness, enabling them to
                    make adjustments and improve efficiency.
                  </li>
                  <li>
                    <span className="bullet-title">Decision Making:</span> Sound
                    financial Analyst provides essential information for making
                    strategic decisions. It aids in evaluating investment
                    opportunities, and assessing potential risks and returns.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Planning and Budgeting:
                    </span>{" "}
                    Financial analyst plays a vital role in planning and
                    budgeting processes. By analyzing historical financial data,
                    forecasting future trends, and assessing financial
                    resources, individuals and organizations can develop
                    realistic budgets and set achievable goals.
                  </li>
                  <li>
                    <span className="bullet-title">Risk Assessment:</span>{" "}
                    Financial analyst helps identify and evaluate financial
                    risks faced by businesses and individuals. It allows for
                    assessing factors such as liquidity, solvency, and
                    profitability, which are crucial in determining the
                    stability and sustainability of an entity.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Stakeholder Communication:
                    </span>{" "}
                    Financial analyst provides meaningful insights for
                    communicating financial performance to stakeholders,
                    including investors, lenders, and shareholders. It helps
                    build transparency and trust by presenting accurate and
                    reliable financial information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="gradient-title mb-15">
                    THOR-Financial Analyst Using ChatGPT Highlights
                  </div>
                  <ul className="list-content">
                    <li>
                      The AI application allows users to submit/upload PDF
                      documents (which are stored in an S3 bucket), which needs
                      to be analyzed financially
                    </li>
                    <li>
                      Example documents will be company balance sheet, profit
                      and loss statement, cash flow, assets and liabilities,
                      bank statements and others.{" "}
                    </li>
                    <li>
                      In the case of password-protected documents, the user must
                      provide the password to enable reading of the documents.{" "}
                    </li>
                    <li>
                      The submitted documents are uploaded and stored in a
                      designated private "Vector Store" folder, accessible by
                      the LLM for processing.
                    </li>
                    <li>
                      After the documents are prepared, the LLM initiates the
                      processing phase to extract all relevant data.{" "}
                    </li>
                    <li>A model is trained using the extracted data.</li>
                    <li>
                      Once the model is trained, users can pose questions
                      pertaining to the provided documents, and the LLM will
                      retrieve answers based on the trained data.{" "}
                    </li>
                    <li>
                      The model performs a comprehensive analysis of multiple
                      documents to extract relevant information and generate
                      answers to user’s multiple questions.
                    </li>
                    <li>
                      In certain scenarios, ChatGPT may encounter difficulties
                      in providing accurate answers. To address this, we have
                      implemented a JSON mapping that maps the user's original
                      question to a rephrased question that the model can better
                      understand. This allows users to pose their questions as
                      usual while internally utilizing the rephrased question
                      (prompt engineering) to generate responses, which are then
                      presented to the user.{" "}
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <img
                    id="myImg"
                    src={diagram44}
                    onClick={() => {
                      openModal(diagram44);
                    }}
                    className="img-shadow width-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 text-center">
                <img
                  id="myImg1"
                  src={diagram45}
                  onClick={() => {
                    openModal(diagram45);
                  }}
                  className="img-shadow width-100"
                  alt=""
                />
              </div>
              <div className="col-md-6 ">
                <div className="gradient-title mb-15">Document Processing</div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">Split:</span> All the data
                    that is extracted from the documents is split into small
                    chunks.
                  </li>
                  <li>
                    <span className="bullet-title">Embedding:</span> We have
                    used OpenAIEmbeddings (NLP tool) to integrate the text data.
                    This requires an “OPENAI_API_KEY”.
                  </li>
                  <li>
                    <span className="bullet-title">Vector Store:</span> It is a
                    collection of pre-trained word embeddings that can be used
                    in natural language processing tasks. It helps in creating
                    the model.{" "}
                  </li>
                  <li>
                    <span className="bullet-title">Model:</span> We have used
                    RetrievalQA chain type to create and train the financial
                    analyst model that is based on the data from the provided
                    documents.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="gradient-title text-center">Benefits</div>
                  <ul className="list-content-6">
                    <li>
                      <span className="bullet-title">
                        Accessibility and Convenience:
                      </span>{" "}
                      Available 24/7 through online platforms, making financial
                      advice easily accessible at any time. Users can access
                      their accounts, review investment performance, and receive
                      recommendations conveniently from their computers or
                      mobile devices.
                    </li>
                    <li>
                      <span className="bullet-title">Cost-Effectiveness:</span>{" "}
                      Have lower fees compared to traditional human financial
                      advisors. Since it is relying on algorithms and
                      automation, it can offer the services at a fraction of the
                      cost, making financial advice more affordable for a wider
                      range of individuals.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Objectivity and Impartiality:
                      </span>{" "}
                      Recommendations are based on data and algorithms,
                      eliminating the potential biases or conflicts of interest
                      that can be present with human advisors. This objectivity
                      ensures that investment decisions are based on a
                      systematic analysis of financial factors rather than
                      subjective opinions.
                    </li>
                    <li>
                      <span className="bullet-title">Transparency:</span>{" "}
                      Provide transparency by offering clear and detailed
                      information about investment strategies, fees, and
                      performance. Users can easily track their investments,
                      understand the underlying holdings, and access
                      comprehensive reports, enhancing trust and accountability.
                    </li>
                    <li>
                      <span className="bullet-title">Scalability:</span> Can
                      handle a large number of clients simultaneously, allowing
                      for scalability without compromising the quality of
                      service. This scalability enables more individuals to
                      access financial advice without the limitations of human
                      resource availability.
                    </li>
                    <li>
                      <span className="bullet-title">
                        Consistency and Discipline:
                      </span>{" "}
                      Adhere to predefined investment strategies and rules,
                      ensuring consistent implementation without emotional
                      biases or impulsive decisions. Can help investors stay
                      disciplined and avoid reactive behavior in response to
                      market fluctuations.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="gradient-title text-center">Use Cases</div>
                <ul className="list-content-6">
                  <li>
                    <span className="bullet-title">
                      Automated data extraction:
                    </span>{" "}
                    Can extract relevant financial information from documents
                    such as company financial statements, CAS statements, etc.
                    and share it in JSON/CSV/EXCEL format. This can save time
                    and reduce errors compared to manual data entry.
                  </li>
                  <li>
                    <span className="bullet-title">Expense management:</span> By
                    processing financial documents like financial statements,
                    CAS statements or expense reports, you can automate expense
                    management processes. It can extract expense details,
                    categorize expenses, and generate reports, making it easier
                    to track and analyze expenses.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Loan and credit application processing:
                    </span>{" "}
                    When evaluating loan or credit applications, financial
                    institutions require a thorough analysis of various
                    documents such as bank statements, income statements, and
                    tax returns. It can extract relevant information, calculate
                    key metrics, and provide insights to support the
                    decision-making process.
                  </li>
                  <li>
                    <span className="bullet-title">Investment analysis:</span>{" "}
                    Financial documents such as annual reports or prospectus
                    contain critical information for investment analysis. It can
                    analyze these documents, extract financial data, perform
                    calculations, and generate insights to aid investment
                    decision-making.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}
          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt=""
              />
              <div id="caption" />
            </div>
          </div>
          {/* ..................................................modal end...................................................... */}
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(FinancialAnalyst);
