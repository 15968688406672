import WrapComponent from "../Common/WrapComponent";

import RPASolutionsImage from "../../assets/img/rpa-solutions-img.png";
import diagram23 from "../../assets/img/digram-23.png";
import usedToolImage from "../../assets/img/used-tool-img.png";
import diagram25 from "../../assets/img/digram-25.png";
import inputImage from "../../assets/img/input-img.png";
import inputImage1 from "../../assets/img/input-img-1.png";
import tableTagImage from "../../assets/img/table-tag-img.png";
import iamge29 from "../../assets/img/digram-29.png";
import diagram26 from "../../assets/img/digram-26.png";
import diagram27 from "../../assets/img/digram-27.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";
// import { useNavigate } from "react-router-dom";
import { memo } from "react";

const RPASolutions = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="services-banner-section-4 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">RPA Solutions</div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                  Home
              </Link>{" "}
              <span className="font-45B0DC"> / RPA Solutions</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img className="images" alt="RPAImage" src={RPASolutionsImage} />
            </div>
            <div className="col-md-6">
              <p>
                RPA (Robotic Process Automation) solutions are software systems
                that automate manual and repetitive tasks. They are designed to
                mimic the actions of a human user, such as clicking links,
                entering data, and navigating websites. RPA solutions can be
                used in a variety of industries to automate processes and
                improve efficiency. These solutions can be used to reduce costs,
                save time, and improve accuracy. Additionally, they can be used
                to create data-driven insights that can help increase
                productivity and drive decision-making. Examples of RPA
                solutions include automation of customer service processes,
                credit underwriting, customer onboarding, competitive analytics,
                invoice processing, automating excel based financial and HR
                processes.
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="gradient-title text-center">
                  The key benefits that RPA solutions offer
                </div>
                <ul className="list-content-6">
                  <li>
                    Automates tedious, repetitive tasks, allowing employees to
                    focus on more strategic, value-adding activities.
                  </li>
                  <li>Improves the accuracy and speed of processes.</li>
                  <li>
                    Reduces manual errors and the cost of labour associated with
                    manual processes.
                  </li>
                  <li>Enhances visibility into process performance.</li>
                  <li>
                    Facilitates collaboration and communication between
                    departments.
                  </li>
                  <li>
                    Streamlines processes to reduce cycle times and improve
                    customer service.
                  </li>
                  <li>
                    Integrates with existing systems to provide a comprehensive
                    view of data.
                  </li>
                  <li>
                    Increases scalability and agility to meet changing business
                    needs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="gradient-title text-center custom-text-center">
                Examples of common use cases, that we have implemented using RPA
                technology
              </div>
              <ul className="list-content-6">
                <li>Automating upload and download of Files to web apps</li>
                <li>Generation and dispatch of reports</li>
                <li>Manually Uploading data from excel</li>
                <li>Uploading data manually from FTP or other sources</li>
                <li>
                  Reading emails and extracting content to structured format
                  like JSON or XML for downstream processing
                </li>
                <li>
                  Reading emails and extracting attachments for downstream
                  processing like invoice processing
                </li>
                <li>
                  Scraping content from websites and using it for processing and
                  analytics
                </li>
                <li>
                  OCR solutions to extract data from Pictures as JSON/CSV/XML
                  and use it for processing e.g. ID documents like Aadhar, PAN,
                  SSN etc.
                </li>
                <li>Extract data from PDF files and convert to JSON/CSV/XML</li>
                <li>
                  Use Vision AI to compare live pictures for authentication e.g.
                  Liveliness check
                </li>
                <li>
                  Implement Chatbots using NLP Frameworks for automating support
                  services e.g. statement request
                </li>
                <li>Doing Video KYC to onboard a new customer</li>
                <li>
                  Convert voice to text and apply NLP to do sentiment analysis
                </li>
              </ul>
              <div className="heading-20 custom-text-center mb-15 mt-20">
                Our RPA Delivery Framework:
              </div>
              <div className="custom-text-center mb-0">
                <img className="images" alt="diagram23" src={diagram23} />
              </div>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="gradient-title mb-15">RPA tools used</div>
                <ul className="list-content-6">
                  <li>
                    <div className="bullet-title">RPA Paid Tools</div>
                    UI Path, BluePrism, Open Span, Automation Anywhere
                  </li>
                  <li>
                    <div className="bullet-title">Python Libraries</div>
                    Python RPA Framework, PyPI, Request
                  </li>
                  <li className="initial">
                    <div className="bullet-title">OCR</div>
                    Amazon Textract, Amazon Rekognition, Google Tesseract,
                    Google Vision, Asprise, ABBYY
                  </li>
                  <li className="initial">
                    <div className="bullet-title">Web Scrapping</div>
                    Beautiful Soup, Scrapy, Requests, Selenium
                  </li>
                  <li className="initial">
                    <div className="bullet-title">File Upload/Download</div>
                    Selenium, Python RPA Framework, RPA Tools
                  </li>
                  <li>
                    <div className="bullet-title">Email Reader</div>
                    Java, Python, UI Path, Other RPA Tools
                  </li>
                  <li>
                    <div className="bullet-title">Chatbots</div>
                    Python Chatterbot, RPA Tools
                  </li>
                  <li className="initial">
                    <div className="bullet-title">Vision</div>
                    Google Vision, Amazon Rekognition
                  </li>
                  <li>
                    <div className="bullet-title">PDF Reader</div>
                    Python, Nodejs Libraries
                  </li>
                </ul>
              </div>
              <div className="col-md-6 comman-mb">
                <img
                  className="images"
                  alt="usedToolImage"
                  src={usedToolImage}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-4">
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card-box-2 comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      Secure Web Portal Data Scraping for Healthcare Provider
                    </div>
                    <div className="card-content-4">
                      Problem Statement: UK based customer wanted to login into
                      various recruitment web portals and download all job
                      listings for consolidating and downstream processing –
                      where profiles are matched to open jobs and recommended
                      applicants are generated.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#usaecase-1"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2  comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      ID verification using OCR for Financial Services
                    </div>
                    <div className="card-content-4">
                      Problem Statement: India based Financial Services customer
                      wanted to automatically extract data from uploaded PAN
                      card and verify it.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#usaecase-2"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 mb-0 comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      Market Data Scraping for Financial
                      <br /> Services
                    </div>
                    <div className="card-content-4">
                      Problem Statement: India based Financial Services customer
                      wanted to automatically extract real-time market data and
                      news from target websites for use in customer facing
                      investment portals.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#usaecase-3"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 mb-0 comman-mb">
                  <div className="padding-20">
                    <div className="small-text">
                      Customer onboarding automation using OCR
                    </div>
                    <div className="card-content-4">
                      Problem Statement: India based Financial Services customer
                      wanted to automate customer onboarding process for Wealth
                      management.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#usaecase-4"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-2 mb-0">
                  <div className="padding-20">
                    <div className="small-text">
                      Invoice automation using OCR
                    </div>
                    <div className="card-content-4">
                      Problem Statement: India based customer wanted to bulk
                      process invoices received over email from vendors and
                      automate the upload of the invoice data into backend ERP
                      systems for Accounts Payable.
                    </div>
                    <div
                      className="btn keep-reading-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#usaecase-5"
                    >
                      Keep Reading
                      <i className="fa-solid fa-arrow-right ml-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}

        <WithToggle id="usaecase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Secure Web Portal Data Scraping for Healthcare Provider
                  </div>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    UK based customer wanted to login into various recruitment
                    web portals and download all job listings for consolidating
                    and downstream processing – where profiles are matched to
                    open jobs and recommended applicants are generated.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    For scraping the Web portals, we’ve used the power of
                    Selenium Web Driver and HTMLUnit to scrap and read the data
                    from various portals using class ids, x-path. The Selenium
                    web driver executes on Client machine or standalone server
                    and HTMLUnit uses virtual JVM to execute and grab the data
                    at a scheduled interval. Further, we’ve used Apache SOLR to
                    index the scrapped data and make it structured.
                  </p>
                  <div className="heading-20 ">
                    The following are the key highlights of the solution:&nbsp;
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      The Selenium Driver executes using a predefined Web Portal
                      properties URL, Credentials, etc) to scrap the data.
                    </li>
                    <li>
                      The properties are configurable from Admin Portal to get
                      the data using class id, x-path.
                    </li>
                    <li>
                      Configurable content search from the Web Portals with the
                      help of modifiable data dictionaries.
                    </li>
                    <li>
                      We have used Apache SOLR for indexing and formatting the
                      data.
                    </li>
                    <li>
                      We have used multi-threading that helps for faster
                      response and concurrent operations.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img className="images" alt="diagram25" src={diagram25} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="usaecase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    ID verification using OCR for Financial Services
                  </div>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    India based Financial Services customer wanted to
                    automatically extract data from uploaded PAN card and verify
                    it.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    Here we’ve used various OCR Tools to read and scrap objects
                    from various image formats like jpg, png, tiff, etc.
                  </p>
                  <div className="heading-20 ">
                    The key tools that we have used for OCR apart from Java
                    framework are as follows:
                  </div>
                  <p />
                  <ul className="list-content mb-15">
                    <li>Google Vision API</li>
                    <li>Asprise OCR</li>
                    <li>ABBYY OCR &amp; NLP</li>
                  </ul>
                  <div className="heading-20 ">
                    The steps are defined herein:
                  </div>
                  <ul className="list-content">
                    <li>Load PAN Image in memory.</li>
                    <li>Convert Image to bytes.</li>
                    <li>Pass Image bytes to google vision API.</li>
                    <li>In return, it give all text on the image.</li>
                    <li>
                      Parse that text according to standard PAN format using
                      string tokenizer.
                    </li>
                    <li>
                      Get different information i.e. Name, DOB and PAN number
                      from that.
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="usaecase-3">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Market Data Scraping for Financial Services
                  </div>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    India based Financial Services customer wanted to
                    automatically extract real-time market data and news from
                    target websites for use in customer facing investment
                    portals.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    The important part here to authenticate and login and then
                    read the real time market data and process it through
                    different channels. The steps are defined herein:
                  </p>
                  <ul className="list-content mb-15">
                    <li>
                      GET /loginPage for example
                      <b>https://www.abcxyz.com/login?goto=market</b>
                    </li>
                    <li>
                      Select the first username
                      <img alt="inputImage" src={inputImage} /> tag
                    </li>
                    <li>
                      Select the first
                      <img alt="inputImage1" src={inputImage1} /> tag
                    </li>
                    <li>
                      Set the value attribute for both inputs (username and
                      password)
                    </li>
                    <li>Select the enclosing form, and submit it.</li>
                    <li>
                      Check the logout link presence to verify we're logged in.
                    </li>
                    <li>Prints the cookie to the console</li>
                    <li>
                      Go to market page <b>(https://news.abcxyz.com/market)</b>
                    </li>
                    <li>Check table id and table class.</li>
                    <li>
                      Robo is going to select the
                      <img alt="tableTagImage" src={tableTagImage} /> tags that
                      have a class `itemlist`.
                    </li>
                    <li>
                      Then Robo will iterate through this table, and for each tr
                      and td select the news headline with link, comments data
                      and no of points to that news.
                    </li>
                    <li>
                      Robo will put it in JSON, using&nbsp;Jackson library, to
                      map all data in JSON format and push it to DB for further
                      processing
                    </li>
                    <li>
                      Finally, Robo will find out the logout link and click on
                      it to log out.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img
                      className="images desk-images"
                      alt="image29"
                      src={iamge29}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="usaecase-4">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">
                    Customer onboarding automation using OCR
                  </div>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    India based Financial Services customer wanted to automate
                    customer onboarding process for Wealth management.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      All the key steps that required manual verification of
                      uploaded documents for KYC were automated
                    </li>
                    <li>
                      OCR was used to extract data from uploaded documents: ID
                      Proof, Address Proof, Bank Statement
                    </li>
                    <li>
                      OCR was used to extract data from uploaded Bank Cheque
                      Image
                    </li>
                    <li>
                      OCR was used to match uploaded or entered signature with
                      the Signature extracted from PAN
                    </li>
                    <li>
                      Vision was used to match live photo taken on the app with
                      the image extracted from PAN or Aadhar card
                    </li>
                    <li>
                      E-Sign was used to get user consent and automate the
                      generation of the agreement and customer application form
                    </li>
                    <li>
                      Amazon Textract and Rekognition were extensively used to
                      deliver the RPA solution
                    </li>
                  </ul>
                  <div className="text-center">
                    <img
                      className="images desk-images"
                      alt="diagram26"
                      src={diagram26}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <WithToggle id="usaecase-5">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Invoice automation using OCR</div>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <p>
                    India based customer wanted to bulk process invoices
                    received over email from vendors and automate the upload of
                    the invoice data into backend ERP systems for Accounts
                    Payable.
                  </p>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <ul className="list-content mb-15">
                    <li>
                      Java based email reader using Camel Framework was
                      implemented
                    </li>
                    <li>
                      Extracted invoices were uploaded to FTP folder and onto
                      AWS S3 for storage
                    </li>
                    <li>
                      OCR was used to extract data from uploaded invoices into
                      JSON format and stored in PostgresSQL database
                    </li>
                    <li>
                      ERP APIs were called and the JSON payload passed for
                      processing
                    </li>
                  </ul>
                  <div className="text-center">
                    <img
                      className="images desk-images"
                      alt="diagram27"
                      src={diagram27}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(RPASolutions);
