import React, { memo } from "react";
import WrapComponent from "../Common/WrapComponent";
import bankingFianceImage from "../../assets/img/banking-finance-img-1.png";
import diagram12 from "../../assets/img/digram-12.png";
import diagram13 from "../../assets/img/digram-13.png";
import diagram14 from "../../assets/img/digram-14.png";
import WithToggle from "../Common/WithToggle";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const BankingAndFiance = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-1 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Banking &amp; Finance
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="color-45B0DC"> / banking &amp; finance</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="Banking and Fiance"
                src={bankingFianceImage}
              />
            </div>
            <div className="col-md-6">
              <p>
                As a leader in the banking and financial services industry, your
                organization is constantly looking for ways to improve
                efficiency, reduce risk, and deliver value to your customers. At
                EPI, we understand the unique challenges and opportunities
                facing your industry, and we have a range of solutions to help
                you meet them head-on.
              </p>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="gradient-title heading-24 mb-20">
                  Some key processes and challenges in the banking and financial
                  services industry include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">Customer Onboarding:</span> Ensuring
                    that new customers are quickly and easily onboarded
                    onto your platform, while also meeting regulatory
                    requirements and minimizing risk.
                  </li>
                  <li>
                    <span className="bullet-title">Compliance:</span> Managing
                    complex and constantly changing regulatory requirements,
                    including Know Your Customer (KYC) and financial market
                    (SEBI, IRDA,…) regulations.
                  </li>
                  <li>
                    <span className="bullet-title">Fraud Prevention:</span> Protecting 
                    your customers and your organization from
                    fraudulent activity, while also minimizing false positives
                    and customer friction.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Digital Transformation:
                    </span> Embracing 
                    new technologies and approaches to drive
                    efficiency and improve the customer experience.
                  </li>
                </ul>
              </div>
              <div className="col-md-6 mb-25">
                <div className="gradient-title heading-24 mb-20">
                  To address these challenges and more, our solutions include:
                </div>
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">
                      Customer Onboarding Automation:
                    </span> Streamline
                    the onboarding process with our automated
                    solution, which combines document capture, identity
                    verification, and risk assessment to speed up the process
                    and reduce risk.
                  </li>
                  <li>
                    <span className="bullet-title">Compliance Automation:</span> Our 
                    compliance automation solutions can help you automate
                    manual processes and reduce risk, while also meeting
                    regulatory requirements.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Fraud prevention Tools:
                    </span> Our 
                    fraud prevention tools use advanced machine learning
                    algorithms to detect and prevent fraudulent activity, while
                    also minimizing false positives.
                  </li>
                  <li>
                    <span className="bullet-title">
                      Digital transformation consulting:
                    </span> Our 
                    team of experts can help you identify opportunities for
                    digital transformation and develop a roadmap for success.
                  </li>
                </ul>
              </div>
              <div className="custom-text-center">
                <p className="mb-0">
                  Contact us today to learn more about how we can help your
                  organization navigate the challenges and opportunities of the
                  banking and financial services industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-3">
          <div className="main-section">
            <div className="custom-text-center mb-30">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 1</div>
                  <div className="card-content-4">
                    About the Company: Platform and Service Provider for
                    Personal Financial Advice and Financial Planning and a
                    technology centric market place for
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 2</div>
                  <div className="card-content-4">
                    About the Company: Platform and Service Provider for
                    Personal Financial Advice and Financial Planning and a
                    technology centric marketplace for personal finance
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}

        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Platform and Service Provider for Personal Financial Advice
                    and Financial Planning and a technology centric market place
                    for personal finance and Service Provider for Personal
                    Financial Advice and Financial Planning.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content-8 mb-15">
                    <li>
                      The company had just evolved from a B to C model to a B to
                      B model with a huge potential to grow
                    </li>
                    <li>Time to market was the biggest force</li>
                    <li>
                      The design should accommodate various interfaces securely
                      and elegantly and it should also scale for future
                      interfaces
                    </li>
                    <ul className="list-content-8">
                      <li>It should have an open architecture </li>
                      <li>
                        The design should be based on service-oriented
                        architecture so that the components are highly cohesive
                        and loosely coupled
                      </li>
                    </ul>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We developed and implemented a fully functional automated
                    process for wealth management for high network
                    individualsHigh degree of functional / product roadmap
                    ownership
                  </p>
                  <ul className="list-content-8">
                    <li>
                      High degree of functional / product roadmap ownership
                    </li>
                    <ul className="list-content-8">
                      <li>Product Development</li>
                      <li>State of the art UI for new applications</li>
                      <ul className="list-content-8 ">
                        <li>Delivered Multiple Apps in 4 months</li>
                        <li>Client Portal, Partner Portal</li>
                        <li>Service Portal, Form Printing</li>
                        <li>Mobile Financial Planning Tool</li>
                      </ul>
                    </ul>
                    <ul className="list-content-8">
                      <li>Bi-Weekly Production Updates</li>
                    </ul>
                  </ul>
                  <ul className="list-content-8 mb-20">
                    <li>Production and support</li>
                    <ul className="list-content-8">
                      <li>Complete responsibility including </li>
                      <ul className="list-content-8">
                        <li>
                          Infrastructure decisions, hosting and engagement with
                          other vendors
                        </li>
                        <li>Production support: L1, L2, L3</li>
                      </ul>
                    </ul>
                  </ul>
                  <div className="text-center mb-20">
                    <img className="images" alt="diagram" src={diagram13} />
                  </div>
                  <div className="text-center mb-0">
                    <img className="images" alt="diagram14" src={diagram14} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}

        <WithToggle id="useCase-2">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 2</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Platform and Service Provider for Personal Financial Advice
                    and Financial Planning and a technology centric marketplace
                    for personal finance.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content-8 mb-15">
                    <li>
                      A platform for wealth management customer and high degree
                      of Analytics was to be created.
                    </li>
                    <li>
                      Multiple sources of unstructured data with no one data
                      owner and fixed requirement.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We developed and implemented custom solution to address the
                    challenges and limitations as stated above.
                  </p>
                  <ul className="list-content-8 mb-20">
                    <li>
                      High degree of functional / product roadmap ownership.
                    </li>
                    <li>
                      Creation of a data lake and adapters from various external
                      and internal systems including the RTA.
                    </li>
                    <li>Various data real time data capture from mobiles.</li>
                    <li>
                      Data platform of scale ~10TB to handle 100k+ customers,
                      ~50M transactions.
                    </li>
                    <li>
                      Use cases covering BI, Reporting, Complex financial data
                      analysis, Real time access of data, Mobile device
                      analysis, Real time location tracking.
                    </li>
                    <li>
                      Various business units generating data. Synced data from
                      all to data platform and provided analysis.
                    </li>
                  </ul>
                  <div className="text-center">
                    <img className="images" alt="diagram" src={diagram12} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(BankingAndFiance);
