import React, { memo, useState } from "react";
import BIAnalyticsImage from "../../assets/img/bi-analytics-img.png";
import diagram3 from "../../assets/img/digram-3.png";
import diagram4 from "../../assets/img/digram-4.png";
import BIImage1 from "../../assets/img/bi-img-1.png";
import BIImage2 from "../../assets/img/bi-img-2.png";
import { Link } from 'react-router-dom';
import WrapComponent from "../Common/WrapComponent";
import WithToggle from "../Common/WithToggle";
import ScrollToTopButton from "../Common/ScrollToTop";

// import { useNavigate } from "react-router-dom";

const BIAnalytics = () => {
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <div className="services-banner-section-2 text-center">
        <div className="banner-text media-banner-text">
          <div className="banner-gradient-title mb-15"> BI &amp; Analytics</div>
          <div className="breadcrumb-links ">
            {/* <a
              onClick={() => {
                navigate("/");
              }}
              href=""
            >
              Home
            </a> */}
            <Link to="/" onClick={() => {}}>
              Home
            </Link>{" "}
            <span className="color-45B0DC"> / BI &amp; Analytics</span>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-6 comman-mb">
            <img
              className="images"
              alt="analyticsImage"
              src={BIAnalyticsImage}
            />
          </div>
          <div className="col-md-6">
            <p>
              Business intelligence and analytics tools help to transform raw
              data into meaningful information. Insightful information and
              analytics allow business owners to make better decisions, increase
              efficiency, and maximize profits.
            </p>
            <p>
              We help organizations of all sizes leverage the power of data to
              make better informed decisions, increase efficiency, and drive
              business growth. Our team of experienced data professionals has a
              proven track record of delivering top-quality BI and analytics
              solutions to a wide range of industries. We offer a full range of
              services, including:
            </p>
            <ul className="list-content mb-20">
              <li>
                <span className="bullet-title">
                  Data integration and management:
                </span> We 
                can help you collect, organize, and manage your data to
                ensure it is ready for analysis.
              </li>
              <li>
                <span className="bullet-title">Data visualization: </span>We can
                create custom dashboards and reports that help you understand
                and communicate insights from your data.
              </li>
              <li>
                <span className="bullet-title">Data analysis: </span>Our team
                can use advanced analytics techniques to uncover insights and
                trends in your data, and help you make data-driven decisions.
              </li>
              <li>
                <span className="bullet-title">Machine learning: </span>We can
                help you build and deploy machine learning models to predict
                future outcomes and make more accurate forecasts.
              </li>
              <li>
                <span className="bullet-title">Predictive analytics: </span>Our
                predictive analytics services can help you identify patterns and
                trends in your data to make better informed decisions.
              </li>
            </ul>
            <p className="mb-0">
              We pride ourselves on delivering top-quality services and
              solutions that meet the unique needs of each of our clients.
            </p>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row">
            <div className="col-md-6">
              <div className="gradient-title mb-20">
                Our Business Intelligence Implementation Framework
              </div>
              <p>
                Business intelligence (BI) is a powerful tool that can help
                companies make better informed decisions, increase efficiency,
                and improve profitability.
              </p>
              <div className="heading-20 text-center mb-15">
                Our Implementation framework covers the following key steps:
              </div>
              <ul className="list-content">
                <li>
                  Identify your business goals and objectives. Before
                  implementing any BI solution, it's important to have a clear
                  understanding of what you want to achieve. This will help you
                  choose the right BI tools and tactics for your organization.
                </li>
                <li>
                  Select the right BI tools. There are a wide variety of BI
                  tools available, each with its own strengths and weaknesses.
                  Some common options include dashboards, reporting tools, data
                  visualization tools, and analytics Platforms. It's important
                  to choose the right tools for your specific needs and budget.
                </li>
                <li>
                  Collect and organize your data. BI relies on data, so it's
                  important to have a plan in place for collecting and
                  organizing this data. This may include setting up data
                  pipelines, integrating data from multiple sources, building
                  data lake, warehouse or lakehouse and creating a data
                  governance plan.
                </li>
                <li>
                  Analyze and visualize your data. Once you have your data
                  organized, you can begin to analyze and visualize it to gain
                  insights and make better informed decisions. This may include
                  creating dashboards, generating reports, and using data
                  visualization tools.
                </li>
                <li>
                  Implement a BI governance plan. A BI governance plan is a set
                  of guidelines and processes for managing the use of BI within
                  your organization. This may include setting up a BI steering
                  committee, establishing roles and responsibilities, and
                  defining metrics for measuring the success of your BI efforts.
                </li>
                <li>
                  Train your employees. It's important to make sure that your
                  employees are trained in the use of the BI tools and
                  techniques you are implementing. This will help ensure that
                  they are able to effectively use the data and insights
                  generated by your BI efforts to make better informed
                  decisions.
                </li>
                <li>
                  Implementing BI for any business can be a complex and
                  time-consuming process, but it can also be a powerful way to
                  drive business growth and improve efficiency. By following
                  these steps, you can set your organization up for success with
                  BI.
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img
                id="myImg"
                alt="diagram"
                src={diagram3}
                onClick={() => {
                  openModal(diagram3);
                }}
                className="img-shadow mb-20 mt-15 width-100"
              />
              <div className="heading-20">
                A Sample BI Solution using AWS Stack
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className="custom-text-center">
              <div className="gradient-title mb-20 custom-text-center">
                Our BI Tools Experience
              </div>
              <p>
                There are a wide variety of business intelligence (BI) tools
                available, and the right tools for your organization will depend
                on your specific needs and budget.
              </p>
              <p className="heading-20  mb-15">
                Some common BI tools that we use for implementing the right fit
                solution covers:
              </p>
            </div>
            <ul className="list-content-6 ">
              <li>
                <span className="bullet-title">Dashboards:</span> Dashboards are
                visual displays of key performance indicators (KPIs) and other
                important data. They can help users quickly understand the
                current state of the business and identify trends and patterns.
              </li>
              <li>
                <span className="bullet-title">Reporting Tools:</span> Reporting
                tools allow users to generate reports based on specific data
                sets and criteria. These tools can be used to create customized
                reports on demand or to schedule regular reports to be delivered
                automatically.
              </li>
              <li>
                <span className="bullet-title">Data Visualization Tools:</span>
                Data visualization tools allow users to create charts, graphs,
                and other visual representations of data. These tools can help
                users better understand complex data sets and communicate
                insights to others.
              </li>
              <li>
                <span className="bullet-title">Analytics Platforms:</span>
                Analytics platforms provide a range of tools and capabilities
                for data analysis, including machine learning, predictive
                analytics, and natural language processing. These platforms can
                help users discover insights and trends in large data sets.
              </li>
              <li>
                <span className="bullet-title">
                  Collaboration and Communication Tools:
                </span>
                Collaboration and communication tools, such as chat and project
                management software, can help teams work together more
                effectively and share insights and data with one another.
              </li>
              <li>
                <span className="bullet-title">Data Integration Tools:</span>
                Other BI tools include data integration and ETL (extract,
                transform, load) tools, data warehousing platforms, and mobile
                BI solutions. It's important to choose the right tools for your
                organization's needs and budget.
              </li>
              <img
                id="myImg1"
                src={diagram4}
                alt="diagram"
                onClick={() => {
                  openModal(diagram4);
                }}
                className="img-shadow mt-15 width-100"
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title mb-20">
              Data Storage Strategies for BI
            </div>
            <p className="mb-20 ">
              One of core activities for implementing a successful BI solutions
              requires Data aggregation from various sources – internal and
              external to the organization and its storage. We have deep
              experience in implementing Data Lakes, Data Warehouses and
              Lakehouses. Here is a quick snapshot of their key features.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card-box-6 mb-0 comman-mb">
                <div className="padding-20">
                  <div className="heading-20">Data Lakes</div>
                  <div className="small-text">Definition</div>
                  <p>
                    A large, centralized repository that allows businesses to
                    store all their structured and unstructured data at any
                    scale.
                  </p>
                  <div className="small-text">Storage</div>
                  <p>Stores raw, unstructured data.</p>
                  <div className="small-text">Schema</div>
                  <p>
                    No strict schema required. Data is stored in its raw format.
                  </p>
                  <div className="small-text">Query and Analysis</div>
                  <p>
                    Queries can be slow, as data must btransformed e and
                    structured before it can be analyzed.
                  </p>
                  <div className="small-text">Use case</div>
                  <p>
                    Ideal for storing and analyzing large amounts of
                    unstructured data.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-6 mb-0 comman-mb">
                <div className="padding-20">
                  <div className="heading-20"> Data Warehouses</div>
                  <div className="small-text">Definition</div>
                  <p>
                    A centralized repository that stores structured data
                    specifically designed for fast query and analysis.
                  </p>
                  <div className="small-text">Storage</div>
                  <p>Stores structured data.</p>
                  <div className="small-text">Schema</div>
                  <p>
                    Strict schema required. Data must be structured and conform
                    to a specific format.
                  </p>
                  <div className="small-text">Query and Analysis</div>
                  <p>
                    Designed for fast query and analysis of structured data.
                  </p>
                  <div className="small-text">Use case</div>
                  <p>Ideal for structured data analysis and reporting.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-6 mb-0">
                <div className="padding-20">
                  <div className="heading-20">Lakehouses</div>
                  <div className="small-text">Definition</div>
                  <p>
                    A combination of a data lake and a data warehouse, designed
                    to provide the benefits of both technologies.
                  </p>
                  <div className="small-text">Storage</div>
                  <p>Can store both structured and unstructured data.</p>
                  <div className="small-text">Schema</div>
                  <p>
                    Can have a flexible schema like a data lake, but also
                    supports structured data like a data warehouse.
                  </p>
                  <div className="small-text">Query and Analysis</div>
                  <p>
                    Combines the fast query and analysis of a data warehouse
                    with the flexibility and scalability of a data lake.
                  </p>
                  <div className="small-text">Use case</div>
                  <p>
                    Provides the benefits of both data lakes and data
                    warehouses, making it ideal for a wide range of use cases.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-text-center">
            <p className=" mt-20 mb-0">
              It's important to note that data lakes, data warehouses, and
              lakehouses are not mutually exclusive and can often be used
              together as part of a larger BI strategy. The right solution for
              your organization will depend on your specific needs and goals.
            </p>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className="custom-text-center">
              <div className="gradient-title  mb-10">
                Our Technology Experience
              </div>
              <p className=" mb-20">
                There are a number of technologies that can be used to implement
                data lakes, data warehouses, and lakehouses.
              </p>
            </div>
            <div className="heading-20 text-center mb-15">
              Some common technologies include:
            </div>
            <ul className="list-content-6">
              <li>
                <span className="bullet-title">Hadoop:</span> Hadoop is an
                open-source framework that can be used to store and process
                large amounts of data. It is often used to implement data lakes
                and can be used in conjunction with other technologies like
                Spark for data processing.
              </li>
              <li>
                <span className="bullet-title">Spark:</span> Spark is an
                open-source data processing engine that can be used to perform
                distributed data processing tasks on large data sets. It can be
                used in conjunction with Hadoop to analyze data stored in a data
                lake.
              </li>
              <li>
                <span className="bullet-title">Cloud Storage:</span> Cloud
                storage platforms like Amazon S3 and Google Cloud Storage can be
                used to store large amounts of data in the cloud. These
                platforms are often used to implement data lakes.
              </li>
              <li>
                <span className="bullet-title">Relational Databases:</span>
                Relational databases like MySQL, PostgresSQL, and SQL Server can
                be used to implement data warehouses. These databases are
                designed to store structured data and support fast query and
                analysis.
              </li>
              <li>
                <span className="bullet-title">
                  Data Integration and ETL Tools:
                </span>
                Data integration and ETL (extract, transform, load) tools can be
                used to move data from various sources into a data lake or data
                warehouse. These tools can also be used to clean, transform, and
                structure the data as needed.
              </li>
              <li>
                <span className="bullet-title">
                  Data Warehousing Platforms:
                </span>
                Data warehousing platforms like Amazon Redshift, Snowflake and
                Google BigQuery can be used to implement data warehouses. These
                platforms are optimized for fast query and analysis of large
                data sets.
              </li>
              <li>
                <span className="bullet-title">Data Visualization Tools:</span>
                Data visualization tools like Tableau, Apache Superset, Qlik,
                and Power BI can be used to create charts, graphs, and other
                visual representations of data stored in a data lake, data
                warehouse, or lakehouse. These tools can help users understand
                and communicate insights from their data.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row ">
            <div className="col-md-12">
              <div className="custom-text-center">
                <div className="gradient-title  mb-20">
                  Our Analytics Services
                </div>
                <p className=" mb-15">
                  Business intelligence (BI) and analytics are often used
                  interchangeably, but they are not the same thing. While BI is
                  focused on using data and insights to inform business
                  decisions, analytics is focused on using data and statistical
                  methods to understand and predict patterns and trends.
                </p>
              </div>
              <div className="heading-22 text-center mb-15">
                Some key differences between BI and analytics include:
              </div>
              <ul className="list-content-6">
                <li>
                  BI is focused on using data to inform decision-making, while
                  analytics is focused on understanding and predicting patterns
                  and trends.
                </li>
                <li>
                  BI tools often include dashboards, reporting tools, and data
                  visualization tools that allow users to view and analyze data.
                  Analytics tools, on the other hand, often include machine
                  learning algorithms and predictive analytics capabilities that
                  allow users to forecast future outcomes.
                </li>
                <li>
                  BI is typically reactive, while analytics is proactive. BI
                  helps organizations understand what has happened in the past,
                  while analytics helps organizations predict what will happen
                  in the future.
                </li>
              </ul>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="gradient-title custom-text-center mb-20 mt-20">
                Our Analytics Technology Experience
              </div>
              <div className="heading-22 text-center mb-15">
                We use a number of tools to implement analytics, including:
              </div>
              <ul className="list-content-6 ">
                <li>
                  <span className="bullet-title">
                    Machine Learning Platforms:
                  </span>
                  Machine learning platforms like Google Cloud AI Platform and
                  Amazon SageMaker provide a range of tools and algorithms for
                  building and deploying machine learning models.
                </li>
                <li>
                  <span className="bullet-title">
                    Predictive Analytics Platforms:
                  </span>
                  Predictive analytics platforms like RapidMiner and KNIME
                  provide a range of tools and algorithms for building and
                  deploying predictive models.
                </li>
                <li>
                  <span className="bullet-title">
                    Data Visualization Tools:
                  </span>
                  Data visualization tools like Tableau and Power BI can be used
                  to create charts, graphs, and other visual representations of
                  data. These tools can also be used to build and deploy machine
                  learning models and predictive analytics models.
                </li>
                <li>
                  <span className="bullet-title">
                    Statistical analysis software:
                  </span>
                  Statistical analysis software like R and SAS can be used to
                  perform advanced statistical analysis on data sets.
                </li>
                <li>
                  <span className="bullet-title">Data manipulation Tools:</span>
                  Data manipulation tools like Excel and SQL can be used to
                  clean, transform, and structure data sets for analysis.
                </li>
              </ul>
              <div className="custom-text-center">
                <p className=" mt-20 mb-0">
                  It's important to note that many BI tools also include
                  analytics capabilities, and some analytics tools include BI
                  capabilities. The right tools for your organization will
                  depend on your specific needs and goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-bg-section-4">
        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title">Use Cases</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card-box-2 comman-mb mb-0">
                <div className="padding-20">
                  <div className="small-text">
                    BI Solution for Wealth Management
                  </div>
                  <div className="card-content-4">
                    About the Company: Financial services with strong focus on
                    Private and Digital Wealth Management...
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-box-2 comman-mb mb-0">
                <div className="padding-20">
                  <div className="small-text">
                    Data Platform for Online Travel Business
                  </div>
                  <div className="card-content-4">
                    Leading Travel Business: Data Platform for Analytics &amp;
                    BI (UAE) About the Company: One of the largest Online
                    Travel, Hotel and Flight Booking Platform in UAE...
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ..................................................modal start...................................................... */}
      <div className="custom-modal">
        <div
          id="myModal"
          className="modal"
          style={{ display: modalVisible ? "block" : "none" }}
        >
          <span className="close" onClick={closeModal}>
            ×
          </span>
          <img
            className="modal-content"
            id="img01"
            alt="diagram"
            src={modalImgSrc}
          />
          <div id="caption" />
        </div>
      </div>
      {/* ..................................................modal end...................................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-1">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  BI Solution for Wealth Management
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Financial services with strong focus on Private and Digital
                    Wealth Management
                  </li>
                  <li>
                    Delivers financial planning, transaction processing,
                    reporting, re-balancing and BI to customers, RMs and
                    Distributors seamlessly.
                  </li>
                </ul>
                <div className="gradient-title heading-22">Objective</div>
                <ul className="list-content mb-15">
                  <li>
                    Build data warehouse for MIS reporting and BI consolidating
                    data from various front-end and back-office systems.
                  </li>
                  <li>
                    Implement BI using a Visualization Tool for RMs,
                    Distributors and Management to slice/dice and engage with
                    customers deeply.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  A platform for wealth management customer and high degree of
                  Analytics was to be created.
                </p>
                <div className="gradient-title heading-22">Challenges</div>
                <p>
                  Multiple sources of structured data with conflicting source of
                  records.
                </p>
                <p>
                  Real-time on demand and scheduled bulk data transfer was
                  needed.
                </p>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    High degree of functional / product roadmap ownership.
                  </li>
                  <li>
                    Creation of a data warehouse and adapters from various
                    external and internal systems including the RTA.
                  </li>
                  <li>Various data real time data capture from mobiles.</li>
                  <li>
                    Data platform of scale ~10TB to handle 100k+ customers, ~50M
                    transactions.
                  </li>
                  <li>
                    Use cases covering BI, Reporting, Complex financial data
                    analysis, Real time access of data, Mobile device analysis,
                    Real time location tracking.
                  </li>
                  <li>
                    Various business units generating data. Synced data from all
                    to data platform and provided BI reports using Tableau to
                    all the internal stakeholders – Management, RM, External
                    Distributors.
                  </li>
                  <li>
                    Monthly Customer Portfolio reports scheduled, generated and
                    emailed as attachment from Data platform
                  </li>
                </ul>
                <div className="text-center ">
                  <img className="images" alt="BIImage" src={BIImage1} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="useCase-2">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text ">
                  Data Platform for Online Travel Business
                </div>
                <div className="gradient-title-use-case mb-20">
                  Leading Travel Business: Data Platform for Analytics &amp;
                  <br />
                  BI (UAE)
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  One of the largest Online Travel, Hotel and Flight Booking
                  Platform in UAE.
                </p>
                <div className="gradient-title heading-22">Objective</div>
                <p>
                  Deliver KPI Dashboards by implementing a Data Lake inline with
                  long term goal for customer segmentation and targeted
                  marketing campaigns automation.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  Lack of one view of Customer Info and No consolidated data
                  view for products and services including reports.
                </p>
                <div className="gradient-title heading-22">Challenges</div>
                <p>
                  Multiple sources of unstructured data with no one data owner
                  and fixed requirement. Extensive Testing and Data Validation
                  for Quality Assurance.
                </p>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-20">
                  <li>
                    Building common connectors for identified
                    structured/semi-structured and unstructured data sources
                    using FilePull, Mongo Connector, FB Connector, GAd
                    connector, Adjust Connector.
                  </li>
                  <li>
                    Complete Configurable Control by changing JSON
                    transformation and adding new source account/campaign with
                    ETL workflow.
                  </li>
                  <li>
                    Scalable Data Platform Architecture using Hadoop, HDFC,
                    Hive, mySQL for data storage, transformation, data
                    aggregation and complex KPI calculations using Map Reduce.
                  </li>
                  <li>
                    Rich KPI Dashboards with very strong visualizations and
                    widgets for key stakeholders – Management, Operations and
                    Sales/Marketing teams.
                  </li>

                  
                  <li>
                    Strong Monitoring of scheduled and real-time ETL processes
                    and data ingestion pipelines.
                  </li>
                  <li> Selenium based Test Automation for data validation.</li>
                  <li>
                    Two generations of the platform – 1st implemented On premise
                    and next migrated and implemented on AWS.
                  </li>
                </ul>
                <div className="text-center ">
                  <img className="images" alt="BIImage" src={BIImage2} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
    </WrapComponent>
  );
};
export default memo(BIAnalytics);
