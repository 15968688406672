import React, { memo } from "react";
//import client1 from "../../assets/img/client-photo-1.png";
import client2 from "../../assets/img/client-photo-2.png";
import client3 from "../../assets/img/client-photo-3.png";
import client4 from "../../assets/img/client-photo-4.png";
import client5 from "../../assets/img/client-photo-5.png";
import client6 from "../../assets/img/client-photo-6.png";

const ClientSection = () => {
  return (
    <>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title mb-15">Client Speaks!!</div>
          </div>
              <section className="testimonials">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div id="customers-testimonials" className="owl-carousel">
                        <div className="item">
                          <div className="shadow-effect">
                            <img className="img-circle" src={client2} alt="" />
                            <div className="testi-scroll scroll_text_show mb-15">
                              <p>
                                <i>
                                  “PieEye is working with EPI for more than 18
                                  months. The EPI team has consistently demonstrated
                                  their dedication and hard work, and the results we
                                  have achieved together have been outstanding. It
                                  is a pleasure to work with such a skilled and
                                  reliable group of professionals
                                </i>
                              </p>
                              <p>
                                <i>
                                  {" "}
                                  PieEye is grateful for the strong partnership that
                                  we have developed. It truly feels like we are part
                                  of a family, working towards a common goal and
                                  supporting each other every step of the way
                                </i>
                              </p>
                              <p>
                                <i>
                                  {" "}
                                  I have no doubt that our partnership will continue
                                  to be a success, and I look forward to the
                                  opportunities and challenges that lie ahead. Thank
                                  you for your unwavering commitment to excellence”
                                </i>
                              </p>
                            </div>
                            <div className="client-name">Ofer Hermoni</div>
                            <div className="client-designation">
                              Co-Founder and CTO | PieEye
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="shadow-effect">
                            <img className="img-circle" src={client4} alt="" />
                            <div className="testi-scroll scroll_text_show mb-15">
                              <p>
                                <i>
                                  “I have partnered with Extrapreneurs on multiple
                                  assignments pretty much since the time that they
                                  were founded. I have worked with them in staff
                                  augmentation as well as in managed service
                                  delivery engagements and they have been effective
                                  in both models. They have demonstrated delivery
                                  competence in the .NET as well as the Java tech
                                  stack. They are a flexible organization with a
                                  customer-centric ethos. They boast a very seasoned
                                  and technically capable senior leadership team.
                                  They are able to work effectively on off-shore
                                  delivery assignments for USA as well as for
                                  near-shore assignments in India and the Middle
                                  East"
                                </i>
                              </p>
                            </div>
                            <div className="client-name">Umesh Marthi</div>
                            <div className="client-designation">
                              CTO | ODeX India Solutions Pvt. Ltd.
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="shadow-effect">
                            <img className="img-circle" src={client5} alt="" />
                            <div className="testi-scroll scroll_text_show mb-15">
                              <p>
                                <i>
                                  {" "}
                                  “Dear EPI Team, <br />
                                  Thank you for the quality of service provided by
                                  your company. We sincerely appreciate your
                                  efficient, gracious customer service, the level of
                                  detail and accountability you have demonstrated on
                                  each project, and the way you conduct business as
                                  a whole.
                                </i>
                              </p>
                              <p>
                                <i>
                                  {" "}
                                  We have, and will continue to, recommend your
                                  services to other companies and contacts. Our team
                                  could not be more satisfied with your work, and we
                                  look forward to continuing this relationship.{" "}
                                  <br />
                                  All the best"
                                </i>
                              </p>
                            </div>
                            <div className="client-name">Apurva Parekh </div>
                            <div className="client-designation">
                              {" "}
                              CTO | CIEL/FINX
                            </div>
                          </div>
                        </div>
                        <div className="item ">
                          <div className="shadow-effect">
                            <img className="img-circle" src={client3} alt="" />
                            <div className="testi-scroll scroll_text_show mb-15">
                              <p>
                                <i>
                                  “Our partnership with Extrapreneurs is
                                  multifaceted. The FIINFRA platform (for financial
                                  intermediaries) is architected, developed and
                                  supported by Extrapreneurs. Their business centric
                                  and consultative approach has been a great value
                                  for us. The highly passionate and dedicated team
                                  is helping us remain ahead in the market place”
                                </i>
                              </p>
                            </div>
                            <div className="client-name">Sumit Vaidya</div>
                            <div className="client-designation">CEO | FIINFRA</div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="shadow-effect">
                            <img className="img-circle" src={client6} alt="" />
                            <div className="testi-scroll-2 scroll_text_show mb-15">
                              <p>
                                <i>
                                  “Extrapreneurs has been a trusted partner for Core
                                  Integra since 2015. They have contributed in
                                  architecture designing and developing the product
                                  for Core Integra. EPI’s leadership team comes with
                                  a vast experience in the domain and provide
                                  customized solutions based on our requirements.”
                                </i>
                              </p>
                            </div>
                            <div className="client-name">Sandesh Chitnis</div>
                            <div className="client-designation">
                              {" "}
                              Chief Executive Officer | Core Integra
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </>
  );
};

export default memo(ClientSection);
