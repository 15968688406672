import React, { memo } from "react";
import image1 from "../../assets/img/bi-img-2.png";
import image2 from "../../assets/img/services-img-1.png";
import diagram1 from "../../assets/img/digram-1.png";
import diagram2 from "../../assets/img/digram-2.png";
import image7 from "../../assets/img/img-7.png";
import image4 from "../../assets/img/img-2.png";
import image5 from "../../assets/img/img-5.png";
import image6 from "../../assets/img/service-img-2.png";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";
import WrapComponent from "../Common/WrapComponent";
import WithToggle from "../Common/WithToggle";

// import { useNavigate } from "react-router-dom";

const PlatformDevelopment = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <div className="services-banner-section-1 text-center">
        <div className="banner-text">
          <div className="banner-gradient-title mb-15">
            Platform Development &amp;
            <br /> Digital Transformation
          </div>
          <div className="breadcrumb-links ">
            {/* <a
              onClick={() => {
                navigate("/");
              }}
              href=""
            >
              Home
            </a> */}
            <Link to="/" onClick={() => {}}>
              Home
            </Link>{" "}
            <span className="font-45B0DC">
              / Platform Development &amp; Digital Transformation
            </span>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-6 comman-mb">
            <img className="images" alt="backImage" src={image2} />
          </div>
          <div className="col-md-6">
            <div className="mb-10">
              <p>
                Our Platform development and digital transformation services
                encompasses the process of leveraging a rich stack of
                technologies to deliver cutting edge cloud native and Hybrid
                digital platforms for B2B, B2C, B2B2C and Marketplace business
                models. The key tenants of our Platform delivery framework
                covers:
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <ul className="list-content">
                  <li>Security </li>
                  <li>Dynamic Scalablity</li>
                  <li>High Throughput</li>
                  <li className="initial">Real Time Event Based Processing</li>
                  <li>Straight Through Processing</li>
                  <li className="initial">
                    Adherence to Applicable Compliance e.g. PCI-DSS, HIPAA, GDPR
                  </li>
                  <li>Workflow Automation</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-content">
                  <li>Insightful Analytics</li>
                  <li className="initial">
                    Seamless Integration with Legacy Systems
                  </li>
                  <li>Integrated Deployments</li>
                  <li className="initial">Integrated Monitoring and Alerts</li>
                  <li>Test Automation</li>
                  <li>Load Based Infra Usage</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12 comman-mb">
              <div className="gradient-title text-center">
                Our Digital Platform Layers
              </div>
              <div className="text-center">
                <img className="images" alt="diagram" src={diagram1} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className="gradient-title text-center custom-text-center">
              Technical Highlights of the Digital Platforms we have Implemented
            </div>
            <ul className="list-content-7 mobile-list-content">
              <li>Cloud native and Hybrid deployments.</li>
              <li>Web Apps: Responsive and PWA.</li>
              <li>Mobile Apps: Hybrid and Native.</li>
              <li>Container based micro services.</li>
              <li>SQL and NoSQL databases.</li>
              <li className="initial">
                Message Brokers for queue based processing.
              </li>
              <li>Streams for real time data processing.</li>
              <li>Data Caching for performance.</li>
              <li className="initial">
                Big Data, Data Lake, Lakehouse, Data warehouse, Datamart
                implementations.
              </li>
              <li>Object Stores.</li>
              <li>Content store and caching.</li>
              <li>BI and Analytics.</li>
              <li>AI and ML models.</li>
              <li className="initial">
                Devops for CI/CD automation and monitoring.
              </li>
              <li>Batch processing.</li>
              <li>REST APIs services layer and integration.</li>
              <li>AD/SAML integration/SSO integration.</li>
              <li>OWASP security.</li>
              <li className="initial">
                Serverless and container based deployments.
              </li>
              <li>RPA solutions.</li>
              <li>
                Seamless Integration with complimentary cloud platforms e.g.
                SSO, CRM, Accounting, ERP.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12 comman-mb mb-0">
              <div className="gradient-title text-center">
                A Typical Digital Platform Functional Architecture
              </div>
              <div className="text-center">
                <img className="images" alt="diagram" src={diagram2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className="gradient-title text-center custom-text-center mb-20">
              Our Customers have accrued multiple benefits by virtue of
              operating these Digital Platforms
            </div>
            <div className="heading-20 text-center mb-15">
              The key ones include:
            </div>
            <ul className="list-content-6">
              <li>
                Increased customer engagement using Omni channel experiences.
              </li>
              <li>Expanded sales volumes and revenue share by customer.</li>
              <li>Reduced TAT to launch new products and services.</li>
              <li>
                Increased operational efficiency across all key processes from
                sales, service, back-office and support.
              </li>
              <li>
                Reduced cost by cutting down on manual workforce required for
                catering to increasing business volumes.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gray-bg-section">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="gradient-title text-center custom-text-center mb-20">
                Our Digital Platform Tech Stack Covers the Following
                Technologies
              </div>
              <ul className="list-content-7 mobile-list-content">
                <li>
                  <div className="bullet-title">Cloud Infrastructure</div>
                  AWS, Azure, GCP, Private Clouds
                </li>
                <li className="initial">
                  <div className="bullet-title">
                    Core Languages for implementing Microservices
                  </div>
                  Python, NodeJS, Java, Go
                </li>
                <li className="initial">
                  <div className="bullet-title ">Web Apps</div>
                  React, Typescript, Javascript, HTML5, Flutter
                </li>
                <li>
                  <div className="bullet-title">Mobile Apps</div>
                  React Native, Android, iOS
                </li>
                <li className="initial">
                  <div className="bullet-title">Databases</div>
                  PostgresSQL, mySQL, SQL Server, MongoDB, DynamoDB
                </li>
                <li>
                  <div className="bullet-title">Caching</div>
                  Redis, Memcache
                </li>
                <li>
                  <div className="bullet-title">Message Brokers</div>
                  RabbitMQ, SQS
                </li>
                <li>
                  <div className="bullet-title">Streaming</div>
                  Kafka, Spark
                </li>
                <li>
                  <div className="bullet-title">API Gateway</div>
                  Kong, Zuul, AWS API Gateway
                </li>
                <li>
                  <div className="bullet-title">Containers</div>
                  ECS, EKS, Docker, Kubernetes
                </li>
                <li className="initial">
                  <div className="bullet-title">Test Automation</div>
                  Selenium, Appium, jMeter, Postman Collection, Saucelabs
                </li>
                <li>
                  <div className="bullet-title">Devops Automation</div>
                  GitHub, Bitbucket, Jenkins, Maven, Ansible, Docker, Terraform,
                  AWS Code Pipeline
                </li>
                <li>
                  <div className="bullet-title">Devops Monitoring</div>
                  Slack, Sentry, ELK, Nagios, Prometheus, New Relic
                </li>
                <li>
                  <div className="bullet-title">BI Tools</div>
                  Tableau, Domo, Power BI, Metabase
                </li>
                <li>
                  <div className="bullet-title">Big Data </div>
                  Hadoop, Hive, AWS EMR, Redshift, Athena, AWS S3
                </li>
                <li>
                  <div className="bullet-title">Object Stores</div>
                  AWS S3, Azure Blob
                </li>
                <li>
                  <div className="bullet-title">Data Engineering</div>
                  Oozie, Airflow, Dbt, Talend, Sqoop, Spark
                </li>
                <li>
                  <div className="bullet-title">Search Engines</div>
                  Elastic Search
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main-bg-section-4">
        <div className="main-section">
          <div className="custom-text-center">
            <div className="gradient-title">Use Cases</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card-box-2 mb-0 comman-mb">
                <img alt="backImage" src={image7} width="100%" />
                <div className="padding-20">
                  <div className="small-text">banking and finance</div>
                  <div className="card-content-4">
                    Our Platform Implementation Framework: THOR
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#successStory-1"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-2 mb-0 comman-mb">
                <img alt="backImage" src={image4} width="100%" />
                <div className="padding-20">
                  <div className="small-text">print and publishing</div>
                  <div className="card-content-4">
                    Print &amp; Publishing and Digital Transformation Platform
                    (USA)
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#successStory-2"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box-2 mb-0 ">
                <img alt="backImage" src={image5} width="100%" />
                <div className="padding-20">
                  <div className="small-text">
                    DATA PLATFORM FOR ONLINE TRAVEL BUSINESS
                  </div>
                  <div className="card-content-4">
                    Leading Travel Business: Data Platform for Analytics &amp;
                    BI (UAE)
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#successStory-3"
                  >
                    Keep Reading <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ..........................................modal start...................................... */}

      <WithToggle id="successStory-1">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Banking and Finance</div>
                <div className="gradient-title-use-case mb-20">
                  Our Platform Implementation Framework: THOR
                </div>
                <div className="gradient-title heading-22">About THOR</div>
                <ul className="list-content mb-15">
                  <li>
                    THOR provides pre-built platform API based service
                    components that are needed to build, implement and operate
                    Business Platforms.
                  </li>
                  <li>
                    Built using open source tools and software for enterprise
                    scale and security to run B2B, B2B2C and B2C businesses with
                    in-built multi-tenancy features.
                  </li>
                </ul>
                <div className="gradient-title heading-22">Objective</div>
                <ul className="list-content mb-15">
                  <li>
                    To use the delivery component as an accelerator for platform
                    building.
                  </li>
                  <li> Ability to use it across various business domains.</li>
                </ul>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Business platform needs standard features like security,
                    authentication, audit, logging, notification etc. which
                    takes up a lot of upfront effort to build.
                  </li>
                  <li>Reusability and centralization of common features.</li>
                </ul>
                <div className="gradient-title heading-22">Challenges</div>
                <ul className="list-content mb-15">
                  <li>
                    High degree of functional / product roadmap ownership.
                  </li>
                  <li>
                    High degree of configurability and flexibility to customize
                    yet very simple to implement.
                  </li>
                  <li>
                    In-built multi-tenancy, localization, multi-currency,
                    multi-language support.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    It provides a productivity leverage of more than 1000+
                    person months of effort.
                  </li>
                  <li>Scalable, Secure, Service Based, Maintainable, ...</li>
                  <li>Build and deploy multiple Business Apps.</li>
                  <li>
                    All Apps works as services and can be dynamically scaled to
                    cater to concurrent user load.
                  </li>
                  <li>Centralized Management and Monitoring.</li>
                  <li>
                    Strong Adherence to Best Design Principles, Practices and
                    Patterns.
                  </li>
                  <li>
                    Services built and deployed using proven industry standard
                    best practices.
                  </li>
                  <li>
                    Flexi Deployment (Single to Multi-server scalability).
                  </li>
                </ul>
                <div className="text-center">
                  <img alt="backImage" className="images " src={image2} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}

      <WithToggle id="successStory-2">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Print and Publishing</div>
                <div className="gradient-title-use-case mb-20">
                  Print &amp; Publishing and Digital Transformation Platform
                  (USA)
                </div>
                <div className="gradient-title heading-22">
                  About Organization
                </div>
                <ul className="list-content mb-15">
                  <li> Fortune 2000 organization.</li>
                  <li>3 billion US$ revenue.</li>
                  <li>Traditional Print and Publishing industry in US.</li>
                </ul>
                <div className="gradient-title heading-22">Objective</div>
                <ul className="list-content mb-15">
                  <li>
                    Re-position from traditional print and publishing only
                    player to multi channel communication player.
                  </li>
                  <li> Provide a new Go To Market offering.</li>
                  <li>
                    Integrate and Operate a platform with best of breed niche
                    functional solutions.
                  </li>
                  <li>
                    Speed of response and operate as a start up within a large
                    organizational setup.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Configurable &amp; customizable Storefronts aligned to B2B
                    and B2C models.
                  </li>
                  <li>
                    Multi-channel capabilities: Direct Mail, Web, Packaging,
                    Labels, Envelopes, Print, Digital.
                  </li>
                  <li>
                    Seamless Integration with internal and external service
                    providers.
                  </li>
                </ul>
                <div className="gradient-title heading-22">Challenges</div>
                <ul className="list-content mb-15">
                  <li>
                    Working as one team across 5 time zones and owning the
                    technology platform.
                  </li>
                  <li>Built the first version of the platform in 3 months.</li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Best of Breed solutions integrated into one cohesive
                    platform using REST APIs and real-time processing.
                  </li>
                  <li>
                    Implemented a complex routing engine to push orders for
                    fulfilment to various plants across the US by integrating
                    into respective ERP.
                  </li>
                  <li>
                    Implemented a cloud based data warehouse and BI solution for
                    order tracking, KPI and TAT metrics and reporting.
                  </li>
                  <li>
                    Integrated the best of breed digital design composition
                    engines to automate the artwork creation, review and
                    approval workflows between customer and in-house design
                    teams.
                  </li>
                  <li>
                    Implemented multi-tenant (SaaS) store-fronts that can be
                    co-branded and white-labelled to each business with Single
                    Sign-on for merchandise ordering.
                  </li>
                  <li>
                    Migrated multiple legacy store-fronts to the new
                    store-fronts and sunset them seamlessly.
                  </li>
                </ul>
                <img
                  alt="backImage"
                  className="images desk-images "
                  src={image6}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
      {/* ..........................................modal start...................................... */}
      <WithToggle id="successStory-3">
        <>
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  DATA PLATFORM FOR ONLINE TRAVEL BUSINESS
                </div>
                <div className="gradient-title-use-case mb-20">
                  Leading Travel Business: Data Platform for Analytics &amp;
                  <br /> BI (UAE)
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  One of the largest Online Travel, Hotel and Flight Booking
                  Platform in UAE.
                </p>
                <div className="gradient-title heading-22">Objective</div>
                <p>
                  Deliver KPI Dashboards by implementing a Data Lake inline with
                  long term goal for customer segmentation and targeted
                  marketing campaigns automation.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <p>
                  Lack of one view of Customer Info and No consolidated data
                  view for products and services including reports.
                </p>
                <div className="gradient-title heading-22">Challenges</div>
                <p>
                  Multiple sources of unstructured data with no one data owner
                  and fixed requirement. Extensive Testing and Data Validation
                  for Quality Assurance.
                </p>
                <div className=" gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Building common connectors for identified
                    structured/semi-structured and unstructured data sources
                    using FilePull, Mongo Connector, FB Connector, GAd
                    connector, Adjust Connector.
                  </li>
                  <li>
                    Complete Configurable Control by changing JSON
                    transformation and adding new source account/campaign with
                    ETL workflow.
                  </li>
                  <li>
                    Scalable Data Platform Architecture using Hadoop, HDFC,
                    Hive, mySQL for data storage, transformation, data
                    aggregation and complex KPI calculations using Map Reduce.
                  </li>
                  <li>
                    Rich KPI Dashboards with very strong visualizations and
                    widgets for key stakeholders – Management, Operations and
                    Sales/Marketing teams.
                  </li>
                  <li>
                    Strong Monitoring of scheduled and real-time ETL processes
                    and data ingestion pipelines.
                  </li>
                  <li> Selenium based Test Automation for data validation.</li>
                  <li>
                    Two generations of the platform – 1st implemented On premise
                    and next migrated and implemented on AWS.
                  </li>
                </ul>
                <div className="text-center ">
                  <img alt="backImage" className="images" src={image1} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      </WithToggle>
      {/* ..........................................modal end...................................... */}
    </WrapComponent>
  );
};

export default memo(PlatformDevelopment);
