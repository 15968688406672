import WrapComponent from "../Common/WrapComponent";

import diagram21 from "../../assets/img/digram-21.png";
import printAndPublishImage from "../../assets/img/print-publishing-img.png";
import WithToggle from "../Common/WithToggle";
// import { useNavigate } from "react-router-dom";
import { memo } from "react";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const PrintAndPublish = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="industries-banner-section-4 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Print &amp; Publishing
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="font-45B0DC"> / Print &amp; Publishing</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="print and publish"
                src={printAndPublishImage}
              />
            </div>
            <div className="col-md-6">
              <p>
                The traditional business of the Print and Publishing industries
                is the mass production and distribution of information, in the
                form of posters, labels, envelops, cards, books, periodicals,
                newspapers and other printed matter. Print and publishing
                encompasses the production of printed material and related
                services, such as graphic design, labels business, envelops,
                postcards, etc. It includes the production of posters, labels,
                business cards, journals, and other printed materials, as well
                as electronic publishing services. The industry is highly
                competitive and there are a wide variety of services and
                products available. Companies that specialize in print and
                publishing may offer services such as layout and design,
                typesetting, printing, binding, and distribution. They may also
                provide digital publishing services, including digital printing,
                web-based publishing, etc.
              </p>
              <p>
                Labels and Envelope printing services are offered digitally to
                B2B and B2C customers. Typically, the following systems are
                required to implement the end to end supply chain
              </p>
              <ul className="list-content mb-0">
                <li>eCommerce with shopping carts</li>
                <li> Online Payments</li>
                <li> Order Management</li>
                <li>
                  Digital and Print media creation, customization and
                  personalization at scale
                </li>
                <li> Order Routing to ERP systems for fulfilment</li>
                <li>CRM for pre and post sales support</li>
                <li>Analytics to measure TAT overall and at every step</li>
                <li>Integration with Shipping Service Providers</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <p className="gradient-title custom-text-center ">
                  The Table below Illustrates the Popular Solutions used in this
                  Domain
                </p>
                <table className="table table-bordered mb-0 bg-white">
                  <tbody>
                    <tr>
                      <th scope="row">eCommerce</th>
                      <td>NopCommerce, AccuConnect, WooCommerce</td>
                    </tr>
                    <tr>
                      <th scope="row">Digital and Print Media Creation</th>
                      <td>Adobe Indesign, Chili Publish, XMPie</td>
                    </tr>
                    <tr>
                      <th scope="row">Payments</th>
                      <td>Authorize.net, CyberSource, PayPal, 3DSi</td>
                    </tr>
                    <tr>
                      <th scope="row">Order Management</th>
                      <td>SAP, Hagen, Prinergy</td>
                    </tr>
                    <tr>
                      <th scope="row">Shipping Management</th>
                      <td>USPS (Endicia), FedEx, UPS</td>
                    </tr>
                    <tr>
                      <th scope="row">Analytics</th>
                      <td>Domo, AWS EMR, Amazon Redshift, Cognos, Spotfire</td>
                    </tr>
                    <tr>
                      <th scope="row">CRM</th>
                      <td>Salesforce, Zoho, Kentico</td>
                    </tr>
                    <tr>
                      <th scope="row">Integration Tools</th>
                      <td>Tibco, Mulesoft, REST APIs, Talend ETL, Vertex</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-3">
          <div className="main-section">
            <div className="custom-text-center mb-30">
              <div className="gradient-title">Use Cases</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box mb-0 comman-mb">
                  <div className="small-text">Use Case 1</div>
                  <div className="card-content-4">
                    About the Company: Customer is a leading North American
                    manufacturer of envelopes with over 100 years of experience
                    and approximately 30 billion envelopes manufactured
                    annually. It is engaged in the manufacture of various
                    print-related products. The company's products and services
                    include printed labels,
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <WithToggle id="useCase-1">
          <>
            <div className="modal-dialog modalDialog" role="document">
              <div className="modal-content">
                <div className="modal-header modalHeader">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modalBody">
                  <div className="small-text">Use Case 1</div>
                  <div className="gradient-title heading-22">
                    About the Company
                  </div>
                  <p>
                    Customer is a leading North American manufacturer of
                    envelopes with over 100 years of experience and
                    approximately 30 billion envelopes manufactured annually. It
                    is engaged in the manufacture of various print-related
                    products. The company's products and services include
                    printed labels, packaging and digital print products, print
                    magazine and books, mailing and creative services, and
                    inventory and warehouse management software.
                  </p>
                  <p>
                    Team of over 2,000 employees is focused on delivering
                    quality products and service to some of the world’s most
                    recognized brands. Customer operates 14 facilities
                    nationwide with a commitment to producing world-class
                    products that foster a more sustainable future.
                  </p>
                  <div className="gradient-title heading-22">
                    Problem Statement
                  </div>
                  <ul className="list-content mb-20">
                    <li>
                      The technology used to produce content was legacy
                      technology – a mix of internal and vendor-supplied tools.
                    </li>
                    <li>
                      Frustrated by difficult collaboration across U.S., Europe,
                      and Asia-Pacific teams – used to slow down new content
                      production.
                    </li>
                    <li>
                      CEO was complaining about lack of ability to get new
                      products to market faster – competitors consistently beat
                      them to market.
                    </li>
                    <li>
                      The data was scattered and not in place to analyse
                      anything. Data analysis was needed to make better business
                      decisions.
                    </li>
                  </ul>
                  <div className="gradient-title heading-22">
                    Solution Implementation
                  </div>
                  <p>
                    We developed and implemented "Kadena" as a eCommerce SaaS
                    solution with Integrated Supply Chain to address the
                    challenges and limitations as stated above.
                  </p>
                  <p>
                    Kadena is an agile content supply chain solution, using an
                    integrated set of tools and capabilities configured to fit
                    the unique needs of the organization.
                  </p>
                  <ul className="list-content mb-15">
                    <li>
                      Expert content supply chain management capabilities.
                    </li>
                    <li>
                      Innovative technologies, without major IT investments.
                    </li>
                    <li>
                      Exceptional agility in content production, fulfillment
                      &amp; distribution.
                    </li>
                    <li>
                      Improved supply chain performance insights and cost
                      efficiencies.
                    </li>
                    <li>Faster product rollout for time-to-market.</li>
                  </ul>
                  <p>
                    Used CHILI-Publish from digital media templates creation.
                    Chili-Publish platform helps to design and develop templates
                    which are dynamic in nature, which are more creative,
                    scalable and output in graphical production.
                  </p>
                  <p>
                    Customer’s Label, Envelop, Poster, Postcard, Letter and
                    other type of products businesses benefited from a template
                    produced in Chili-Publish.
                  </p>
                  <div className="text-center ">
                    <img
                      className="images desk-images"
                      alt="diagram21"
                      src={diagram21}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </>
        </WithToggle>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(PrintAndPublish);
