const Global_var = {
  BASEURL: "https://mllwy4k7sc.execute-api.ap-south-1.amazonaws.com/prod",
  URL_GET_TOKEN: "https://mllwy4k7sc.execute-api.ap-south-1.amazonaws.com/v1/generatetoken",
  URL_SUBMIT_QUERY : "/Lambda_epiwebsite/submit_query",
  URL_GET_JOB_LIST : "/Lambda_epiwebsite/get_job",
  URL_APPLY_JOB : "/Lambda_epiwebsite/apply_job",
  STRAPI_GET_TOKEN: "2d0bd39e4adcc34300a319ec9d3cc287b9be51e07ace2ee2d8dfbe2af4a6fa1ea322ed512b23a7a03495448d1bc727e774a681a1a7fa43c31852b23bd1607b8576a9172fadd22a9e0720f034a852f23bc1eabe881f4b4720a6976fa27aa3d967023f98679b03c2438eda69b5582dec3c3dcd2773172ff52c1d2f0fd0687834f3",
  STRAPI_POST_TOKEN: "a62b5f46574be558d3f75b759e048923fa12c8039f0b4e7f9cd71a4795bb3adba8afb3e6df8f8c9cdbe0151727fe1c485951581c3721360ec5705bee56829f3a95dd7b19bd86d251eb9dcc5dac5624d102032b8648da604debd4e3c68a703058bb051baac2ba8a583c1a01dc801771891a03a43874127e4c6abf9357251f3795",
  STRAPI_EC2_URL: "13.126.34.73",
  STRAPI_EC2_PORT: "1337",
  // STRAPI_EC2_URL_FULL: "beta.extrapreneursindia.com/strapi",
  STRAPI_EC2_URL_FULL: "api.extrapreneursindia.com:3311",
  G_CAPTCHA_SCE_KEY: "6LcPEQAoAAAAAEdq_ZG0D-dhfBe_bgPM3-JCyix7",
  // STRAPI_EC2_URL: "localhost",
  EPI_API_BASE:{
    'BETA':"https://api.extrapreneursindia.com:3311",
    // 'BETA':"https://beta.extrapreneursindia.com/reCAPTCHA",
    'PROD':"https://api.extrapreneursindia.com:3311",
    'DEV':"http://localhost:3311",
  },
  CAPTCHA_VERIFY_URL: "/verify"
}
export default Global_var;
