// Inside Home.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
// import avtar from "./user-avatar.png";
// import './blog-style.css';
// import '../../../public/assets/css/blohStyle.css';
import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import ReCAPTCHA from "react-google-recaptcha";
import Global_var from "../../Global/global_var";
import { InfinitySpin } from  'react-loader-spinner'

const Home = () => {
  const [isList, setIsList] = useState(true);
  const [posts, setPosts] = useState([]); //posts
  const [rPosts, setRPost] = useState([]); //recent posts
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //eslint-disable-next-line 
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [postId, setPostId] = useState(0);
  const getToken = Global_var.STRAPI_GET_TOKEN;
  const postToken = Global_var.STRAPI_POST_TOKEN;
  // const configPost = {
  //   headers: { Authorization: `Bearer ${postToken}` }
  // };
  // const configGet = {
  //   headers: { Authorization: `Bearer ${getToken}` }
  // };

  const initialValues = {
    name: "",
    // email: "",
    // website: "",
    message: "",
    connection: "",
  };
  
  const [ipAddress, setIPAddress] = useState('')
  // const [blogloading, blogSetLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);


  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);


  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5", { action: "homepage" })
        .then(token => {
          // ...
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5"
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, []);

  const gre = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LcPEQAoAAAAAKNRXsF-xrw4NQT_Ipax3D3c-tZ5', { action: 'submit' }).then(function (token) {
        console.log(token);

        const secretKey = Global_var.G_CAPTCHA_SCE_KEY;
        const userIp = ipAddress;
        //const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}&remoteip=${userIp}`;
        //console.log("URL", url)
        axios.post(Global_var.EPI_API_BASE.PROD + Global_var.CAPTCHA_VERIFY_URL, {
          'secret': secretKey,
          'response': token,
          'remoteip': userIp,
          headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*"
          }
        }).then((res)=>{
          console.log(res);
          console.log(res.data);
          if(res.data.success){
            // alert("Success")
            // setCaptchaValue("Done");
            const { name, message} = values;
        const current = new Date();
        const month = `${current.getMonth()+1}`<=10 ? `${current.getMonth()+1}`.toString().padStart(2, '0') : `${current.getMonth()+1}`;
        const day = `${current.getDate()}`<=10 ? `${current.getDate()}`.toString().padStart(2, '0') : `${current.getDate()}`;
        const year = `${current.getFullYear()}`;
        // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
        const date = `${year}-${month}-${day}`;
        // console.log(values)
        // console.log(event);
        // console.log(name);
        // console.log(message);
        // console.log(postId);
        // console.log(date);
        // debugger;
        if (!name) {
          toast.error("Please enter the first name", {position: toast.POSITION.BOTTOM_RIGHT});
          // <ToastContainer />
          return;
        }
        // if (!captchaValue) {
        //         toast.error("Failed to varify captcha please reload the page", {position: toast.POSITION.BOTTOM_RIGHT});
        //         // <ToastContainer />
        //         return;
        //       }
        const addComments = async () => {
          try {
            const data = {
                "reply": message,
                "name": name,
                "post_id": postId,
                "date": date,
                "token": postToken
            };
            // const response = await axios.post(
            //   `http://localhost:1337/api/comments`, data,
            // );
            // const response = await axios.post(
            //   `http://${Global_var.STRAPI_EC2_URL}:${Global_var.STRAPI_EC2_PORT}/api/comments`, data, configPost,
            // );
            const response = await axios.post(
              `https://${Global_var.STRAPI_EC2_URL_FULL}/addcomment`, data,
            );
            console.log("DATA....", response.data.data);
            setCommentLoading(false);
            toast.success("Comment posted successfully", {position: toast.POSITION.BOTTOM_RIGHT})
            document.getElementById('name').value = ""
            document.getElementById('message').value = ""
            //eslint-disable-next-line 
            {
              const getCommentData = {
                  "limit": 100,
                  "start": 0,
                  "token": getToken
              };
              axios
                .post(`https://${Global_var.STRAPI_EC2_URL_FULL}/getpost`,getCommentData)
                // .get(`http://${Global_var.STRAPI_EC2_URL}:${Global_var.STRAPI_EC2_PORT}/api/posts?populate=*`,configGet)
                // .get(`http://localhost:1337/api/posts?populate=*`)
                .then((response) => {
                  //eslint-disable-next-line 
                  const data = (response.data.data || []).filter((x) => {
                    if (x.id === postId) {
                      return x;
                    }
                  });
                  console.log("blog data --->", data);
                  setPost(data[0].attributes);
                  console.log("data->", response.data.data.attributes);
                  setComments(data[0].attributes.comments.data || []);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
        setValues("");
          } catch (error) {
            console.error(error);
          }
        };
        addComments();

          }
          else{
            toast.error("Failed to varify captcha please reload the page", {position: toast.POSITION.BOTTOM_RIGHT})
            alert("Failed to varify captcha please reload the page")
            window.location.reload();
            return
          }
        })
        // Add your logic to submit to your backend server here.
    //     const { name, message} = values;
    //     const current = new Date();
    //     const month = `${current.getMonth()+1}`<=10 ? `${current.getMonth()+1}`.toString().padStart(2, '0') : `${current.getMonth()+1}`;
    //     const day = `${current.getDate()}`<=10 ? `${current.getDate()}`.toString().padStart(2, '0') : `${current.getDate()}`;
    //     const year = `${current.getFullYear()}`;
    //     // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
    //     const date = `${year}-${month}-${day}`;
    //     // console.log(values)
    //     // console.log(event);
    //     // console.log(name);
    //     // console.log(message);
    //     // console.log(postId);
    //     // console.log(date);
    //     // debugger;
    //     if (!name) {
    //       toast.error("Please enter the first name", {position: toast.POSITION.BOTTOM_RIGHT});
    //       // <ToastContainer />
    //       return;
    //     }
    //     if (!captchaValue) {
    //             toast.error("Failed to varify captcha please reload the page", {position: toast.POSITION.BOTTOM_RIGHT});
    //             // <ToastContainer />
    //             return;
    //           }
    //     const addComments = async () => {
    //       try {
    //         const data = {
    //           "data": {
    //             "replay": message,
    //             "name": name,
    //             "post": postId,
    //             "date": date
    // }
    //         };
    //         // const response = await axios.post(
    //         //   `http://localhost:1337/api/comments`, data,
    //         // );
    //         const response = await axios.post(
    //           `http://${Global_var.STRAPI_EC2_URL}:1337/api/comments`, data, configPost,
    //         );
    //         console.log("DATA....", response.data.data);
    //         toast.success("Comment posted successfully", {position: toast.POSITION.BOTTOM_RIGHT})
    //         document.getElementById('name').value = ""
    //         document.getElementById('message').value = ""
    //         //eslint-disable-next-line 
    //         {
    //           axios
    //             .get(`http://${Global_var.STRAPI_EC2_URL}:1337/api/posts?populate=*`,configGet)
    //             // .get(`http://localhost:1337/api/posts?populate=*`)
    //             .then((response) => {
    //               //eslint-disable-next-line 
    //               const data = (response.data.data || []).filter((x) => {
    //                 if (x.id === postId) {
    //                   return x;
    //                 }
    //               });
    //               console.log("blog data --->", data);
    //               setPost(data[0].attributes);
    //               console.log("data->", response.data.data.attributes);
    //               setComments(data[0].attributes.comments.data || []);
    //             })
    //             .catch((error) => {
    //               console.error(error);
    //             });
    //         }
    //     setValues("");
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    //     addComments();
      });
    });
  }


  const [values, setValues] = useState(initialValues);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };


  const oncancel = (e) => {
    document.getElementById('name').value = ""
    // document.getElementById('email').value = ""
    // document.getElementById('website').value = ""
    document.getElementById('message').value = ""
    setValues("");
    console.log(values);
  };
  // const onSubmit = (values) => {

  const onSubmit  = (event) =>{
    // debugger;
    setCommentLoading(true);
    console.log(event)
    let token = gre(event)
    console.log("Your token is", token)
//     const { name, message} = values;
//     const current = new Date();
//     const month = `${current.getMonth()+1}`<=10 ? `${current.getMonth()+1}`.toString().padStart(2, '0') : `${current.getMonth()+1}`;
//     const day = `${current.getDate()}`<=10 ? `${current.getDate()}`.toString().padStart(2, '0') : `${current.getDate()}`;
//     const year = `${current.getFullYear()}`;
//     // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
//     const date = `${year}-${month}-${day}`;
//     console.log(values)
//     // console.log(event);
//     // console.log(name);
//     // console.log(message);
//     // console.log(postId);
//     // console.log(date);
//     // debugger;
//     if (!name) {
//       toast.error("Please enter the first name", {position: toast.POSITION.BOTTOM_RIGHT});
//       // <ToastContainer />
//       return;
//     }
//     if (!captchaValue) {
//       toast.error("Please enter the first name", {position: toast.POSITION.BOTTOM_RIGHT});
//       // <ToastContainer />
//       return;
//     }
//     const addComments = async () => {
//       try {
//         const data = {
//           "data": {
//             "replay": message,
//             "name": name,
//             "post": postId,
//             "date": date
// }
//         };
//         // const response = await axios.post(
//         //   `http://localhost:1337/api/comments`, data,
//         // );
//         const response = await axios.post(
//           `http://${Global_var.STRAPI_EC2_URL}:1337/api/comments`, data, configPost,
//         );
//         console.log("DATA....", response.data.data);
//         toast.success("Comment posted successfully", {position: toast.POSITION.BOTTOM_RIGHT})
//         document.getElementById('name').value = ""
//         document.getElementById('message').value = ""
//         //eslint-disable-next-line 
//         {
//           axios
//             .get(`http://${Global_var.STRAPI_EC2_URL}:1337/api/posts?populate=*`,configGet)
//             // .get(`http://localhost:1337/api/posts?populate=*`)
//             .then((response) => {
//               //eslint-disable-next-line 
//               const data = (response.data.data || []).filter((x) => {
//                 if (x.id === postId) {
//                   return x;
//                 }
//               });
//               console.log("blog data --->", data);
//               setPost(data[0].attributes);
//               console.log("data->", response.data.data.attributes);
//               setComments(data[0].attributes.comments.data || []);
//             })
//             .catch((error) => {
//               console.error(error);
//             });
//         }
//     setValues("");
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     addComments();
  };  

  // const [captchaValue, setCaptchaValue] = useState(null);
  // const captchaRef = useRef(null);

  // function onChangeCaptcha(value) {
  //   console.log("Captcha value:", value);
  //   setCaptchaValue(value)
  //   console.log(captchaValue);
  //   // debugger;
  // }

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // const response = await axios.get(
        //   `http://localhost:1337/api/posts?_limit=${postsPerPage}&_start=${
        //     (currentPage - 1) * postsPerPage
        //   }`
        // );
        // const response = await axios.get(
        //   `http://${Global_var.STRAPI_EC2_URL}:${Global_var.STRAPI_EC2_PORT}/api/posts?_limit=${postsPerPage}&_start=${
        //     (currentPage - 1) * postsPerPage
        //   }`, configGet
        // );
        const getPostData = {
          "limit": 100,
          "start": 0,
          "token": getToken
      };
        const response = await axios.post(`https://${Global_var.STRAPI_EC2_URL_FULL}/getblogs`,getPostData);
        console.log("DATA....", response.data.data);
        setPosts(response.data.data);
        setRPost(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPosts();
    const fetchCategory = async () => {
      try {
        // const response = await axios.get(
        //   `http://localhost:1337/api/categories?populate=*`
        // );
        // const response = await axios.get(
        //   `http://${Global_var.STRAPI_EC2_URL}:${Global_var.STRAPI_EC2_PORT}/api/categories?populate=*`, configGet
        // );
        const getCategoriesData = {
          "limit": 100,
          "start": 0,
          "token": getToken
        };
        
        const response = await axios.post(`https://${Global_var.STRAPI_EC2_URL_FULL}/getcategories`,getCategoriesData);
        console.log("DATA....", response.data.data);
        setCategory(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategory();
    if (!isList) {
        const getCommentData = {
          "limit": 100,
          "start": 0,
          "token": getToken
        };
      axios
        // .get(`http://localhost:1337/api/posts?populate=*`)
        // .get(`http://${Global_var.STRAPI_EC2_URL}:${Global_var.STRAPI_EC2_PORT}/api/posts?populate=*`, configGet)
        .post(`https://${Global_var.STRAPI_EC2_URL_FULL}/getpost`,getCommentData)
        .then((response) => {
          //eslint-disable-next-line 
          const data = (response.data.data || []).filter((x) => {
            if (x.id === postId) {
              return x;
            }
          });
          console.log("blog data --->", data);
          setPost(data[0].attributes);
          console.log("data->", response.data.data.attributes);
          setComments(data[0].attributes.comments.data || []);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  //eslint-disable-next-line 
  }, [currentPage, postsPerPage, isList, postId]);

  const totalPages = Math.ceil(posts.length / postsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const postByCategory = (id) => {
    // debugger;
    // try {
    //   const response = await axios.get(
    //     `http://localhost:1337/api/posts?populate[category][populate]=${name}`
    //   );
    //   console.log("DATA....", response.data.data);
    //   setCategory(response.data.data);
    // } catch (error) {
    //   console.error(error);
    // }
    //eslint-disable-next-line 
    const postList = (category || []).filter((x) => {
      if (x.id === id) {
        return x;
      }
    });
    const postdata = postList[0].attributes.posts.data;
    console.log("Here", postdata);
    setPosts([]);
    setPosts(postdata);
  };

  return (
    <div className="site-header">
        <div className="banner-section-4 text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Blogs
              </div>
            </div>
          </div>
     
      <div className="blog-main-section ">
      <h3 className="smallGrayTitle" style={{ marginBottom:"50px", fontSize:"14px" }}>POSTS</h3>
      <div className="row" id="blogs">
        {isList ? (
          <div className="col-md-8">
            {(posts || []).map((post) => {
              return (
                <div key={post.attributes.id} className="review-card">
                  {post.id===1 ? (
                  <div className="smallGrayTitle">
                    POST BY {post.attributes.author_name} ON{" "}
                    {post.attributes.date}
                  </div>
                  ):(
                    <div className="smallGrayTitle" style={{ marginTop: "90px"}}>
                    POST BY {post.attributes.author_name} ON{" "}
                    {post.attributes.date}
                  </div>
                  )}
                  <Link className=" blogsTitle"
                    to={`/blog/${post.id}`}
                    // onClick={() => {
                    //   setPostId(post.id);
                    //   setIsList(false);
                    // }}
                  >
                    <ReactMarkdown>{post.attributes.title}</ReactMarkdown>
                  </Link>
                  <p>{post.attributes.content.substring(0, 200)}...</p>
                  <Link className="blogsTitle2"
                    to={`/blog/${post.id}`}
                    onClick={() => {
                      // setPostId(post.id);
                      // setIsList(false);
                    }}
                  >
                    Continue reading... <br/>
                  </Link>
                  
                </div>
              );
            })}
            <div style={{marginTop:"20px"}} className="text-right">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button key={index + 1} onClick={() => handlePageChange(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
          </div>
        ) : (
          <>
            {/* <div className="col-md-9 user-form" style={{ padding: "50px" }}> */}
            {/* <div className="col-md-9 img"> */}
            <div className="col-md-8 img blogsTitle">
              <div className="smallGrayTitle" style={{ marginTop: "5px"}}>
                {/* POST BY {post.author_name} ON {post.date} */}
                {/* {setValues({
                ...values,
                  ["connection"]: post.author_name,
                })} */}
                {post.date} BY {post.author_name}
              </div>
              <ReactMarkdown className="innerTitle">{post.title}</ReactMarkdown>
              <ReactMarkdown>{post.content}</ReactMarkdown>
              <h2 style={{ borderBottom: "1px solid #ccc", padding:"10px 0" }}>Comments</h2>
              <section style={{}}>
                {/* backgroundColor: "#e7effd"  */}
                <div className="container my-5 text-dark img-original img.user-form mb10">
                  {comments.map((comment) => (
                    <div key={comment.id}>
                      {" "}
                      <div className="row d-flex ">
                        {/* justify-content-center */}
                        <div className="col-md-11 col-lg-9 col-xl-7">
                          <div className="d-flex flex-start mb-4">
                            {/* <img
                              className="shadow-1-strong me-3 "
                              src={avtar}
                              alt="avatar"
                              style={{width: 40, height:40}}
                            /> */}
                            <div className="shadow-1-strong me-3 avtarimg avtar-img-space"></div>
                            <div className>
                                  <h5>{comment.attributes.name}</h5>
                                  <p className="small">
                                    {comment.attributes.date.substring(0, 10)}
                                  </p>
                                  <p>{comment.attributes.replay}</p>
                                  <div className="d-flex justify-content-between align-items-center">
                                    {/* <a href="#!" className="link-muted">
                                <i className="fas fa-reply me-1" /> Reply
                              </a> */}
                                  </div>
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              {/* --------comment post-------------------------------------------------- */}
              {/* <h4>Leave a Reply</h4> */}
              <h5>
                Your email address will not be published. Required fields are
                marked *
              </h5>

              {/* --------Input for comment post-------------------------------------------------- */}
              {/* <form> */}
              <div
                className=" py-3 border-0"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="d-flex flex-start w-100">
                  {/* <img
                    className=" shadow-1-strong me-3 "
                    src={avtar}
                    alt="avatar"
                    style={{width: 40, height:40}}
                  /> */}
                  <div className="shadow-1-strong me-3 avtarimg avtar-img-space"></div>
                  <div className="form-outline w-100">
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <input
                      value={values.name}
                      onChange={handleInputChange}
                      type="text"
                      className="form-control mb10"
                      id="name"
                      style={{ background: "#fff" }}
                      defaultValue={""}
                      label="name"
                      name="name"
                    />
                    <label className="form-label" htmlFor="textAreaExample">
                      Message
                    </label>
                    <textarea
                      value={values.message}
                      onChange={handleInputChange}
                      className="form-control mb10"
                      id="message"
                      rows={4}
                      style={{ background: "#fff" }}
                      defaultValue={""}
                      label="message"
                      name="message"
                    />
                    {/* <label for="exampleInputPassword1" class="required">
                      Captcha
                    </label>
                    <ReCAPTCHA
                      sitekey="6LfLIKsjAAAAAJseL39DISmGg6x2DHOYQdSl5yXL"
                      onChange={onChangeCaptcha}
                      ref={captchaRef}
                      onVerify={onChangeCaptcha} 
                    /> */}
                    {/* <div
                      class="g-recaptcha"
                      data-sitekey="6LfLIKsjAAAAAJseL39DISmGg6x2DHOYQdSl5yXL"
                    ></div> */}
                    <div
                      id="captcha-error"
                      class="text-danger hidden"
                    ></div>
                  </div>
                </div>
                {commentLoading ? <div className="float-end mt-2 pt-1 text-right" style={{justifyContent: "right", alignItems:"auto", marginLeft:"auto", marginRight:"auto", width: "auto"}} >
              <InfinitySpin width='250' color="#aec10b"/> 
            </div>:
                <div className="float-end mt-2 pt-1 text-right">
                  {/* <button type="button" className="btn btn-secondary btn-sm">
                    Post comment
                  </button>{" "} */}
                  {/* <button type="button" className="btn btn-secondary btn-sm" onClick={onSubmit} disabled={captchaValue === null}>
                    Post comment
                  </button>{" "} */}
                  <button type="button" className="btn btn-secondary btn-sm" onClick={(event) => {
                          onSubmit(event); // Pass the event object
                        }}>
                    Post comment
                  </button>{" "}
                  {/* <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                  >
                    Cancel
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={oncancel}
                  >
                    Reset
                  </button>
                  {/* <ToastContainer/> */}
                </div>
            }
              </div>
              {/* </form> */}
              {/* --------comment post-------------------------------------------------- */}
            </div>
          </>
        )}
        {/* -------------------------------------------------------------- */}
        <div className="col-md-4" >
          <div className="list-group list-group-light">
            {/* <h1 style={{ borderBottom: "2px solid", paddingTop: "20px" }}>
              Recent Posts
            </h1> */}
            <div className="rightTitle">
              Recent Posts
            </div>
            {(rPosts || []).map((post) => {
              return (
                <div className="smallText" style={{fontSize: 5}}>
                  {/* <br /> */}
                 
                  <Link className="blogsTitleRight" 
                    to={`/blog/${post.id}`}
                    
                    // onClick={() => {
                    //   setPostId(post.id);
                    //   setIsList(false);
                    // }}
                  >
                    <ReactMarkdown >{post.attributes.title }</ReactMarkdown>
                  </Link>
                </div>
              );
            })}

            <h1 className="rightTitle1">
              Categories
            </h1>
            {(category || []).map((c) => {
              return (
                <div className="smallText1" >
             
                  <Link className=""
                     to={`/blog/${post.id}`}
                    // onClick={() => {
                    //   postByCategory(c.id);
                    //   setIsList(true);
                    // }}
                  >
                    {/* <ReactMarkdown>{c.attributes.category_name}</ReactMarkdown> */}
                    {c.attributes.category_name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
  
      </div>

     
    </div>
  );
};

export default Home;
