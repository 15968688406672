import React, { memo } from "react";
import WrapComponent from "./Common/WrapComponent";
import product1 from "../assets/img/product-img-1.png";
import product2 from "../assets/img/product-img-2.png";
import product3 from "../assets/img/product-img-3.png";
import product4 from "../assets/img/product-img-4.png";
import product5 from "../assets/img/product-img-5.png";
import product6 from "../assets/img/product-img-6.png";
import product7 from "../assets/img/product-img-7.png";
import product8 from "../assets/img/product-img-8.png";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "./Common/ScrollToTop";

const Solutions = () => {
  
  return (
    <><ScrollToTopButton/>
      <WrapComponent>
        

        <div>
          <div className="banner-section-3 text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">Solutions</div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}

                <span className="font-45B0DC"> / Solutions</span>
              </div>
            </div>
          </div>
          <div className="product-main-section">
            <div className="row mb-30">
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-thorPlatform");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product1} alt="" />
                      </figure>
                      <div className="product-heading">
                        Thor Platform Solutions
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/thor-platform" onClick={() => {}}>
                    <div>
                        <figure>
                          <img src={product1} alt="" />
                        </figure>
                        <div className="product-heading">
                          Thor Platform Solutions
                        </div>
                      </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-customerOnboarding");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product2} alt="" />
                      </figure>
                      <div className="product-heading">Customer Onboarding</div>
                    </div>
                  </a> */}
                  <Link to="/solutions/customer-onboarding" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product2} alt="" />
                      </figure>
                      <div className="product-heading">Customer Onboarding</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-financialPlanning");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product3} alt="" />
                      </figure>
                      <div className="product-heading">
                        Financial Planning Tool
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/financial-planning" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product3} alt="" />
                      </figure>
                      <div className="product-heading">
                        Financial Planning Tool
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mb-30">
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-customerRelationshipManagement");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product4} alt="" />
                      </figure>
                      <div className="product-heading">
                        Customer Relationship Management (CRM)
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/customer-relationship-management" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product4} alt="" />
                      </figure>
                      <div className="product-heading">
                        Customer Relationship Management (CRM)
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-videokyc");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product5} alt="" />
                      </figure>
                      <div className="product-heading">Video KYC</div>
                    </div>
                  </a> */}
                  <Link to="/solutions/videokyc" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product5} alt="" />
                      </figure>
                      <div className="product-heading">Video KYC</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-multifactorAuthentication");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product6} alt="" />
                      </figure>
                      <div className="product-heading">
                        Advanced Multi Factor Authentication
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/multifactor-authentication" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product6} alt="" />
                      </figure>
                      <div className="product-heading">
                        Advanced Multi Factor Authentication
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-emailAnalytics");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product7} alt="" />
                      </figure>
                      <div className="product-heading">
                        Email Analytics using ChatGPT
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/email-analytics" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product7} alt="" />
                      </figure>
                      <div className="product-heading">
                        Email Analytics using ChatGPT
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="hover11 column">
                  {/* <a
                    href=""
                    onClick={() => {
                      navigate("/solutions-financialAnalyst");
                    }}
                  >
                    <div>
                      <figure>
                        <img src={product8} alt="" />
                      </figure>
                      <div className="product-heading">
                        Thor Financial Analyst Using Chatgpt
                      </div>
                    </div>
                  </a> */}
                  <Link to="/solutions/financial-analyst" onClick={() => {}}>
                    <div>
                      <figure>
                        <img src={product8} alt="" />
                      </figure>
                      <div className="product-heading">
                        Thor Financial Analyst Using Chatgpt
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(Solutions);
