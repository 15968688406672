import React, { memo, useState } from "react";
import WrapComponent from "../Common/WrapComponent";
import ftpImg from "../../assets/img/ftp-img.png";
import diagram37 from "../../assets/img/digram-37.png";
import diagram38 from "../../assets/img/digram-38.png";
import pdfIcon from "../../assets/img/pdf-icon.png";
import fptImg1 from "../../assets/img/fpt-img-1.jpg";
import fptImg2 from "../../assets/img/fpt-img-2.jpg";
import fptImg3 from "../../assets/img/fpt-img-3.jpg";
import fptImg4 from "../../assets/img/fpt-img-4.jpg";
import fptImg5 from "../../assets/img/fpt-img-5.jpg";
import fptImg6 from "../../assets/img/fpt-img-6.jpg";
import fptImg7 from "../../assets/img/fpt-img-7.jpg";
import fptImg8 from "../../assets/img/fpt-img-8.jpg";
import fptImg9 from "../../assets/img/fpt-img-9.jpg";
import fptImg10 from "../../assets/img/fpt-img-10.jpg";
import fptImg11 from "../../assets/img/fpt-img-11.jpg";
import fptImg12 from "../../assets/img/fpt-img-12.jpg";
import fptPdf from "./fpt-sample-pdf.pdf";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../Common/ScrollToTop";

const FinancialPlanning = () => {
  // const navigate = useNavigate();
  //Image_modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImgSrc, setModalImgSrc] = useState();

  function openModal(src) {
    setModalVisible(true);
    setModalImgSrc(src);
  }
  function closeModal() {
    setModalVisible(false);
  }
  //Slider
  const [index, setIndex] = useState(0);
  const slides = document.querySelectorAll(".slide");
  const dots = document.querySelectorAll(".dot");

  const prevSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const nextSlide = (n) => {
    setIndex((prevIndex) => prevIndex + n);
  };

  const changeSlide = () => {
    if (index > slides.length - 1) setIndex(0);

    if (index < 0) setIndex(slides.length - 1);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = i === index ? "block" : "none";
      dots[i].classList.toggle("active", i === index);
    }
  };

  // Call the changeSlide function whenever the index changes
  React.useEffect(() => {
    changeSlide();
    // eslint-disable-next-line
  }, [index]);

  return (
    <>
      <WrapComponent>
      <ScrollToTopButton/>
        <div>
          <div className="banner-section-ftp text-center">
            <div className="banner-text media-banner-text">
              <div className="banner-gradient-title mb-15">
                Financial Planning Tool
              </div>
              <div className="breadcrumb-links">
                {/* <a
                  onClick={() => {
                    navigate("/");
                  }}
                  href=""
                >
                  Home
                </a>{" "} */}
                <Link to="/" onClick={() => {}}>
                  Home
                </Link>{" "}
                <span className="font-white"> / </span>
                {/* <a href="solutions.html">Solutions</a>{" "} */}
                <Link to="/solutions" onClick={() => {}}>
                Solutions
                </Link>{" "}
                <span className="color-45B0DC"> / Financial Planning Tool</span>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6 comman-mb text-center solution-img-tp">
                <img className="images" src={ftpImg} alt="" />
              </div>
              <div className="col-md-6">
                <div className="gradient-title mb-15">
                  Why Financial Planning is Needed?{" "}
                </div>
                <p>
                  Financial planning is a step-by-step approach to meet one's
                  life goals. A financial plan acts as a guide as you go through
                  life's journey. Essentially, it helps you be in control of
                  your income, expenses and investments such that you can manage
                  your money and achieve your goals.
                </p>
                <p>
                  Financial Planning is the process of meeting your life goals
                  through the proper management of your finances. Examples of
                  goals:
                </p>
                <ul className="list-content comman-mb">
                  <li>Buying a house</li>
                  <li>Higher Education</li>
                  <li>Retirement, etc.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg-section">
            <div className="main-section">
              <div className="row">
                <div className="col-md-6 comman-mb">
                  <div className="gradient-title mb-15">
                    Financial Planning Solution
                  </div>
                  <p>
                    THOR-FP is a solution built on the THOR platform which
                    enables Independent Financial Advisors(IFA), National
                    Distributors, RIAs and Banks to generate, execute and track
                    financial plans for their customers.{" "}
                  </p>
                  <p>
                    THOR-FP is a cutting edge technology platform created for
                    Individual financial advisors who have an urge to add value
                    in their relationship with their clients and focus in
                    growing their business to the next level. It has a robust
                    client data capture, planning workflow and advisory tool,
                    where in IFAs can create various kind of financial plans for
                    their customers.
                  </p>
                  <p>
                    It has the ability to add various types of goals with
                    different timelines, current assets and liabilities along
                    with income and expense of the customer and internally in a
                    single click, it generates the financial plans for the
                    customer.
                  </p>
                  <p>
                    THOR-FP is available in cloud and on-premise desktop based
                    offerings.
                  </p>
                </div>
                <div className="col-md-6 text-center">
                  <img
                    id="myImg1"
                    src={diagram37}
                    onClick={() => {
                      openModal(diagram37);
                    }}
                    className="img-shadow width-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-12 comman-mb">
                <div className="gradient-title text-center">
                  Financial Planning Process
                </div>
                <div className="text-center">
                  <img className="images" src={diagram38} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="gray-bg-section-img-slider">
            <div className="main-section">
              <div className="custom-slider">
                <div className="gradient-title text-center">
                  How Financial Planning Works?
                </div>
                <div id="slider" className="slider">
                  <div className="slide" style={{ display: "block" }}>
                    <img src={fptImg1} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg2} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg3} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg4} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg5} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg6} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg7} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg8} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg9} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg10} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg11} alt="" />
                  </div>
                  <div className="slide">
                    <img src={fptImg12} alt="" />
                  </div>
                  {/*Controlling arrows*/}
                  <span
                    className="controls"
                    id="left-arrow"
                    onClick={() => prevSlide(-1)}
                  >
                    {" "}
                    <i className="fa-solid fa-angle-left" />
                  </span>
                  <span
                    className="controls"
                    id="right-arrow"
                    onClick={() => nextSlide(1)}
                  >
                    <i className="fa-solid fa-angle-right" />
                  </span>
                </div>
                <div id="dots-con">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </div>
                <div className="heading-20 mt-25">
                  Sample THOR Financial Planning Tool PDF
                </div>
                <div className="pdf-button">
                  {/* <a
                    href={fptPdf}
                    target="_blank"
                    className="btn p10-0"
                    rel="noreferrer"
                  >
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="ml-10">THOR Financial Planning Tool</span>
                  </a> */}
                  <Link 
                    // href={fptPdf}
                    to={fptPdf}
                    target="_blank"
                    className="btn p10-0"
                    rel="noreferrer">
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="ml-10">THOR Financial Planning Tool</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">Financial Planning Benefits </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p>
                  THOR-FP becomes very handy when it comes to the goal-based
                  planning and generating financial plans for customers. It has
                  inbuilt features of doing the error free precise calculations.
                  Every financial advisors knows the significance of client
                  engagement. FP tool give a flexible and configurable way of
                  generating financial plans. Key highlights of the solutions:
                </p>
                <ul className="list-content comman-mb">
                  <li>
                    This solution can be hosted in the cloud as well as can be
                    hosted within a client network as a desktop based app.{" "}
                  </li>
                  <li>
                    The App collects the Basic information of client including
                    his Income, Expense, Asset, Liability and Insurance.{" "}
                  </li>
                  <li>
                    The App run an assessment to determine the client Risk
                    Profile.
                  </li>
                  <li>
                    The App intelligently auto-allocates the existing Assets to
                    set Goals for fulfillment.
                  </li>
                  <li>
                    It automatically calculates the Gap to achieve Target Value
                    and the SIP and Lump sum investment needed to achieve the
                    Target value.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-content comman-mb">
                  <li>
                    Loan facility also available for the Goal Achievement.
                  </li>
                  <li>
                    Product/Schemes recommendation can be configured for
                    generating the Action plan.{" "}
                  </li>
                  <li>
                    It allows the user to view the plan in PDF format which is
                    user friendly.
                  </li>
                  <li>
                    There is option to add the Custom Commentary on all actions.
                  </li>
                  <li>
                    For Insurance, HLV calculation is done to determine Cover
                    value and premium to be paid.
                  </li>
                  <li>All FP Assumption values are configurable.</li>
                  <li>
                    Providing the facility where user can view the generated
                    financial plan in HTML (preview).
                  </li>
                  <li>Option to download the plan in PDF file format.</li>
                  <li>Option to add Overall Commentary to the plan</li>
                  <li>
                    Option for Product Recommendation to achieve the Target
                    Value.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ..................................................modal start...................................................... */}
          <div className="custom-modal">
            <div
              id="myModal"
              className="modal"
              style={{ display: modalVisible ? "block" : "none" }}
            >
              <span className="close" onClick={closeModal}>
                ×
              </span>
              <img
                className="modal-content"
                id="img01"
                src={modalImgSrc}
                alt=""
              />
              <div id="caption" />
            </div>
          </div>
        </div>
      </WrapComponent>
    </>
  );
};

export default memo(FinancialPlanning);
