import React, { memo } from "react";
import WrapComponent from "../Common/WrapComponent";

import platformAccelerationImage from "../../assets/img/platform-accelerators-img.png";
import cobImage1 from "../../assets/img/cob-img-1.png";
import diagram28 from "../../assets/img/digram-28.png";
import diagram24 from "../../assets/img/digram-24.png";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import ScrollToTopButton from "../Common/ScrollToTop";

const PlatformAcceleration = () => {
  // const navigate = useNavigate();
  return (
    <WrapComponent>
      <ScrollToTopButton/>
      <>
        <div className="services-banner-section-5 text-center">
          <div className="banner-text media-banner-text">
            <div className="banner-gradient-title mb-15">
              Platform Accelerators
            </div>
            <div className="breadcrumb-links ">
              {/* <a
                onClick={() => {
                  navigate("/");
                }}
                href=""
              >
                Home
              </a> */}
              <Link to="/" onClick={() => {}}>
                Home
              </Link>{" "}
              <span className="font-45B0DC"> / Platform Accelerators</span>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="gradient-title custom-text-center mb-15">
            Welcome to our Platform Accelerator Services!
          </div>
          <div className="custom-text-center">
            <p className=" mb-20">
              Are you looking to scale your business and take it to the next
              level? Our team of experienced professionals can help you do just
              that with our comprehensive platform accelerator services.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 comman-mb">
              <img
                className="images"
                alt="platformAccelerationImage"
                src={platformAccelerationImage}
              />
            </div>
            <div className="col-md-6">
              <div className="heading-20">Our services include:</div>
              <ul className="list-content">
                <li>
                  <span className="bullet-title">Strategy Development: </span> We 
                  will work with you to identify your business goals and
                  develop a customized technical strategy to help you achieve
                  them.
                </li>
                <li>
                  <span className="bullet-title">
                    Architecture &amp; Design:
                  </span> We 
                  will help you architect and design the platform, the
                  technology stack and tools to be used, UI/UX design and scope
                  down the MVP.
                </li>
                <li>
                  <span className="bullet-title">Product Development: </span> From 
                  concept to launch, we can help you bring your product
                  ideas to life and get them to market quickly and efficiently.
                </li>
                <li>
                  <span className="bullet-title">Technical Development: </span>
                  Our team of skilled developers can help you build and optimize
                  your platform, ensuring that it is scalable, reliable, and
                  user-friendly.
                </li>
                <li>
                  <span className="bullet-title">Growth Hacking: </span>
                  We will help you identify and leverage growth opportunities,
                  using data-driven approaches to drive traffic, engagement, and
                  conversions.
                </li>
              </ul>
            </div>
          </div>
          <div className="custom-text-center ">
            <p className="mt-20 mb-0">
              With our platform accelerator services, you can focus on what you
              do best – running and growing your business – while we take care
              of the rest. Contact us today to learn more and get started on
              your journey to success
            </p>
          </div>
        </div>
        <div className="gray-bg-section">
          <div className="main-section">
            <div className="gradient-title custom-text-center mb-15">
              We use multiple frameworks and approaches to deliver platform
              accelerator services
            </div>
            <div className="heading-20 custom-text-center">
              Some common ones include:
            </div>
            <div className="row">
              <div className="col-md-6">
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">Lean Startup: </span>
                    The Lean Startup framework emphasizes rapid experimentation,
                    iteration, and learning in order to identify and validate
                    business models and achieve growth. It focuses on maximizing
                    customer value and minimizing waste and risk.
                  </li>
                  <li>
                    <span className="bullet-title">Design Thinking: </span>
                    Design Thinking is a human-centered approach to
                    problem-solving that emphasizes empathy, creativity, and
                    prototyping. It can be used to identify opportunities for
                    innovation and develop solutions that meet the needs of
                    customers and users.
                  </li>
                  <li>
                    <span className="bullet-title">Agile: </span>
                    Agile is a project management framework that emphasizes
                    flexibility, collaboration, and continuous delivery. It is
                    often used to manage complex, rapidly changing projects and
                    can help accelerate the development and launch of new
                    products and platforms.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-content">
                  <li>
                    <span className="bullet-title">Customer Development: </span>
                    The Customer Development framework focuses on identifying
                    and validating customer needs and building a product or
                    service that meets those needs. It emphasizes customer
                    research, experimentation, and iteration in order to
                    identify and validate business models.
                  </li>
                  <li>
                    <span className="bullet-title">Lean Canvas: </span>
                    The Lean Canvas is a planning tool that helps businesses map
                    out their value proposition, customer segments, channels,
                    revenue streams, and key resources and partners. It can be
                    used to develop and refine a business model and identify
                    growth opportunities.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg-section-5">
          <div className="main-section">
            <div className="custom-text-center">
              <div className="gradient-title">Thor Use Cases</div>
              <p className="paragraph-content mb-20">
                THOR can help reduce overall effort required to deliver cloud
                based enterprise and retail applications by 60-70%. Major effort
                goes in building the User Interfaces (Portals and Mobile Apps)
                and Domain specific Business services (APIs) that the solution
                needs to deliver. Some of the solutions where THOR is a strong
                fit
              </p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card-box-7 mb-0 comman-mb">
                  <div className="small-text">Complete Customer Onboarding</div>
                  <div className="card-content">
                    About the Company: Customer onboarding is the process of
                    introducing a customer to a new product or service. It is
                    designed to ensure that the customer has a positive
                    experience and is able to quickly and easily
                  </div>
                  <div
                    className="btn keep-reading-btn-3"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-1"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-7 mb-0 comman-mb">
                  <div className="small-text">
                    Leading Billing and Collections Platform (USA)
                  </div>
                  <div className="card-content">
                    About the Company: Customer is a USA based organization that
                    provides billing assistance to the healthcare industry. The
                    Company's services include collections,
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-2"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-30">
                <div className="card-box-7 mb-0 comman-mb ">
                  <div className="small-text">
                    Leading Billing and Taxation Platform (USA)
                  </div>
                  <div className="card-content">
                    About the Company: Customer is one of the USA’s leading
                    providers of data processing, printing, and mailing of
                    information-sensitive documents.
                  </div>
                  <div
                    className="btn keep-reading-btn-3"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-3"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-7 mb-0 ">
                  <div className="small-text">
                    Leading Professional Education Platform for MF industry
                  </div>
                  <div className="card-content">
                    About the Company: India’s leading Professional education
                    platform for MF industry. Founded by a group of
                  </div>
                  <div
                    className="btn keep-reading-btn"
                    type="submit"
                    data-toggle="modal"
                    data-target="#useCase-4"
                  >
                    View Details <i className="fa-solid fa-arrow-right ml-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal start...................................... */}
        <div
          className="modal fade"
          id="useCase-1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">Complete Customer Onboarding</div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  Customer onboarding is the process of introducing a customer
                  to a new product or service. It is designed to ensure that the
                  customer has a positive experience and is able to quickly and
                  easily understand the product or service and how to use it.
                  Onboarding typically involves customer education, training,
                  and support. It can include activities such as customer
                  onboarding calls, webinars, video tutorials, customer service,
                  and product demonstrations.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Financial products have their own Account Opening nuances
                    based on regulatory requirements.
                  </li>
                  <li>
                    The onboarding process includes multiple cross-functional
                    teams involved.
                  </li>
                  <li>
                    Volatile regulatory environment: changes to the process,
                    data and underlying systems to comply.
                  </li>
                  <li>Ownership and in-efficient collaboration.</li>
                  <li>
                    Dealing with unstructured/semi-structured documents and
                    data.
                  </li>
                  <li>Integrating with Legacy Back-office systems.</li>
                  <li>
                    Integration with the India Stack services for identity,
                    e-Sign and Storage.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Speed &amp; automated onboarding: Timely engagement and
                    costs, No manual processes.
                  </li>
                  <li>
                    Easy Access: Mobile friendly is the present and the future.
                  </li>
                  <li>
                    Ability to configure the process as per the product and
                    regulatory compliances and organizations.
                  </li>
                  <li>
                    Keep the process simple: An average users to complete it
                    without any support.
                  </li>
                  <li>
                    Provide self service tools to reduce operational overheads
                    of a back-office/call center team.
                  </li>
                  <li>
                    Provide strong security to safeguard user identity from
                    internal and external teams.
                  </li>
                  <li>
                    Address drop-outs by nudging users to complete the journey.
                  </li>
                  <li>
                    Provide building blocks to quickly stitch together an
                    Onboarding journey.
                  </li>
                  <li>
                    Salesforce® tech bring all the cross functional teams
                    together.
                  </li>
                  <li>
                    Leverage the India Stack for Digital Identity and
                    Signatures.
                  </li>
                  <li>
                    Leverage AI/ML for scanning documents and photos to
                    eliminate manual overheads.
                  </li>
                </ul>
                <div className="text-center">
                  <img
                    className="images desk-images"
                    alt="cob  1"
                    src={cobImage1}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <div
          className="modal fade"
          id="useCase-2"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  Leading Billing and Collections Platform (USA)
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  Customer is a USA based organization that provides billing
                  assistance to the healthcare industry.
                </p>
                <p>
                  The Company's services include collections, pre-collections,
                  legal and judgment recovery, and billing assistance. Customer
                  offers legal services in addition to its billing and
                  collections services.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Patients were paying their hospital bills from different
                    platforms, such as from Payment Portal, from customer site
                    and directly from Hospital.
                  </li>
                  <li>
                    But the problem is the Payment synching, the other platform
                    was showing older bill amounts.
                  </li>
                  <li>
                    The payment plans and schedule for payment were not
                    intuitive and clear enough.
                  </li>
                  <li>Issue in Partial Payment functionality.</li>
                  <li>PCI DSS certification needed.</li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Provided a solution to sync the paid bill amount across the
                    platforms and which helps users to pay the actual bill
                    amount.
                  </li>
                  <li>Provided single platform to pay the bills. </li>
                  <li>
                    Solution provided for PCI DSS compliance transactions to
                    make secure payments.
                  </li>
                  <li>
                    Provided functionality for Partial payment, Full Payment.
                  </li>
                  <li>
                    Provided interactive Payment plans &amp; schedules to
                    patients to pay their bills in different schedules.
                  </li>
                  <li>Provided strong security for payment.</li>
                  <li>
                    Provided Mobile friendly website to do payment from mobile
                    also.
                  </li>
                </ul>
                <div className="text-center">
                  <img
                    className="images desk-images"
                    alt="diagram28"
                    src={diagram28}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <div
          className="modal fade"
          id="useCase-3"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  Leading Billing and Taxation Platform (USA)
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  Customer is one of the USA’s leading providers of data
                  processing, printing, and mailing of information-sensitive
                  documents. From billing and tax statements to HIPAA-regulated
                  letters and forms, it rigorously maintains the security of
                  personal information.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Existing application was developed in a legacy tech stack.
                  </li>
                  <li>
                    Application was hosted in-house and document management also
                    handled internally.
                  </li>
                  <li>
                    Huge amount of documents (More than 5 TB) were stored which
                    was costing more of the infrastructure amount.
                  </li>
                  <li>Ownership and in-efficient collaboration</li>
                  <li>Fetching a specific document was time consuming.</li>
                  <li>
                    Overall document management was one of the biggest problems
                    for Customer.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content">
                  <li>
                    By consuming THOR APIs Customer develops their whole
                    application in minimal time since all APIs are in place.
                  </li>
                  <li>
                    Since THOR APIs are developed in the latest technologies
                    which are open source tech, hence the new application
                    development cost was marginally lower than older systems.
                  </li>
                  <li>
                    They revamped the application by using numerous THOR
                    Services.
                  </li>
                  <li>
                    THOR Services were successfully consumed from the .Net based
                    application.
                  </li>
                  <li>Document service was used extensively.</li>
                  <li>
                    5TB documents was migrated as part of this effort within 2
                    to 3 weeks timespan with multiple threads ran parallelly.
                  </li>
                  <li>
                    Newly developed applications and services are hosted on AWS
                    which reduced cost.
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal end...................................... */}
        {/* ..........................................modal start...................................... */}
        <div
          className="modal fade"
          id="useCase-4"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modalDialog" role="document">
            <div className="modal-content">
              <div className="modal-header modalHeader">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body modalBody">
                <div className="small-text">
                  Leading Professional Education Platform for MF industry
                </div>
                <div className="gradient-title heading-22">
                  About the Company
                </div>
                <p>
                  India’s leading Professional education platform for MF
                  industry. Founded by a group of professionals who have more
                  than 100-man years of industry experience and a knack for
                  understanding industry’s skilling requirements. Driven by the
                  purpose of creating over 100,000 skilled Banking, Financial
                  Services, Insurance (BFSI) &amp; Real Estate professionals and
                  new to industry financial entrepreneurs over the next 5 years.
                  They bring to the table, an inside-out practioners’
                  perspective, proven execution capability &amp; relentless zeal
                  to support all COGS of better employability &amp; employment
                  ecosystem – Job seekers, Corporates and Educational
                  institutions. Customer Team is working very closely with
                  experts of BFSI industry and educational institutions to
                  bridge the huge gap between Academia and Corporates.
                </p>
                <div className="gradient-title heading-22">
                  Problem Statement
                </div>
                <ul className="list-content mb-15">
                  <li>
                    The Mutual Fund Investor base is growing in India. However,
                    there are not as many Mutual Fund Advisors who can capture
                    the growing investors.
                  </li>
                  <li>
                    There is a need to generate more and more licensed Mutual
                    Fund Distributors to support the growing investor base.
                  </li>
                  <li>
                    Multiple courses and levels in learning path, configurable
                    learning path with optional, mandatory levels, cost/no cost
                    for some products.
                  </li>
                  <li>
                    Custom workflows and journey stages as per process
                    (ARN/EUIN/CPE).
                  </li>
                  <li>
                    Since it’s an e-learning platform, performance is expected
                    as on the higher side.
                  </li>
                </ul>
                <div className="gradient-title heading-22">
                  Solution Implementation
                </div>
                <ul className="list-content mb-15">
                  <li>
                    Web Application: Distributor / Ops / AMC / Super Admin |
                    Hosting on AWS | Micro services | CICD Implementation.
                  </li>
                  <li>
                    Enrollment and registration of potential distributors.
                  </li>
                  <li>Capturing registered users’ profile.</li>
                  <li>
                    Learning Path configuration based on chosen journey –
                    ARN/EUIN/CPE.
                  </li>
                  <li>
                    Built-in Pre Assessment engine to gauge the potential
                    distributors factual and behavioral attributes, scoring
                    mechanism.
                  </li>
                  <li>
                    Integration with third party LMS for preparatory training
                    for NISM Certification.
                  </li>
                  <li>Payment gateway integration.</li>
                  <li>Workflow orchestration for multiple processes.</li>
                  <li>
                    Built-in features to cater operational requirements for
                    assisting the potential distributors in scheduling the NISM
                    Certification, KYD process, applying for ARN, EUIN,
                    Dashboards, Reports, Learning progress review, Distributor
                    Journey Views, Audit Trail, Product configuration,
                    Assessment engine configuration, user management.
                  </li>
                </ul>
                <div className="text-center">
                  <img
                    className="images desk-images"
                    alt="diagram24"
                    src={diagram24}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ..........................................modal end...................................... */}
      </>
    </WrapComponent>
  );
};

export default memo(PlatformAcceleration);
