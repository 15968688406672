import React, { memo } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const Footer = () => {
  // const navigate = useNavigate();
  return (
    <>
      <div className="footer-section">
        <div className="footer-main-section">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="company-name">Extrapreneurs India Pvt. Ltd.</div>
              <div className="footer-sub-title">
                Energy, Edge, Entrepreneurship
              </div>
            </div>
            <div className="col-md-3 footer-col comman-mb-15">
              <div
                className="dropdown-menu show"
                aria-labelledby="navbarDropdown"
              >
                <div className="link-title ">SERVICES</div>
                {/* <a
                  className="dropdown-item boxes-footer"
                  onClick={() => {
                    navigate("/services-platformDevelopment");
                  }}
                  href=""
                >
                  Platform Development &amp;
                  <br />
                  Digital Transformation{" "}
                </a> */}
              
                <Link 
                    className="dropdown-item boxes-footer"
                    to="/services/platform-development" onClick={() => {window.scrollTo(0, 0);}}>
                    Platform Development &amp;
                    <br />
                    Digital Transformation{" "}
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/services-biAndAndlytics");
                  }}
                  href=""
                >
                  BI &amp; Analytics
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/services/bi-and-analytics" onClick={() => {window.scrollTo(0, 0);}}>
                    BI &amp; Analytics
                </Link>{" "}   
                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/services-cloudManagment");
                  }}
                  href=""
                >
                  Cloud Management
                </a> */}
                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/services/cloud-management" onClick={() => {window.scrollTo(0, 0);}}>
                    Cloud Management
                </Link>{" "}   

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/services-rpaSolutions");
                  }}
                  href=""
                >
                  RPA Solutions
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/services/rpa-solutions" onClick={() => {window.scrollTo(0, 0);}}>
                    RPA Solutions
                </Link>{" "} 
                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/services-platformAcceleration");
                  }}
                  href=""
                >
                  Platform Accelerators
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/services/platform-acceleration" onClick={() => {window.scrollTo(0, 0);}}>
                    Platform Accelerators
                </Link>{" "} 
                
                {/* <a
                  className="dropdown-item boxes-footer"
                  onClick={() => {
                    navigate("/services-testingAndQA");
                  }}
                  href=""
                >
                  Testing &amp; QA Services
                </a> */}

                <Link 
                    className="dropdown-item boxes-footer"
                    to="/services/testing-and-qa" onClick={() => {window.scrollTo(0, 0);}}>
                    Testing &amp; QA Services
                </Link>{" "}

              </div>
            </div>
            <div className="col-md-3 media-pl-15 footer-col comman-mb-15 tab-pl-15 pl-35">
              <div
                className="dropdown-menu show"
                aria-labelledby="navbarDropdown"
              >
                <div className="link-title">INDUSTRIES</div>
                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-bankingAndFiance");
                  }}
                  href=""
                >
                  Banking Finance
                </a> */}
                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/banking-and-finance" onClick={() => {window.scrollTo(0, 0);}}>
                    Banking Finance
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-healthcareAndFarma");
                  }}
                  href=""
                >
                  Healthcare &amp; Pharma
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/healthcare-and-pharma" onClick={() => {window.scrollTo(0, 0);}}>
                    Healthcare &amp; Pharma
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-travelAndTourism");
                  }}
                  href=""
                >
                  Travel &amp; Tourism
                </a> */}
                 
                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/travel-and-tourism" onClick={() => {window.scrollTo(0, 0);}}>
                    Travel &amp; Tourism
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-printAndPublish");
                  }}
                  href=""
                >
                  Print &amp; Publishing
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/print-and-publish" onClick={() => {window.scrollTo(0, 0);}}>
                    Print &amp; Publishing
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-mutualfundDistributor");
                  }}
                  href=""
                >
                  Mutual Funds Distributor
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/mutual-fund-distributor" onClick={() => {window.scrollTo(0, 0);}}>
                    Mutual Funds Distributor
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  onClick={() => {
                    navigate("/industries-labourAndOffice");
                  }}
                  href=""
                >
                  Labor, Office &amp; Factory <br />
                  Law Compliance
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/industries/labour-and-office" onClick={() => {window.scrollTo(0, 0);}}>
                    Labor, Office &amp; Factory <br />
                    Law Compliance
                </Link>{" "}

              </div>
            </div>
            <div className="col-md-2 media-pl-15 footer-col tab-pl-15 pl-35">
              <div
                className="dropdown-menu show mb-15 width-100"
                aria-labelledby="navbarDropdown"
              >
                <div className="link-title">ABOUT Us</div>
                {/* <a
                  className="dropdown-item footer-links-animation"
                  href=""
                  onClick={() => {
                    navigate("/aboutus-lifeAtExtrapreneurs");
                  }}
                >
                  Life at Extrapreneurs
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/aboutus/life-at-extrapreneurs" onClick={() => {window.scrollTo(0, 0);}}>
                    Life at Extrapreneurs
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  href=""
                  onClick={() => {
                    navigate("/aboutus-ourCelebrations");
                  }}
                >
                  Our Celebrations
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/aboutus/our-celebrations" onClick={() => {window.scrollTo(0, 0);}}>
                    Our Celebrations
                </Link>{" "}

              </div>
              <div
                className="dropdown-menu show"
                aria-labelledby="navbarDropdown"
              >
                <div className="link-title">OTHERS</div>
                {/* <a
                  className="dropdown-item footer-links-animation"
                  href=""
                  onClick={() => {
                    navigate("/solutions");
                  }}
                >
                  Solutions
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/solutions" onClick={() => {window.scrollTo(0, 0);}}>
                    Solutions
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  href=""
                  onClick={() => {
                    navigate("/careers");
                  }}
                >
                  Careers
                </a> */}

                
                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/careers" onClick={() => {window.scrollTo(0, 0);}}>
                    Careers
                </Link>{" "}

                {/* <a
                  className="dropdown-item footer-links-animation"
                  href=""
                  onClick={() => {
                    navigate("/contactus");
                  }}
                >
                  Contact Us
                </a> */}

                <Link 
                    className="dropdown-item footer-links-animation"
                    to="/contactus" onClick={() => {window.scrollTo(0, 0);}}>
                    Contact Us
                </Link>{" "}

              </div>
            </div>
            <div className="col-md-4 media-pl-15 tab-pl-15 pl-70">
              <div className="footer-text-color">
                <div className="link-title">GET IN TOUCH</div>
                {/* <div className="mb-10">
                  <i className="fa-solid fa-phone mr-10 " />
                  (+91) 99754 98229
                </div> */}
                <div className="mb-15 emaillink">
                  <i className="fa-solid fa-envelope mr-10 " />
                  <a href="mailto:corporate@extrapreneursindia.com">
                    corporate@extrapreneursindia.com
                  </a>
                </div>
                <div className="mb-15">
                  <div className="footer-social-icon">
                    {/* <a href="#" target="_blank " class="btn p-0">
                              <div class="footer-facebook-icon"></div>
                          </a> */}
                    <a
                      href="https://www.linkedin.com/company/extrapreneurs-india/"
                      target="_blank"
                      className="btn p-0"
                      rel="noreferrer"
                    >
                      <div className="footer-linkedin-icon" />
                    </a>
                    {/* <a href="#" target="_blank" class="btn p-0">
                              <div class="footer-twitter-icon"></div>
                          </a> */}
                  </div>
                </div>
                <div>
                  {" "}
                  Copyright © 2023, <br />
                  Extrapreneurs India Pvt. Ltd.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Footer);