import React, { memo } from "react";
import thinqloudLogo from "../../assets/img/thinqloud-logo.png";

const ThinqloudSection = () => {
  return (
    <>
      <div className="cloud-bg-section">
        <div className="main-section">
          <div className="text-center mb-25">
            <img src={thinqloudLogo} alt="" />
          </div>
          <div className="text-center justifyText">
            <p>
              <a
                href="https://www.thinqloud.com/"
                target="_blank"
                rel="noreferrer"
              >
                Thinqloud Solutions Pvt. Ltd.
              </a>{" "}
              carved out from Extrapreneurs India. Founded in 2016. Thinqloud is
              a leading Salesforce® Development and Cloud Advisory Company based
              in Pune, India. Thinqloud is a certified Salesforce® partner since
              2016 addressing the needs of integrated marketing, sales, and
              service automation Our differentiator lies in our "approach" to
              designing efficient business processes.{" "}
            </p>
            <p>
              Organization has provided many unique solutions to Nonprofits,
              Higher-ed businesses, Finance Firms, Real Estate Agencies,
              Startups, etc. with the Salesforce® CRM platform. Our expertise
              lies in a range of services which include implementation,
              customization, automation, integration, and product development on
              the Salesforce® platform.{" "}
            </p>
            <p className="mb-0">
              The Thinqloud team comprises of experienced and successful
              business entrepreneurs, business process re-engineering
              professionals, and Salesforce® enthusiasts.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ThinqloudSection);
